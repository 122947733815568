<template>
    <v-container >
              <v-row > 
                  <v-col cols="6" md="4" class="px-0">    
                    <v-text-field
                        clearable
                        v-model="search"
                        prepend-inner-icon="mdi-magnify"
                        label="Search Users"
                        single-line
                        solo
                        hide-details="true"
                        flat
                    ></v-text-field>
                    <v-divider></v-divider>
                    <v-list 
                    dense
                    id="scroll-target"
                    style="max-height: 100%"
                    class="overflow-y-auto list-editor border-right"
                    :height="window.height - this.appBarHeight - this.settingsHeadingElement - this.dividerHeight -12 - this.conversationsNavFilterElement - 12">
                        <v-subheader class="body-1">Existing Users: </v-subheader>
                        <v-list-item-group color="primary" dense>
                            <v-list-item 
                                two-line
                                class="pa-1"
                                v-for="(user, i) in searching"
                                :key="user.id"
                                @click="editUser(user)"
                            >
                                <v-avatar color="primary">
                                    <span v-if="user.firstName && user.lastName" class="white--text text-h5">{{ user.firstName.charAt(0) }}{{ user.lastName.charAt(0) }}   </span>
                                    <span v-else class="white--text text-h5">??</span>
                                </v-avatar>
                               
                                <v-list-item-content class="body-2">
                                    <v-list-item-title v-if="user.firstName && user.lastName" class="overline pb-0">
                                        {{user.firstName}} {{user.lastName}}
                                    </v-list-item-title>
                                    <v-list-item-title v-else> Invite Sent to: </v-list-item-title>
                                    <v-list-item-subtitle class="d-flex justify-space-between align-center flex-wrap">
                                       <span class="caption"> {{user.email}} </span> 
                                        <v-chip x-small color="secondary"> {{getRole(user.roleID)}}</v-chip>
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>

                    </v-list>
                  </v-col>
                  <v-col cols="6" md="8" class="pl-0 pb-0">
                    <v-card elevation="1" class="fill-height">
                            <v-card-title>
                                <h3 v-if="!selectedUser.id">Invite New User</h3>
                                <h3 v-else>Edit Existing User</h3>
                            </v-card-title>
                            <v-card-text>
                                <v-form  v-model="userValid" id="userForm" ref="userForm" lazy-validation>
                                    <v-row class="d-flex flex-column justify-space-between" style="height: 100%">
                                        <v-col class="flex-grow-1" >
                                            <v-text-field
                                                :dense="this.$vuetify.breakpoint.smAndDown"
                                                outlined
                                                label="User First Name"
                                                v-model="selectedUser.firstName"
                                                @change="dirtyForm"
                                                @keydown="[userObjHasChanged, dirtyForm]"
                                                :rules="[templateRules.required]"
                                            ></v-text-field>
                                            <v-text-field
                                                :dense="this.$vuetify.breakpoint.smAndDown"
                                                outlined
                                                label="User Last Name"
                                                v-model="selectedUser.lastName"
                                                @change="dirtyForm"
                                                @keydown="[userObjHasChanged, dirtyForm]"
                                                :rules="[templateRules.required]"
                                            ></v-text-field>

                                            <v-text-field
                                                :dense="this.$vuetify.breakpoint.smAndDown"
                                                outlined
                                                type="email"
                                                label="User Email"
                                                v-model="selectedUser.email"
                                                @change="dirtyForm"
                                                @keydown="[userObjHasChanged, dirtyForm]"
                                                :rules="emailRules"
                                            ></v-text-field>
                                            <v-select
                                                :dense="this.$vuetify.breakpoint.smAndDown"
                                                ref="selectedUserID"
                                                outlined
                                                :items="roleList"
                                                v-model="selectedUser.roleID"
                                                item-text="role"
                                                item-value="value"
                                                label="User Role"
                                            ></v-select>
                                            
                                        </v-col>
                                    
                                        
                                    </v-row>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>    
                            <v-footer absolute>
                                <v-row>
                                    <v-col cols="12" class="d-flex justify-space-between">
                                        <div>
                                            <!--TODO:  Need to improve on form checkups so that when a change is made it would show btn status inmediately -->
                                            <v-btn color="primary" v-if="!selectedUser.id" @click="saveUser" :disabled="!userValid || !formState.formIsDirty">
                                                <span>Invite User</span>
                                            </v-btn>
                                            <v-btn v-else color="primary" @click="saveUser" :disabled="!userValid || !formState.formIsDirty || this.selectedUser.firstName === null || this.selectedUser.lastName === null">
                                                <span>Save User Edits</span>
                                            </v-btn>
                                            <v-btn color="primary"  @click="clearUser" outlined>Clear Form</v-btn>
                                        </div>
                                        <v-btn color="error" v-if="selectedUser.id" @click="deleteUser"> Delete Selected </v-btn>
                                    </v-col>
                                </v-row>
                            </v-footer>
                        </v-card-actions>
                    </v-card>
                  </v-col>
              </v-row>
    </v-container>
</template>
<script>
export default {
    data (){
        return {
            users:[],
            selectedUser:{},
            userValid: true,
            roleList:[
                {value:"SuperAdmin" ,role:"Super Admin"}, 
                {value:"BillingAdmin", role: "Billing Admin"}, 
                {value:"User", role: "User"},
                {value:"AccountAdmin", role: "Account Admin"}
            ],
            selectedTemplate:{},
            search: '',
            templateValid: true,
            formState:{
                formIsDirty: false
            },
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
            window: {
                width: 0,
                height: 0
            },
            templateRules: {
                max: v => { if (v) return v.length <= 160 || 'Max 160 characters';    else return false;  },
                required: v => !!v || 'Required.'
            }
        }
    },
    created() {
        this.$auth.auth0ClientPromise.then(async () => {
            let users = await this.$store.dispatch('company/getCompanyUsers');
            this.parseUsers(users);
            window.addEventListener('resize', this.handleResize);
            this.handleResize();
        });
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    watch: {
        selectedUser: {
            handler: function(v){
                return this.userObjHasChanged();
            },
            deep: true
        }
    },
    computed: {
      searching () {
        if (!this.search) return this.users

        const search = this.search.toLowerCase()

        return this.users.filter(user => {
          const firstName = user.firstName.toLowerCase()
          const lastName = user.lastName.toLowerCase()
          const email = user.email.toLowerCase()

          return firstName.indexOf(search) > -1 ||  lastName.indexOf(search) > -1 ||  email.indexOf(search) > -1
        })
      },
    },
    methods: {
        editUser(user){
            this.selectedUser = Object.assign({}, user);
            this.formState.formIsDirty = true;
            this.userValid = true;
        },
        clearUser(){
            this.selectedUser = {};
            this.formState.formIsDirty = false;
        },
        async saveUser(){
            if(this.selectedUser.hasOwnProperty('id')){
                await this.$store.dispatch('company/updateUserCompany',this.selectedUser);
            }else{
                var usersLength= this.users.length;
                var lastID = this.users[usersLength-1].id;
                var selectedUserIndex = this.$refs.selectedUserID.selectedIndex;
                this.users.push({id: lastID+1, firstName: "", lastName: "", email: this.selectedUser.email, roleID: this.selectedUser.roleID})
                this.clearUser();
            }
        },
        deleteUser(){
            this.$swal({
                title: `Are you sure you want to delete the user: ${this.selectedUser.firstName}?`,
                showDenyButton: true,
                confirmButtonText: "Yes",
                denyButtonText: "No"
            }).then(async result => {
                if(result.isConfirmed){
                    let response = await this.$store.dispatch('company/deleteUserCompany',this.selectedUser.id);
                    if(response){
                        this.$swal({
                            icon: 'success',
                            title: 'User Successfully Deleted',
                            text: 'User Successfully Deleted',
                            toast: true,
                            timer: 3000,
                            position: 'top-end'
                        });

                        let index = this.users.map((item) => item.id).indexOf(this.selectedUser.id);
                        this.users.splice(index, 1);
                        this.clearUser();
                    }else{
                        this.$swal({
                            icon: 'error',
                            title: 'Error Deleting User',
                            text: 'An error occurred while deleting the user. Please try again later.',
                            toast: true,
                            timer: 3000,
                            position: 'top-end'
                        });
                    }
                }
            });
        },
        dirtyForm(){
            this.formState.formIsDirty = true;
        },
        userObjHasChanged(){
            this.$refs.userForm.validate();
        },
        parseUsers(users){
            let userList = [];
            users.forEach(user => {
                let u = {
                    id: user.userId, 
                    firstName: user.firstName,
                    lastName: user.lastName, 
                    email: user.email, 
                    roleID: user.role
                };
                userList.push(u);
            });
            this.users = userList;
        },
        getRole(role){
            let index = this.roleList.findIndex(r => r.value === role);
            return this.roleList[index].role;
        }
    }
}
</script>
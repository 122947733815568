<template>
<v-container fluid class="fill-height">
    <!-- <script2 type="text/javascript" async src="https://play.vidyard.com/embed/v4.js"></script2> -->

    <v-row class="d-flex align-center ">
        <v-col cols="12" md="6">
            <v-card rounded elevation="3" dark class="miq-drawer-main pa-6" >
                <v-card-title class="d-flex justify-center text-center">
                    <v-row>
                        <v-col cols="12">
                            <h1 class="display-4 text-center" style="word-break: normal; font-weight: bold !important;"><strong>The #1 Add-On and Integration Platform for HubSpot</strong></h1>
                        </v-col>
                        <v-col cols="12">
                            <h3 class="font-weight-light">
                                Smart Ramp gives you the most advanced features to triple the value of HubSpot.
                            </h3>
                        </v-col>
                        <v-col cols="12">
                            <h3 class="font-weight-medium">
                            Click the video below to learn more!
                            </h3>
                            <v-icon x-large> mdi-chevron-double-down</v-icon>
                        </v-col>
                    </v-row>
                   
                    
                </v-card-title>
                <v-card-text>
                    <img
                        style="width: 100%; margin: auto; display: block;"
                        class="vidyard-player-embed"
                        src="https://play.vidyard.com/wmNeRogV5s6e5MCeAnP9i9.jpg"
                        data-uuid="wmNeRogV5s6e5MCeAnP9i9"
                        data-v="4"
                        data-type="inline"
                    />
                </v-card-text>
            </v-card>
        </v-col>
        <!-- <v-col cols="12" md="6">
            <v-row>
                <v-col cols="12">
                    <v-card elevation="3" rounded>
                        <v-card-text>
                            <v-row class="d-flex align-center">
                                <v-col cols="auto" md="2">
                                    <div class="d-flex flex-column justify-center align-center">
                                        <v-icon color="secondary" x-large> mdi-flash </v-icon>
                                        <span> 2 minutes</span>
                                    </div>
                                </v-col>
                                <v-divider vertical></v-divider>
                                <v-col cols="8" md="8">
                                    <v-row no-gutters>
                                        <v-col cols="12" class="text-h5">QUICK START</v-col>
                                        <v-col cols="12" class="body-1">Follow our quick start guide and immediately impact your business.</v-col>
                                    </v-row>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col offset="3" cols="6" offset-md="0" md="2"> 
                                    <v-card rounded elevation="3">
                                        <img style="width: 100%; margin: auto; display: block;" class="vidyard-player-embed" src="https://play.vidyard.com/EuqNX6zbNWcrPPMANQKztU.jpg" data-uuid="EuqNX6zbNWcrPPMANQKztU" data-v="4" data-type="lightbox"/>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12">
                    <v-card elevation="3" rounded>
                        <v-card-text>
                            <v-row class="d-flex align-center">
                                <v-col cols="auto" md="2">
                                    <div class="d-flex flex-column justify-center align-center">
                                        <v-icon x-large color="secondary"> mdi-steering </v-icon>
                                        <span> 2 minutes</span>
                                    </div>
                                </v-col>
                                <v-divider vertical></v-divider>
                                <v-col cols="8" md="8">
                                    <v-row no-gutters>
                                        <v-col cols="12" class="text-h5">TAKE IT FOR A SPIN</v-col>
                                        <v-col cols="12" class="body-1">Orient yourself with this quick tour of the place.</v-col>
                                    </v-row>
                                    
                                </v-col>
                                <v-spacer></v-spacer>

                                <v-col offset="3" cols="6" offset-md="0" md="2"> 
                                    <v-card rounded elevation="3">
                                        <img style="width: 100%; margin: auto; display: block;" class="vidyard-player-embed" src="https://play.vidyard.com/XXesvzwZ8BQnEqCwfQPjTY.jpg" data-uuid="XXesvzwZ8BQnEqCwfQPjTY"d ata-v="4" data-type="lightbox"/>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12">
                    <v-card elevation="3" rounded>
                        <v-card-text>
                            <v-row class="d-flex align-center">
                                <v-col cols="auto" md="2">
                                    <div class="d-flex flex-column justify-center align-center">
                                        <v-icon x-large color="secondary"> mdi-map-search-outline </v-icon>
                                        <span> 15 minutes</span>
                                    </div>
                                </v-col>
                                <v-divider vertical></v-divider>
                                <v-col cols="8" md="8">
                                    <v-row no-gutters>
                                        <v-col cols="12" class="text-h5">DIVE DEEP INTO THE HOW TO’S</v-col>
                                        <v-col cols="12" class="body-1">Learn how to use the full power of the 4 C’s with this video playlist.</v-col>
                                    </v-row>
                                    
                                </v-col>
                                <v-spacer></v-spacer>

                                <v-col offset="3" cols="6" offset-md="0" md="2"> 
                                    <v-card rounded elevation="3">
                                        <img style="width: 100%; margin: auto; display: block;" class="vidyard-player-embed" src="https://play.vidyard.com/MDNZe2vNmeYHPRkYTbYBr7.jpg" data-uuid="MDNZe2vNmeYHPRkYTbYBr7" data-v="4" data-type="lightbox"/>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12">
                    <v-card elevation="3" rounded>
                        <v-card-text>
                            <v-row class="d-flex align-center">
                                <v-col cols="auto" md="2">
                                    <div class="d-flex flex-column justify-center align-center">
                                        <v-icon x-large color="secondary"> mdi-school </v-icon>
                                        <span> 1 minute</span>
                                    </div>
                                </v-col>
                                <v-divider vertical></v-divider>
                                <v-col cols="8" md="8">
                                    <v-row no-gutters>
                                        <v-col cols="12" class="text-h5">LEARN FROM THE IQ PLAYBOOK</v-col>
                                        <v-col cols="12" class="body-1">Elevate your IQ interactions with live video training of best practices.</v-col>
                                    </v-row>
                                    
                                </v-col>
                                <v-spacer></v-spacer>

                                <v-col offset="3" cols="6" offset-md="0" md="2"> 
                                    <v-card rounded elevation="3" max-width="400px">
                                         <img
                                            style="width: 100%; margin: auto; display: block;"
                                            class="vidyard-player-embed"
                                            src="https://play.vidyard.com/m3Et1vguKMPXD2E7nAYbKZ.jpg"
                                            data-uuid="m3Et1vguKMPXD2E7nAYbKZ"
                                            data-v="4"
                                            data-type="lightbox"
                                        />
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12">
                    <v-card elevation="3" rounded>
                        <v-card-text>
                            <v-row class="d-flex align-center">
                                <v-col cols="auto" md="2">
                                    <div class="d-flex flex-column justify-center align-center">
                                        <v-icon x-large color="secondary"> mdi-calendar-cursor </v-icon>
                                        <span> 45 minutes</span>
                                    </div>
                                </v-col>
                                <v-divider vertical></v-divider>
                                <v-col cols="8" md="7"> 
                                    <v-row no-gutters>
                                        <v-col cols="12" class="text-h5">SCHEDULE YOUR 1:1 STRATEGY SESSION</v-col>
                                        <v-col cols="12" class="body-1">Book time now to get your personalized IQ plan.</v-col>
                                    </v-row>
                                    
                                </v-col>
                                <v-spacer></v-spacer>

                                <v-col cols="12" md="3" class="text-center"> 
                                    <v-dialog
                                    width="800"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                        color="primary"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                        Schedule
                                        </v-btn>
                                    </template>

                                        <v-card>
                                            <v-card-title class="text-h5 grey lighten-2">
                                                Schedule your 1:1 Strategy Session
                                            </v-card-title>

                                            <v-card-text>
                                            Schedule your strategy session now!

                                            <iframe src="https://meetings.integrateiq.com/meetings/toddearwood/messageiq-strategy-session?embed=true" width="auto" height="700" style="position: relative; width: 100%; height: 700px; left: 0;" frameborder="0" allowfullscreen />
                                            </v-card-text>


                                        </v-card>
                                    </v-dialog>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            
        </v-col> -->
        <v-col cols="12" md="6">
            <v-row>
                <v-col cols="12" v-for="launchItem in launcherItems" :key="launchItem.appName">
                    <v-card elevation="5" rounded>
                        <v-card-text class="ma-1 py-0">
                            <v-row  class="d-flex align-center">
                                <v-col cols="4" md="3" class="text-center">
                                    <v-icon :color="launchItem.isLive ? 'primary':'iq_slategray'" class="auto-limited-150">{{launchItem.appIcon}}</v-icon>
                                </v-col>
                                <v-divider vertical></v-divider>
                                <v-col cols="8" md="6">
                                    <v-row no-gutters>
                                        <v-col cols="12" class="text-h5">{{launchItem.appName}}</v-col>
                                        <v-col cols="12" class="body-2">
                                            {{launchItem.appDescription}}
                                            <v-btn v-if="launchItem.appLearnMoreLink != null" text small color="primary" :to="launchItem.appLearnMoreLink"> Learn More</v-btn>
                                        </v-col>
                                        <v-btn v-if="launchItem.hasInlineHelpList" block x-large text @click.stop="launchItem.showInlineHelp = !launchItem.showInlineHelp">
                                            Get Started Videos
                                            <v-icon color="secondary" v-if="!launchItem.showInlineHelp"> mdi-chevron-down</v-icon>
                                            <v-icon color="secondary" v-else> mdi-chevron-up</v-icon>
                                        </v-btn>
                                    </v-row>
                                </v-col>
                                <v-divider vertical class="d-sm-none d-md-block d-none"></v-divider>
                                <v-col offset="3" cols="6" offset-md="0" md="3"> 
                                    <v-row no-gutters class="fill-height">
                                        <v-col cols="12">
                                            <v-btn dark block x-large :color="launchItem.isLive ? 'primary':'accent'" class="font-weight-medium" :to="launchItem.appLink">
                                                <span v-if="launchItem.isLive">Launch</span>    
                                                <span v-else>See Demo</span>    
                                            </v-btn>

                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card-text>
                            <v-fade-transition>
                                <v-card flat v-show="launchItem.showInlineHelp">
                                    <v-card-text>
                                        <v-card elevation="0" outlined>
                                            <v-card-title>{{launchItem.appIntroTitle}}</v-card-title>
                                            <v-card-subtitle>{{launchItem.appIntroText}}</v-card-subtitle>
                                            <v-card-text class="" style="positions: relative; text-align-center">
                                                <v-card max-width="70%" class="align-center d-flex" style="margin: 0 auto;" elevation="0" flat>
                                                    <img
                                                        style="width: 100%; margin: auto; display: block;"
                                                        class="vidyard-player-embed ma-4"
                                                        :src="launchItem.appIntroVideoSrc"
                                                        :data-uuid="launchItem.appIntroVideoUuid"
                                                        data-v="4"
                                                        data-type="inline"
                                                    />
                                                </v-card>
                                            </v-card-text>
                                        </v-card>
                                        <v-card v-for="inlineHelpItem in launchItem.InlineHelpList" :key="inlineHelpItem.name" elevation="0" outlined color="iq_concrete">
                                            <v-card-text>
                                                <v-row  class="d-flex align-center">
                                                    <v-col cols="auto" md="2" class="text-center">
                                                        <div class="d-flex flex-column justify-center align-center">
                                                                <v-icon color="secondary" x-large>{{inlineHelpItem.icon}} </v-icon>
                                                                <span> {{inlineHelpItem.duration}}</span>
                                                            </div>
                                                    </v-col>
                                                    <v-col cols="8" md="8">
                                                        <v-row no-gutters>
                                                            <v-col cols="12" class="text-h5">{{inlineHelpItem.name}}</v-col>
                                                            <v-col cols="12" class="body-1">{{inlineHelpItem.description}}</v-col>
                                                        </v-row>
                                                    </v-col>
                                                    <v-spacer></v-spacer>
                                                    <v-col offset="3" cols="6" offset-md="0" md="2" class="text-center">                                                     
                                                        <v-card rounded elevation="3" v-if="inlineHelpItem.uuid.length > 0 && inlineHelpItem.src.length > 0">
                                                            <img style="width: 100%; margin: auto; display: block;" class="vidyard-player-embed" :src="inlineHelpItem.src" :data-uuid="inlineHelpItem.uuid" data-v="4" data-type="lightbox"/>
                                                        </v-card>
                                                        <v-dialog v-else
                                                            width="800"
                                                            >
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn
                                                                color="primary"
                                                                dark
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                block
                                                                >
                                                                {{inlineHelpItem.linkText}}
                                                                </v-btn>
                                                            </template>

                                                            <v-card>
                                                                <v-card-title class="text-h5 grey lighten-2">
                                                                    Schedule your 1:1 Strategy Session
                                                                </v-card-title>

                                                                <v-card-text>
                                                                Schedule your strategy session now!

                                                                <iframe :src="inlineHelpItem.link" width="auto" height="700" style="position: relative; width: 100%; height: 700px; left: 0;" frameborder="0" allowfullscreen />
                                                                </v-card-text>


                                                            </v-card>
                                                        </v-dialog>
                                                    </v-col>
                                                </v-row>

                                            </v-card-text>
                                        </v-card>
                                    </v-card-text>
                                </v-card>
                            </v-fade-transition>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>

    </v-row>

</v-container>
    

</template>
<script>
import VS2 from 'vue-script2'
import VidyardEmbed from '@vidyard/embed-code';
import { mapGetters } from 'vuex';
export default {
    computed:{
        ...mapGetters({
            currentCompany: 'company/getCurrentCompany',
            currentCompanyApps: 'company/getCurrentCompanyApps'
        })
    },
    data(){
        return {
            launcherItems: [
                {
                    appName: 'INTEGRATE IQ', 
                    appIcon: '$integrateIQIcon',
                    appColor: 'primary',
                    appLink: '/cards',
                    appDescription: 'Connect any cloud software with HubSpot through our 30+ sync recipes and get launched in less than 30 days.',
                    appLearnMoreLink: null, 
                    appIntroVideoSrc: 'https://play.vidyard.com/p8gh63GnCBorGGbSuDMhrz.jpg',
                    appIntroVideoUuid: 'p8gh63GnCBorGGbSuDMhrz', 
                    appIntroTitle: 'Intro to IntegrateIQ',
                    appIntroText:'Take a look at the advantages and great technology behind our tool.',
                    hasInlineHelpList: true,
                    showInlineHelp: false,
                    isLive: false,
                    InlineHelpList: [],
                },
                {
                    appName: 'MESSAGE IQ', 
                    appIcon: '$messageIQIcon',
                    appColor: 'primary',
                    appLink: '/conversations',
                    appDescription: 'Send 1:1, broadcast and automated SMS messages from inside HubSpot.',
                    appLearnMoreLink: null, 
                    appIntroVideoSrc: 'https://play.vidyard.com/wmNeRogV5s6e5MCeAnP9i9.jpg',
                    appIntroVideoUuid: 'wmNeRogV5s6e5MCeAnP9i9', 
                    appIntroTitle: ' Intro to Message IQ',
                    appIntroText:'Take a look at the advantages and great technology behind our tool.',
                    hasInlineHelpList: true,
                    showInlineHelp: false,
                    isLive: true,
                    InlineHelpList: [
                        { name:'QUICK START',        icon:'mdi-flash',  description:'Follow our quick start guide and immediately impact your business.', linkText: '', link: '', duration:'2 minutes', src:'https://play.vidyard.com/EuqNX6zbNWcrPPMANQKztU.jpg', uuid:'EuqNX6zbNWcrPPMANQKztU'},
                        { name:'TAKE IF FOR A SPIN', icon:'mdi-steering',  description:'Orient yourself with this quick tour of the place.', linkText: '', link: '', duration:'2 minutes', src:'https://play.vidyard.com/XXesvzwZ8BQnEqCwfQPjTY.jpg', uuid:'XXesvzwZ8BQnEqCwfQPjTY'},
                        { name:'DIVE DEEP INTO THE HOW TO\’S', icon:'mdi-steering',  description:'Learn how to use the full power of the 4 C’s with this video playlist.', linkText: '', link: '', duration:'2 minutes', src:'https://play.vidyard.com/MDNZe2vNmeYHPRkYTbYBr7.jpg', uuid:'MDNZe2vNmeYHPRkYTbYBr7'},
                        { name:'LEARN FROM THE IQ PLAYBOOK', icon:'mdi-steering',  description:'Elevate your IQ interactions with live video training of best practices.', linkText: '', link: '', duration:'2 minutes', src:'https://play.vidyard.com/m3Et1vguKMPXD2E7nAYbKZ.jpg', uuid:'m3Et1vguKMPXD2E7nAYbKZ'},
                        { name:'SCHEDULE YOUR 1:1 STRATEGY SESSION', icon:'mdi-calendar-cursor',  description:'Book time now to get your personalized IQ plan.', linkText: 'Schedule', link: 'https://meetings.integrateiq.com/meetings/toddearwood/messageiq-strategy-session?embed=true', duration:'2 minutes', src:'', uuid:''}
                    ],
                },
                {
                    appName: 'STATUS IQ',   
                    appIcon: '$statusIQIcon',
                    appColor: 'iq_slategray',
                    appLink: '/statusiq',
                    appDescription: 'Empower your customers to access their current status of anything found in a HubSpot dropdown property or pipeline.',
                    appLearnMoreLink: null, 
                    appIntroVideoSrc: 'https://play.vidyard.com/wmNeRogV5s6e5MCeAnP9i9.jpg',
                    appIntroVideoUuid: 'wmNeRogV5s6e5MCeAnP9i9', 
                    appIntroTitle: 'Intro to MIQ',
                    appIntroText:'Take a look at the advantages and great technology behind our tool.',
                    hasInlineHelpList: false,
                    showInlineHelp: false,
                    isLive: false,
                    InlineHelpList: [],
                },
                {
                    appName: 'PRICE IQ', 
                    appIcon: '$priceIQIcon',
                    appColor: 'iq_slategray',
                    appLink: '/priceiq',
                    appDescription: 'Automatically update line item pricing based on company price lists, lookup and manage price lists, all from inside HubSpot.',
                    appLearnMoreLink: null, 
                    appIntroVideoSrc: 'https://play.vidyard.com/wmNeRogV5s6e5MCeAnP9i9.jpg',
                    appIntroVideoUuid: 'wmNeRogV5s6e5MCeAnP9i9', 
                    appIntroTitle: 'Intro to MIQ',
                    appIntroText:'Take a look at the advantages and great technology behind our tool.',
                    hasInlineHelpList: false,
                    showInlineHelp: false,
                    isLive: false,
                    InlineHelpList: [],
                },
                {
                    appName: 'STOCK IQ', 
                    appIcon: '$stockIQicon',
                    appColor: 'iq_slategray',
                    appLink: '/stockiq',
                    appDescription: 'Search inventory levels, verify line items are in stock before you tell the customer... all from inside HubSpot.',
                    appLearnMoreLink: null, 
                    appIntroVideoSrc: 'https://play.vidyard.com/wmNeRogV5s6e5MCeAnP9i9.jpg',
                    appIntroVideoUuid: 'wmNeRogV5s6e5MCeAnP9i9', 
                    appIntroTitle: 'Intro to MIQ',
                    appIntroText:'Take a look at the advantages and great technology behind our tool.',
                    hasInlineHelpList: false,
                    showInlineHelp: false,
                    isLive: false,
                    InlineHelpList: [],
                }
            ],
            players: null,
        }
    },
    created() {
        this.$auth.auth0ClientPromise.then(async () => {
            if(Object.keys(this.currentCompany).length === 0){
                await this.$store.dispatch('getUserCompany');
                this.parseCompanyApps();
            }
        });
        VS2.load('https://play.vidyard.com/embed/v4.js').then(function () {
            VidyardEmbed.api.renderDOMPlayers();
        })
    },
    methods: {
        parseCompanyApps(){
            if(this.currentCompanyApps.integrations){
                this.launcherItems[0].isLive = true;
            }
            if(this.currentCompanyApps.messageIq){
                this.launcherItems[1].isLive = true;
            }
            if(this.currentCompanyApps.statusIq){
                this.launcherItems[2].isLive = true;
            }
            if(this.currentCompanyApps.priceIq){
                this.launcherItems[3].isLive = true;
            }
            if(this.currentCompanyApps.stockIq){
                this.launcherItems[4].isLive = true;
            }
        }
    }
}
</script>
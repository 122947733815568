<template>
    <v-container fluid class="pa-0">
        <v-card flat>
            <v-overlay :value="loadingOverlay">
                <v-progress-circular
                indeterminate
                size="70"
                width="7"
                color="primary"
                ></v-progress-circular>
            </v-overlay>
            <v-toolbar
            dark
            color="iq_darkerblue"
            app
            >
                <v-toolbar-title  class="display-3">
                    <div class="d-flex flex-column">
                        Call Logs
                        <span class="caption"> Last Update: {{ new Date() | moment( this.dateFormat) }}</span>  
                    </div>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                </v-toolbar-items>
            </v-toolbar>
            <v-card-text class="pa-0">
                <v-data-table
                    :search="search"
                    :headers="callHeaders"
                    :items="callData"   
                    :sort-by="['callType','callTime']"
                    :sort-desc="[true,true]"
                    :items-per-page="30"
                    dense
                    fixed-header
                    :height="window.height - this.appBarHeight - this.appHeaderToolbar - this.dataTableFooter"
                    show-group-by
                    multi-sort
                    @update:group-by="handleGroup"
                    class="elevation-1 fill-height"
                    :footer-props="{
                        itemsPerPageOptions: [15,30,50,100,200, -1]
                    }"
                >   
                <!-- This template looks for headers with formatters and executes them -->
                    <template
                        v-for="header in callHeaders.filter((header) =>
                            header.hasOwnProperty('formatter')
                        )"
                        v-slot:[`item.${header.value}`]="{ header, value }"
                        >
                        {{ header.formatter(value) }}
                    </template>
                    <!-- Custom colors and icons for missed and connected -->
                    <template v-slot:item.callType="{ item }">
                        <b :class="getColor(item.callType)+'--text'"> 
                            <v-icon :class="getColor(item.callType)+'--text'" x-small>{{getIcon(item.callType)}}</v-icon>
                            {{ item.callType }}
                        </b>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                x-small
                                outlined
                                color="primary"
                                v-bind="attrs"
                                v-on="on"
                                :data-user="item.firstName + item.lastName"
                                :data-phone="item.phone "
                                >
                                    Actions <v-icon small>mdi-chevron-down</v-icon>
                                </v-btn>
                            </template>
                                <v-list>
                                    <v-list-item
                                    v-for="(action, index) in actions"
                                    :key="index" 
                                    @click="callAction(action.title,item.phone)"
                                    :data-user="item.firstName + item.lastName" 
                                    :data-phone="item.phone "
                                    >
                                        <v-list-item-title>{{ action.title }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                        </v-menu>
                    </template>
                    <template v-slot:body.prepend>
                        <tr class="table-filter-row">
                            <td v-if="firstNameShown">
                                <v-text-field hide-details="true" dense class="input-xs" v-model="firstName" type="text" placeholder="First Name "></v-text-field>
                            </td>
                            <td  v-if="lastNameShown">
                                <v-text-field hide-details="true" dense class="input-xs" v-model="lastName" type="text" placeholder="Filter LastName"></v-text-field>
                            </td>
                            <td  v-if="phoneShown">
                                <v-text-field hide-details="true" dense class="input-xs" v-model="phone" type="text" placeholder="Filter Phone"></v-text-field>
                            </td>
                            <td v-if="callTypeShown">
                                <v-select
                                    hide-details="true"
                                    dense
                                    class="input-xs"
                                    v-model="callType"
                                    item-text="text"
                                    item-value="value"
                                    :items="missedFilter"
                                    placeholder="Show Only"
                                ></v-select>
                            </td>
                            <td> 
                                <v-text-field hide-details="true" dense class="input-xs" v-model="duration" type="number" placeholder="Less than"></v-text-field>
                            </td>
                            <td>
                                <v-text-field hide-details="true" dense class="input-xs" v-model="callTime" type="text" placeholder="Call Time"></v-text-field>
                            </td>
                            <td>
                                <v-btn block text
                                @click="clearFilters"
                                :disabled=" firstName === '' && lastName === '' && phone === '' && callType === '' && duration === '' && callTime === ''"
                                >
                                    <v-icon>mdi-filter-off-outline</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
import moment from "moment";
import { mapGetters } from 'vuex';
import Api from "@/services/api";

export default {
    data () {
      return {
        loadingOverlay: true,
        actions: [
            { title: 'Send Message' },
            { title: 'View HS contact' },
        ],
        dateFormat: "MM-DD-YYYY h:mm:ss a",
        search: '',
        missedFilter: [{ text:'Missed Calls', value:'Missed'},{ text:'Connected Calls', value:'Connected'},{ text:'All', value:''},],
        firstName: '',
        firstNameShown: true,
        lastName: '',
        lastNameShown: true,
        phone: '',
        phoneShown: true,
        callType: '',
        callTypeShown: true,
        duration: '',
        callTime: '',
        callData: [],
        window: { //LAYOUT TOOL: store location when page will need vertical space calculations ()
            width: 0,
            height: 0
        }

      }
    },
    methods: {
        getColor (type) {
            if (type === 'Missed') return 'error'
            else return 'success'
        },
        getIcon (type) {
            if (type === 'Missed') return 'mdi-call-missed'
            else return 'mdi-phone'
        },
        clearFilters(){
            this.firstName = "";
            this.lastName = "";
            this.phone = "";
            this.callType = "";
            this.duration = "";
            this.callTime = "";
        },
        callAction(e,f){
            if(e ==="Send Message"){
                this.sendMessage(f);
            }else{
                this.viewHS(f);
            }
        },

        async viewHS(f){
            if(f != undefined){
               let phone = f.replace( /[`~!@#$%^&*()_|+\-=?;: '",.<>\{\}\[\]\\\/]/gi, '')
               phone = "+1" + phone;
               let userCall = await this.$store.dispatch('contact/getContactByPhoneNumber',{"companyId": this.currentCompany.id, "phoneNumber": phone});
               if(userCall != undefined){
                  let url = await this.$store.dispatch('contact/getCurrentHubspotURL',userCall.id);
                  if (url != undefined){
                  window.open(url, '_blank');
                }
                   
               }
            }
        },
        async sendMessage(f){
            if(f != undefined){
               let phone = f.replace( /[`~!@#$%^&*()_|+\-=?;: '",.<>\{\}\[\]\\\/]/gi, '')
               phone = "+1" + phone;
               let userCall = await this.$store.dispatch('contact/getContactByPhoneNumber',{"companyId": this.currentCompany.id, "phoneNumber": phone});
               if(userCall != undefined){
                   if(!userCall.isUnsubscribed){
                    //   let message =  {contactId: userCall.id, companyId: this.currentCompany.id,
                    //   message: "sending test message", companyPhone: this.currentCompanyPhone.phone, mmsContent: null};
                    //   let img = await this.$store.dispatch('message/sendMessage', message);
                        this.$store.dispatch('contact/setContactToSendMessage', userCall.id);
                        this.$router.push('/conversations');
                   }

               }
            }
        },
        handleGroup(e){
            if(e.length > 0){
                if(e[0] === "callType"){
                    //hides append filters to header when groupby applied
                    this.callTypeShown = false;
                    //resets filter applied to callType if any
                    this.callType = "";
                }
                if(e[0] === "firstName"){
                    //hides append filters to header when groupby applied
                    this.firstNameShown = false;
                    //resets filter applied to callType if any
                    this.firstName = "";
                }
                if(e[0] === "lastName"){
                    //hides append filters to header when groupby applied
                    this.lastNameShown = false;
                    //resets filter applied to callType if any
                    this.lastName = "";
                }
                if(e[0] === "phone"){
                    //hides append filters to header when groupby applied
                    this.phoneShown = false;
                    //resets filter applied to callType if any
                    this.phone = "";
                }
            }else{
                this.callTypeShown = true;
                this.firstNameShown = true;
                this.lastNameShown = true;
                this.phoneShown = true;
            }

        },  
        async getCalls() {
            this.callData = [];
            let calls = await this.$store.dispatch('company/getCalls',{id: this.currentCompany.id});
            calls.forEach( (call) => {
                this.callData.push({
                    firstName: call.firstName,
                    lastName: call.lastName,
                    phone: call.phoneNumber,
                    callType: call.callType,
                    duration: call.numberOfMinutes,
                    callTime: call.dateCreated
                });
            });
            this.loadingOverlay = false;
        }              
    },
    async created() { //LAYOUT TOOL: resize handler
        this.$auth.auth0ClientPromise.then(async () => {
            if(Object.keys(this.currentCompany).length === 0){
                await this.$store.dispatch('getUserCompany');
            }
            this.getCalls();
        });
    },
    destroyed() { //LAYOUT TOOL: on leave dispose
        window.removeEventListener('resize', this.handleResize);
    },
    computed:{
        callHeaders() {
            return [
                {
                    text: "First Name",
                    value: "firstName",
                    align: "left",
                    filter: f => { return ( f + '' ).toLowerCase().includes( this[ 'firstName' ].toLowerCase() ) }
                },
                {
                    text: "Last Name", 
                    value: "lastName",
                    align: "left",
                    filter: f => { return ( f + '' ).toLowerCase().includes( this[ 'lastName' ].toLowerCase() ) }
                },
                {
                    text: "Phone Number",
                    value: "phone",
                    align: "right",
                    filter: f => { return ( f + '' ).toLowerCase().replace( /[`~!@#$%^&*()_|+\-=?;: '",.<>\{\}\[\]\\\/]/gi, '').includes( this[ 'phone' ].toLowerCase() ) }
                },
                {
                    text: "Call Type",
                    value: "callType",
                    align: "left",
                    filter: f => { return ( f + '' ).toLowerCase().includes( this[ 'callType' ].toLowerCase() ) }
                },
                {
                    text: "Call Duration", 
                    value: "duration",
                    align: "right",
                    groupable: false,
                    filter: value => {
                        if (!this.duration) return true;
                        return value < parseInt(this.duration);
                    }
                },
                {
                    text: "Call Time",
                    value: "callTime",
                    align: "right",
                    groupable: false,
                    formatter: (x) => (x ? moment(x).format(this.dateFormat) : null),
                    filter: f => { return ( f + '' ).toLowerCase().includes( this[ 'callTime' ].toLowerCase() ) }
                },
                {
                    text: "Actions",
                    value: "actions",
                    align: "center", 
                    groupable: false,
                    filterable: false,
                    sortable: false
                }

            ]
        },
        ...mapGetters({
            currentCompany: 'company/getCurrentCompany',
            currentCompanyPhone: 'company/getCurrentCompanyPhone'
        })
    }
  }
</script>
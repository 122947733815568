<template>
    <div>
        <v-row>
            <v-col cols="12">
                <h1 :class="this.$vuetify.breakpoint.smAndDown ? 'white--text text-h5 text-center' : 'secondary--text text-h2'">Sync your Account</h1>
            </v-col>
            <v-col cols="12">
                <v-card flat class="mb-6 elevation-exp-1">
                    <v-card-title>
                    <template>
                        <svg width="106" height="31" xmlns="http://www.w3.org/2000/svg"><g fill="none"><path d="M11.48 4.974v8.475H3.694V4.974H0v20.231h3.695v-8.2h7.784v8.2h3.696V4.974zm15.522 13.692a3.057 3.057 0 1 1-6.115 0V10h-3.505v8.666a6.556 6.556 0 0 0 13.112 0V10h-3.492v8.666zm25.971-7.773c0-1.777 1.175-2.34 2.462-2.34 1.038 0 2.408.79 3.305 1.748l2.295-2.706c-1.146-1.55-3.47-2.621-5.373-2.621-3.803 0-6.553 2.227-6.553 5.919 0 6.85 8.372 4.676 8.372 8.51 0 1.182-1.148 2.226-2.462 2.226-2.073 0-2.745-1.013-3.697-2.085l-2.548 2.649c1.63 2 3.64 3.016 6.047 3.016 3.613 0 6.519-2.254 6.519-5.778 0-7.604-8.373-5.241-8.373-8.538m51.8 11.08c-2.071 0-2.66-.896-2.66-2.268V13.63h3.22v-3.078h-3.22V6.491l-3.554 1.595V20.46c0 3.165 2.184 4.76 5.178 4.76.47.008.94-.03 1.402-.112l.867-3.192c-.391.027-.84.054-1.233.054M40.344 10.101c-1.736 0-2.948.504-4.12 1.653V5.095h-3.51v12.343c0 4.62 3.34 7.786 7.094 7.786 4.164 0 7.827-3.222 7.827-7.56 0-4.284-3.371-7.56-7.291-7.56m-.022 11.587a3.983 3.983 0 1 1 0-7.967 3.983 3.983 0 0 1 0 7.967m38.142-4.211c0-4.346-3.655-7.56-7.827-7.56-3.753 0-7.094 3.165-7.094 7.786v12.35h3.51v-6.668c1.17 1.147 2.384 1.653 4.118 1.653 3.92 0 7.292-3.276 7.292-7.561m-3.323-.044a3.983 3.983 0 1 1-7.967 0 3.983 3.983 0 0 1 7.967 0" fill="#33475B"/><path d="M89.806 9.75V6.223a2.716 2.716 0 0 0 1.566-2.448v-.081c0-1.5-1.216-2.716-2.716-2.716h-.081a2.716 2.716 0 0 0-2.716 2.716v.081a2.716 2.716 0 0 0 1.566 2.448V9.75a7.691 7.691 0 0 0-3.657 1.61l-9.673-7.534c.069-.249.105-.505.109-.762a3.06 3.06 0 1 0-3.064 3.054 3.027 3.027 0 0 0 1.507-.41l9.525 7.412a7.715 7.715 0 0 0 .118 8.69l-2.897 2.898a2.488 2.488 0 0 0-.724-.118 2.513 2.513 0 1 0 2.515 2.515 2.48 2.48 0 0 0-.118-.724l2.866-2.867A7.728 7.728 0 1 0 89.806 9.75m-1.188 11.6a3.965 3.965 0 1 1 0-7.927 3.965 3.965 0 0 1 .004 7.925" fill="#FF7A59"/></g></svg>
                    </template>
                    </v-card-title>
                    <v-card-subtitle> 
                        We offer a powerful Hubspot integration to chat directly with your customers within Hubspot
                    </v-card-subtitle>
                    <v-card-text>
                        <v-btn color="success" block @click="integrateHS">Connect to Hubspot</v-btn>
                    </v-card-text>
                        <v-divider></v-divider>
                    <v-card-actions class="d-flex flex-column">
                        <v-btn text block @click="showAlert"> Don't Use Hubspot?</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
         <v-footer 
        :app ="this.$vuetify.breakpoint.smAndDown"
        :class="this.$vuetify.breakpoint.smAndDown ? 'bg-mobile-overlay border-top':'bg-transparent'"
        padless 
        class="px-0 bg-transparent" 
        >
            <v-container fluid class="pa-0">
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-card-actions class="d-flex justify-space-between" :class="this.$vuetify.breakpoint.smAndDown ? '':'px-0'">
                            <v-btn v-if="isTrial" x-large outlined color="primary" to="step2"> back </v-btn>
                            <v-btn v-else  x-large outlined color="primary" to="step4"> back </v-btn>
                            <v-btn :disabled="!hasHSIntegration && !skipHSIntegration" x-large color="primary" to="/" > Next </v-btn>
                        </v-card-actions>
                    </v-col>
                </v-row>
            </v-container>
        </v-footer>       
    </div>
</template>
<script>
export default {
    name: 'accountSetup6',
    data () {
        return {
            hasHSIntegration: false,
            skipHSIntegration: false,
            isTrial:false
        }
    },
    created() {
        if(this.$route.fullPath.match('registration/MessageIQ')){
            this.isTrial = true;     
        }
    },
    methods: {
        showAlert() {
            // Use sweetalert2
            // this.$swal('Hello Vue world!!!');
            this.$swal.fire({
                title: 'Do you want to learn more about Hubspot?',
                icon: 'question',
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: 'Yes, Learn More',
                denyButtonText: "No, I'm Good",
                }).then((result) => {
                if (result.isConfirmed) {
                    let routeData = "https://pages.integrateiq.com/hubspot-learn-more";
                    window.open(routeData, '_blank');
                    this.$swal.fire({
                        title: 'Made your mind?',
                        text: 'Make your selection for integration that fits best',
                        icon: 'success'}).then(()=> {
                            
                        })
                } else if (result.isDenied) {
                    this.skipHSIntegration = true;
                    this.$swal.fire({
                        title: 'Account Created with No Hubspot Integration',
                        text: 'Integration can be Enabled later in MessageIQ Settings',
                        icon: 'info'}).then(()=> {
                            this.$router.push('/');
                        })
                }
                })
        },
        integrateHS(){
            window.location.href = process.env.VUE_APP_API_URL + `/api/HubSpotConnect?companyId=${this.currentCompany.id}`;
        }
    }
}
</script>
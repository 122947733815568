import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
//layout
import Registration from '@/components/layouts/Registration.vue'
import UserPages from '@/components/layouts/UserPages.vue'
import Iframed from '@/components/layouts/Iframed.vue'
import StandaloneApp from '@/components/layouts/StandaloneApp.vue'

//registration
import AccountSetup from '../views/AccountSetup.vue'
import Step1 from '@/components/AccountSetup/accountStep1.vue'
import Step2 from '@/components/AccountSetup/accountStep3.vue'
import Step3 from '@/components/AccountSetup/accountStep4.vue'
import Step4 from '@/components/AccountSetup/accountStep5.vue'
import Step5 from '@/components/AccountSetup/accountStep6.vue'
import Step7 from '@/components/AccountSetup/accountStep7.vue'


import Conversations from '../views/Conversations.vue'
import Conversation from '../views/Conversation.vue'
import Calls from '../views/Calls.vue'
import Contacts from '../views/Contacts.vue'
import Charts from '../views/Charts.vue'

import Settings from '../views/Settings.vue'
import Account from '@/components/AdminComponents/Account.vue'
import Automation from '@/components/AdminComponents/Automation.vue'
import BusinessHours from '@/components/AdminComponents/BusinessHours.vue'
import Templates from '@/components/AdminComponents/Templates.vue'
import Billing from '@/components/AdminComponents/Billing.vue'
import Users from '@/components/AdminComponents/Users.vue'
import BillingUpgrade from '@/components/AdminComponents/BillingUpgrade.vue'
import Integrations from '@/components/AdminComponents/Integrations.vue'
import AgencyCompanies from '@/components/AdminComponents/AgencyCompanies';
import Phones from '@/components/AdminComponents/Phones.vue'
import Calculator from '@/components/AdminComponents/Calculator.vue'
import AdminReport from '@/views/AdminReport';
import Tags from '@/components/AdminComponents/Tags.vue'
import Broadcast from '@/components/AdminComponents/Broadcast.vue'
import Help from '@/views/Help';

import Login from '@/views/Login'
import Logout from '@/views/Logout'
import AccountUpgrade from '@/views/AccountUpgrade';

import StripeCheckoutFrame from '@/views/HubSpotFrames/StripeCheckoutFrame';
import StockIQFrame from '@/views/HubSpotFrames/StockIQIframe';

import { authGuard } from '../auth/authGuard';
import { authGuardIframe } from '../auth/authGuardIframe';

//smartRamp
import Cards from '../views/SmartRamp/Cards.vue'
import IntegrationsDashboard from '../views/SmartRamp/IntegrationDashboard.vue'
import Launcher from '../views/SmartRamp/Landing.vue'
import UpTimeApps from '../views/SmartRamp/UptimeApps.vue'
import ProductView from '../views/SmartRamp/ProductView.vue'
import RecipeView from '../views/SmartRamp/RecipeView.vue'
import SmartRampRegistration from '../views/SmartRamp/SmartRampRegistration.vue'


import Wizard from '../views/SmartRamp/Wizard.vue'
import Systems from '@/components/SmartRamp/Wizard/Systems.vue'
import Triggers from '@/components/SmartRamp/Wizard/Triggers.vue'
import Objects from '@/components/SmartRamp/Wizard/Objects.vue'
import Fields from '@/components/SmartRamp/Wizard/Fields.vue'
import Approval from '@/components/SmartRamp/Wizard/Approval.vue'

//Clover App
import CloverApp from '@/components/SmartRamp/Integrations/Clover/Form.vue'
import CloverAuth from '@/components/SmartRamp/Integrations/Clover/Authentication.vue'

//Status IQ
import CreateTracker from '../views/StatusIQ/CreateTracker.vue'
import Trackers from '../views/StatusIQ/Trackers.vue'
import MessageIQTrial from '../views/MessageIQTrial.vue'

// Price IQ
import PriceIQSettings from '@/views/SmartRamp/PriceIQ/PriceIQSettings.vue';
import CreatePriceList from '@/views/SmartRamp/PriceIQ/CreatePriceList.vue';

import VueCookies from 'vue-cookies';

Vue.use(VueRouter)
Vue.use(VueCookies);

const routes = [
  {
    path: '/dash',
    name: 'Start',
    component: Launcher,
    meta: { layout: UserPages },
    beforeEnter: authGuard
  },
  {
    path: '/help',
    name: 'Help',
    component: Help
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/account-upgrade',
    name: 'AccountUpgrade',
    component: AccountUpgrade,
    beforeEnter: authGuard
  },
  {
    path: '/admin/report',
    name: 'AdminReport',
    component: AdminReport,
    meta: { layout: UserPages },
    beforeEnter: authGuard
  },
  {
    path: '/',
    name: 'Start ',
    component: Home,
    meta: { layout: UserPages },
    beforeEnter: authGuard
  },
  {
    path: '/product/:id',
    name: 'IQ Product',
    component: ProductView, 
    meta: { layout: UserPages}
  },
  {
    path: '/recipe/:id',
    name: 'IQ Integration Recipe',
    component: RecipeView, 
    meta: { layout: UserPages}
  },
  {
    path: '/appsbot',
    name: 'Applications Uptime',
    component: UpTimeApps,
    meta: { layout: UserPages }
  },
  {
    path: '/cards',
    name: 'Recipe Directory',
    component: Cards,
    meta: { layout: UserPages }
  },
  {
    path: '/integrations',
    name: 'Integrations ',
    component: IntegrationsDashboard,
    meta: { layout: UserPages }
  },
  {
    path: '/clover',
    name: 'Clover App',
    component: CloverApp,
    meta: { layout: StandaloneApp },
    beforeEnter: authGuard
  },
  {
    path: '/clover/registration',
    name: 'Clover Registration',
    component: CloverAuth,
    meta: { layout: StandaloneApp },
    beforeEnter: authGuard
  },
  {
    path: '/StatusIQ',
    name: 'Status IQ',
    component: Trackers,
    meta: { layout: UserPages},
    beforeEnter: authGuard
  },
  {
    path: '/StatusIQ/create',
    name: 'Status IQ ',
    component: CreateTracker,
    meta: { layout: UserPages},
    beforeEnter: authGuard
  },
  {
    path: '/StatusIQ/edit/:id',
    name: 'Status IQ  ',
    component: CreateTracker,
    meta: { layout: UserPages},
    beforeEnter: authGuard
  },
  {
    path: '/sr/registration',
    name: 'Status Registration',
    component: SmartRampRegistration,
    meta: { layout: Registration},
    beforeEnter: authGuard
  },
  {
    path: '/about',
    name: 'About',
    meta: { layout: UserPages, Iframed },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
  },
  {
    path: '/billing-upgrade',
    name: 'BillingUpgrade',
    component: BillingUpgrade,
    meta: { layout: UserPages },
    beforeEnter: authGuard
  },
  {
    path: '/conversations',
    name: 'Conversations',
    component: Conversations,
    meta: { layout: UserPages },
    beforeEnter: authGuard
  },
  {
    path: '/h/conversation',
    name: 'Conversation ',
    component: Conversation,
    meta: { layout: Iframed },
    beforeEnter: authGuardIframe
  },
  {
    path: '/h/stripe/checkout',
    name: 'Stripe Checkout',
    component: StripeCheckoutFrame,
    meta: { layout: Iframed }
  },
  {
    path: '/h/StockIQ/CheckInventory',
    name: 'Stock IQ Inventory',
    component: StockIQFrame,
    meta: { layout: Iframed }
  },
  {
    path: '/conversation',
    name: 'Conversation',
    component: Conversations,
    meta: { layout: Registration },
    beforeEnter: authGuard
  },
  {
    path: '/calls',
    name: 'Calls',
    component: Calls,
    meta: { layout: UserPages },
    beforeEnter: authGuard
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: Contacts,
    meta: { layout: UserPages },
    beforeEnter: authGuard
  },
  {
    path: '/charts',
    name: 'Charts',
    component: Charts,
    meta: { layout: UserPages },
    beforeEnter: authGuard
  },
  {
    path: '/registration/MessageIQ',
    component: MessageIQTrial,
    meta: { layout: Registration },
    props: true,
    beforeEnter: authGuard,
    children: [
      {
        path: "",
        name: "Step1",
        meta: { layout: Registration },
        component: Step1
      },
      {
        path: "step1",
        name: "Step1 ",
        meta: { layout: Registration },
        component: Step1
      },
      {
        path: "step2",
        name: "Step2",
        meta: { layout: Registration },
        component: Step3
      },
      {
        path: "step3",
        name: "Step3",
        meta: { layout: Registration },
        component: Step5
      },
    ]
  },
  {
    path: '/setup',
    component: AccountSetup,
    meta: { layout: Registration },
    props: true,
    beforeEnter: authGuard,
    children: [
      {
        path: "",
        name: "Step1",
        meta: { layout: Registration },
        component: Step1
      },
      {
        path: "step1",
        name: "Step1 ",
        meta: { layout: Registration },
        component: Step1
      },
      {
        path: "step2",
        name: "Step2",
        meta: { layout: Registration },
        component: Step2
      },
      {
        path: "step3",
        name: "Step3",
        meta: { layout: Registration },
        component: Step3
      },
      {
        path: "step4",
        name: "Step4",
        meta: { layout: Registration },
        component: Step4
      },
      {
        path: "step5",
        name: "Step5",
        meta: { layout: Registration },
        component: Step5
      },
    ]
  },
  {
    path: '/settings',
    component: Settings,
    props: true,
    beforeEnter: authGuard,
    children: [
      {
        path: "",
        name: 'Settings',
        meta: { layout: UserPages },
        component: Account,
      },
      {
        path: "account",
        name: 'Account',
        meta: { layout: UserPages },
        component: Account,
        
      },
      {
        path: 'automation',
        name: 'Automation',
        meta: { layout: UserPages },
        component: Automation
      },
      {
        path: 'businesshours',
        name: 'Business Hours',
        meta: { layout: UserPages },
        component: BusinessHours
      },
      {
        path: 'templates',
        name: 'Templates',
        meta: { layout: UserPages },
        component: Templates
      },
      {
        path: 'billing',
        name: 'Billing',
        meta: { layout: UserPages },
        component: Billing
      },
      {
        path: 'users',
        name: 'Users',
        meta: { layout: UserPages },
        component: Users
      },
      {
        path: 'integrations',
        name: 'Integrations',
        meta: { layout: UserPages },
        component: Integrations
      },
      {
        path: 'agency/customers',
        name: 'Agency Customers',
        meta: { layout: UserPages },
        component: AgencyCompanies
      },
      {
        path: 'phones',
        name: 'Phones',
        meta: { layout: UserPages },
        component: Phones
      },
      {
        path: 'calculator',
        name: 'Calculator',
        meta: { layout: UserPages },
        component: Calculator
      },
      {
        path: 'tags',
        name: 'Tags',
        meta: { layout: UserPages },
        component: Tags
      },
      {
        path: 'broadcast',
        name: 'Broadcast',
        meta: { layout: UserPages },
        component: Broadcast
      },
    ]
  },
  {
    path: '/wizard',
    component: Wizard,
    props: true,
    children: [
      {
        path: "",
        name: 'Systems',
        meta: { layout: UserPages },
        component: Systems,
        props: (route) => ({ query: route.query.status }) 

      },
      {
        path: "triggers",
        name: 'Triggers',
        meta: { layout: UserPages },
        component: Triggers,
        props: (route) => ({ query: route.query.status }) 

      },
      {
        path: "objects",
        name: 'Objects',
        meta: { layout: UserPages },
        component: Objects,
        props: (route) => ({ query: route.query.status }) 

      },
      {
        path: "fields",
        name: 'Fields',
        meta: { layout: UserPages },
        component: Fields,
        props: (route) => ({ query: route.query.status }) 

      },
      {
        path: "finish",
        name: 'Approval',
        meta: { layout: UserPages },
        component: Approval,
        props: (route) => ({ query: route.query.status }) 

      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

<template>
<div class="">
    <v-responsive
        class="miq-conversation-pane overflow-y-auto" id="conversation-pane"
        :max-height="window.height - appBarHeightConversations - this.conversationToolbarHeight - 152 "
    >
        <v-list class="miq-conversation-list" id="chatwindow" ref="chatwindow">
            <v-list-item class="miq-conversation-item" v-for="(chatEntry,idx) in chatEntries" :key="idx" :class="[(chatEntry.outbound ? 'outbound':'' ),( 'item-'+chatEntry.type)]" >
            <!-- TIME ENTRY DAY SEPARATOR -->
                <template v-if="chatEntry.type === 'date'">
                    <v-chip
                    color="primary"
                    class="mx-auto"
                    label
                    small
                    >
                    {{formatDay(chatEntry.day)}}
                    </v-chip>
                </template>
            <!-- REGULAR MESSAGE TEMPLATE -->
                <template v-if="chatEntry.type  === 'message'">
                    <v-list-item-avatar class="font-weight-bold" v-if="!chatEntry.outbound && !chatEntry.hsFlow">
                        {{currentChatInfo.firstName[0]}}{{currentChatInfo.lastName[0]}}

                    </v-list-item-avatar>
                    <v-list-item-avatar class="font-weight-bold" v-if="chatEntry.outbound && chatEntry.hsFlow">
                        IQ
                    </v-list-item-avatar>
                    <v-list-item-avatar class="font-weight-bold" v-if="chatEntry.outbound && !chatEntry.hsFlow">
                        {{chatEntry.initials}}
                        <v-icon v-if="chatEntry.hubspotMessage" class="primary--text">$hubspotIcon</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content :class="'status-'+chatEntry.messageStatusID">
                        <v-list-item-title class="body-1 text-wrap font-weight-medium">
                            {{chatEntry.messageBody}}
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-right">
                            <span v-if="chatEntry.hsFlow">{{chatEntry.hsFlow}} | {{chatEntry.fromNumber}} |</span>
                          {{chatEntry.hubSpotWorkflowName}} {{chatEntry.phoneInformation}} {{chatEntry.timeStamp}}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </template>
            <!-- CALLS TEMPLATE -->
                <template v-if="chatEntry.type === 'call'">
                        <v-list-item-avatar class="font-weight-bold call-avatar" v-if="!chatEntry.outbound && !chatEntry.hsFlow">
                        <v-badge    offset-x="3" offset-y="3" >                          
                            <template v-slot:badge> 
                                <v-icon v-if="!chatEntry.missed">mdi-phone</v-icon>
                                <v-icon v-else>mdi-call-missed</v-icon>
                            </template>     
                            {{currentChatInfo.firstName[0]}}{{currentChatInfo.lastName[0]}}
                        </v-badge>
                    </v-list-item-avatar>
                    <v-list-item-avatar class="font-weight-bold call-avatar" v-if="chatEntry.outbound && chatEntry.hsFlow">
                        <v-badge    offset-x="3" offset-y="3" >                              
                            <template v-slot:badge>
                                <v-icon v-if="!chatEntry.missed">mdi-phone</v-icon>
                                <v-icon v-else>mdi-call-missed</v-icon>
                            </template>     
                            IQ
                        </v-badge>
                    </v-list-item-avatar>
                    <v-list-item-avatar class="font-weight-bold call-avatar" v-if="chatEntry.outbound && !chatEntry.hsFlow">
                        <v-badge    offset-x="3" offset-y="3" >                            
                            <template v-slot:badge>
                                <v-icon v-if="!chatEntry.missed">mdi-phone</v-icon><v-icon v-else>mdi-call-missed</v-icon>
                            </template>
                            {{currentUser.userName[0]}}{{currentUser.userLastName[0]}}     
                            
                        </v-badge>
                    </v-list-item-avatar>
                    <v-list-item-content v-if="chatEntry.outbound" class="call-body" :class="chatEntry.missed ? 'call-missed' : ''">
                        <v-list-item-title class="body-1 text-wrap font-weight-medium">
                            <span v-if="chatEntry.missed">Unanswered Phone Call to</span>
                            <span v-else>Outbound Phone Call to</span>
                                {{currentChatInfo.phone}}
                        </v-list-item-title>
                        <v-list-item-subtitle v-if="chatEntry.mediaURL">
                            <vuetify-audio class="call-media elevation-0"  :file="chatEntry.mediaURL" color="primary"  :ended="audioFinish"></vuetify-audio>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="text-right">
                            {{chatEntry.phoneInformation}} | {{chatEntry.timeStamp}}
                        </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-content v-else class="call-body" :class="chatEntry.missed ? 'call-missed' : ''">
                        <v-list-item-title class="body-1 text-wrap font-weight-medium">
                            <span v-if="chatEntry.missed">Missed Call from </span>
                            <span v-else>Call received from </span>
                            {{currentChatInfo.phone}}
                        </v-list-item-title>
                        <v-list-item-subtitle v-if="chatEntry.mediaURL">
                            <vuetify-audio class="call-media elevation-0" :file="chatEntry.mediaURL" color="primary" :ended="audioFinish"></vuetify-audio>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="text-right">
                           {{chatEntry.phoneInformation}} | {{chatEntry.timeStamp}}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                    
                </template>
                <!-- MEDIA -->
                <template v-if="chatEntry.type === 'media'">
                    <v-list-item-avatar class="font-weight-bold" v-if="!chatEntry.outbound && !chatEntry.hsFlow">
                        {{currentChatInfo.firstName[0]}}{{currentChatInfo.lastName[0]}}

                    </v-list-item-avatar>
                    <v-list-item-avatar class="font-weight-bold" v-if="chatEntry.outbound && chatEntry.hsFlow">
                        IQ
                    </v-list-item-avatar>
                    <v-list-item-avatar class="font-weight-bold" v-if="chatEntry.outbound && !chatEntry.hsFlow">
                        {{chatEntry.initials}}
                    </v-list-item-avatar>
                    <v-list-item-content class="text-center">
                            <vue-responsive-image
                                v-if="chatEntry.mediaType === 'image'"
                                :image-url="chatEntry.mediaURL"
                                :width-on-screen="50"
                                :width-on-screen-tablet="75"
                                :width-on-screen-smartphone="100"
                                mode="all"
                                image-class="chat-media-img"
                            ></vue-responsive-image>
                    </v-list-item-content>
                    <v-list-item-icon class="ma-1">
                        <v-speed-dial
                            direction="bottom"
                            transition="slide-y-transition"
                            >
                            <template v-slot:activator>
                                <v-btn
                                outlined
                                color="iq_lightblue"
                                fab
                                x-small
                                >
                                    <v-icon>
                                        mdi-chevron-down
                                    </v-icon>
                                </v-btn>
                            </template>
                            <v-btn
                                rounded
                                dark
                                small
                                color="grey"
                                @click="downloadImage(chatEntry.mediaURL)"
                            >
                                
                                <v-icon>mdi-download</v-icon>
                                Download
                            </v-btn>
                        </v-speed-dial>
                    </v-list-item-icon>
                </template>
            </v-list-item>
        </v-list>
    </v-responsive>

     <v-footer class="conversation-footer" absolute padless width="auto" :class="[conversationsList ? 'footer-push-left' : '', conversationDetail ? 'footer-push-right' : '' ]">
        <div class="footer-container">
            <v-overlay 
            absolute
            dark
            opacity="0.66"
            color="iq_light"
            :value="unsubscribed"
            >
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <span class="iq_dark--text">This phone number has unsubscribed and we can't send a message.  Please ask the person to text START to your IQ number to resubscribe.</span>
                        </v-col>
                    </v-row>
                </v-container>
            </v-overlay>

            <v-overlay
            absolute
            opacity="0.95"
            :value="templateOverlay"
            class="templates-overlay"
            >
                <v-container class="py-1" fluid>
                    <v-row no-gutters class="d-flex align-center">
                        <v-col cols="auto">
                            <h4> Create a new Template </h4>
                        </v-col>
                        <v-col>
                            <v-btn
                            small
                            color="secondary"
                            outlined
                            class="ml-2"
                            @click="goTemplates"
                            >
                                Manage Templates
                            </v-btn>
                            <v-btn
                                class="float-right"
                                small
                                @click="templateOverlay = false"
                            >
                            Cancel
                            </v-btn>         
                            <v-btn 
                            color="primary"
                            class="float-right"
                            small
                            @click="saveTemplate"
                            >
                                Save
                            </v-btn>
                        
                        </v-col>
                    </v-row>
                    <v-row class="mt-0">
                        <v-col cols="4" class="py-0">
                            <v-text-field
                                    dense
                                solo
                                color="primary"
                                label="Name"
                                v-model="templateName"
                                :value="templateName"
                            ></v-text-field>         
                        </v-col>
                        <v-col cols="8"  class="py-0">
                            <v-textarea
                            row
                            dense
                            solo
                            label="Template Message"
                            rows="1"
                            row-height="20px"
                            v-model="conversationMessage"
                            :value="conversationMessage"
                            placeholder="Type a message"
                            :rules="rules"
                            hide-details="auto"
                            ></v-textarea>
                            <v-file-input
                                dense
                                solo
                                v-model="imgUrl"
                                prepend-icon=""
                                prepend-inner-icon="mdi-paperclip"
                                chips
                                label="Upload Image (optional)"
                            ></v-file-input>
                        </v-col>              
                    </v-row>
                </v-container>
            </v-overlay>            
            <v-row no-gutters>
                <v-col cols="9" md="10">
                        <div class="d-flex flex-row">
                            <v-textarea
                                auto-grow
                                full-width
                                outlined
                                clearable
                                dense
                                rows="1"
                                row-height="32px"
                                v-model="conversationMessage"
                                :counter="maxConversationMessageLength"
                                placeholder="Type a message"
                                :rules="rules"
                                :value="conversationMessage"
                                hide-details="auto"
                                @click:clear="clearMessage()"
                                :append-icon="messageHasAttachment ? 'mdi-attachment' : ''"
                            >
                                <template v-slot:counter>
                                    <span class="mx-1">{{conversationMessage.length}}</span>
                                    <span class="mx-1"> / {{maxConversationMessageLength}}</span>
                                    <span class="d-flex align-center flex-end" v-if="checkForSplits !== ''"> / <b  class="font-weight-bold warning--text ml-1"> {{checkForSplits}} SMS</b></span>
                                </template>
                            </v-textarea>

                            <v-menu
                                v-model="menu"
                                :close-on-content-click="false"
                                :nudge-width="200"
                                offset-x
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    class=""
                                    >
                                        <v-icon >
                                            mdi-emoticon-happy-outline
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <VEmojiPicker @select="selectEmoji" />
                                </v-card>
                            </v-menu>
                            <v-file-input
                                v-model="files"
                                color="primary"
                                counter
                                dense
                                solo-inverted
                                rounded
                                :class="files.length === 0 ? 'isEmpty':''"
                                class="flex-grow-0"
                                multiple
                                placeholder="Select your files"
                                prepend-icon="mdi-image-plus"
                                :show-size="1000"
                                ref="uploader"
                            >
                                <template v-slot:selection="{ index, text }">
                                <v-chip
                                    v-if="index < 2"
                                    color="primary"
                                    dark
                                    small
                                >
                                    {{ text }}
                                </v-chip>

                                <span
                                    v-else-if="index === 2"
                                    class="text-overline grey--text text--darken-3 mx-2"
                                >
                                    +{{ files.length - 2 }} File(s)
                                </span>
                                </template>
                            </v-file-input>
                            
                        </div>
                        <div class="d-flex justify-end align-end">
                        <v-autocomplete
                            v-model="templateSelected"
                            :disabled="isUpdating"
                            :items="templates"
                            placeholder="Templates"
                            clearable
                            dense
                            hide-details="true"
                            @change="sendTemplateToMessage"
                            item-text="name"
                            item-value="text"
                            :append-icon=" templateSelected === null ? '':'mdi-arrow-up-right'"
                            @click:append="AppendSendTemplateToMessage"
                            return-object
                            class="mr-1"
                        >

                            <template v-slot:item="data">
                                <template v-if="typeof data.item !== 'object'">
                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                </template>
                                <template v-else>
                                    <v-list-item-content>
                                        <v-list-item-subtitle v-html="data.item.name" class="primary--text text-subtitle"></v-list-item-subtitle>
                                        <v-list-item-title v-html="data.item.text" class="text-wrap text-bubble text-caption" ></v-list-item-title>
                                    </v-list-item-content>
                                </template>
                            </template>
                        </v-autocomplete>
                            
                        <v-autocomplete
                            v-model="hsTokenSelected"
                            :disabled="isUpdating"
                            :items="hsTokens"
                            placeholder="Hubspot Contact Token"
                            clearable
                            @click="selectAll"
                            dense
                            hide-details="true"
                            item-text="text"
                            item-value="name"
                            :append-icon=" hsTokenSelected === null ? '':'mdi-arrow-up-right'"
                            @change="getContactHubspotDynamicDataById"
                            @click:append="AppendSendTemplateToMessage"

                        >
                            <template v-slot:item="data">
                                <template v-if="typeof data.item !== 'object'">
                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                </template>
                                <template v-else>
                                    <v-list-item-content>
                                       
                                        <v-list-item-title v-html="data.item.text" class="text-wrap text-bubble text-caption" ></v-list-item-title>
                                    </v-list-item-content>
                                </template>
                            </template>
                        </v-autocomplete>

                        <v-spacer></v-spacer>

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    color="primary"
                                    @click="unsubscribeModal"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                <v-icon>mdi-cancel</v-icon>
                                </v-btn>
                            </template>
                            <span>Unsubscribe this conversation</span>

                        </v-tooltip>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    color="warning"
                                    @click="setFavorite"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-icon v-if="!isFavorite">mdi-heart-outline</v-icon>
                                    <v-icon v-else>mdi-heart</v-icon>
                                </v-btn>
                            </template>
                            <span>Set Conversation as Favorite</span>
                        </v-tooltip>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                icon
                                color="primary"
                                @click="templateOverlay = true"
                                v-bind="attrs"
                                v-on="on"
                                >
                                    <v-icon>mdi-content-save-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>Save message as Template</span>
                        </v-tooltip>
                          
                                            
                        </div>

                        

                </v-col>
                <v-col class="pl-3" cols="3" md="2">
                    <v-btn
                    class="send-btn btn-block fill-height text-center"
                    x-large
                    block
                    color="primary"
                    @click="sendMessage"
                    >
                        <span class="px-1" v-if="!this.$vuetify.breakpoint.smAndDown">Send</span>
                        <v-icon 
                            color="white"
                            right
                            class="ma-0"
                        >
                            mdi-send
                        </v-icon>
                    </v-btn>

                </v-col>
            </v-row>
        </div>
     </v-footer>

</div>
</template>
<script>
// vendor imports
import { VEmojiPicker } from 'v-emoji-picker';
import VueResponsiveImage from 'vue-responsive-image';
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
    name: 'ConversationBody',
    components: {
        VuetifyAudio: () => import('vuetify-audio'),
        VEmojiPicker, 
        VueResponsiveImage,
    },   
    props: {
        isMultiChat: {
            type: Boolean,
            default: true
        },
        isFavorite:{
            type: Boolean,
            default: false
        },
        unsubscribed:{
            type: Boolean,
            default: false
        },
        appBarHeightConversations: {
            type: Number, 
            default: 0
        },
        currentUser: {
            type: Object,
            default: null
        },
        currentChatInfo: {
            type: Object, 
            default: null
        },
        chatEntries: {
            type: Array,
            default: null
        },
        entries: {
            type: Array,
            default: null
        },
        conversationsList: {
            type: Boolean,
            default: true
        },
        conversationDetail: {
            type: Boolean,
            default: false
        },
        isConversation:{
            type:Boolean,
            default: false
        },
        BussinessPhones: {
            type: Array,
            default: null
        },
    },
    created() {
        this.$auth.auth0ClientPromise.then(async () => {
            if(Object.keys(this.currentCompany).length === 0){
                await this.$store.dispatch('getUserCompany');
                if(this.currentCompany.isHubSpotIntegrated){
                    window.addEventListener('resize', this.handleResize);
                    this.handleResize();
                    this.getProperties();
                    this.getTemplatesByCompany();
                    this.scrollToEnd();
                }else{
                    this.$swal({
                        title: `Please connect to HubSpot to Send Message.`,
                        icon: 'error',
                        confirmButtonText: "Connect to HubSpot"
                    }).then(async result => {
                        if(result.isConfirmed){
                            this.$router.push("/settings/integrations");
                        }
                    });
                }
            }else{
                if(this.currentCompany.isHubSpotIntegrated){
                    window.addEventListener('resize', this.handleResize);
                    this.handleResize();
                    this.getProperties();
                    this.getTemplatesByCompany();
                    this.scrollToEnd();
                }else{
                    this.$swal({
                        title: `Please connect to HubSpot to Send Message.`,
                        icon: 'error',
                        confirmButtonText: "Connect to HubSpot"
                    }).then(async result => {
                        if(result.isConfirmed){
                            this.$router.push("/settings/integrations");
                        }
                    });
                }
            }
        });
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    data () {
      return {
        //MainMessage Text rules 
        rules: [ v => { if (v) return v.length <= 160 || 'Max 160 characters';    else return false;  }],
        imgUrl: null,
        conversationMessage: "",
        templateName: "",
        maxConversationMessageLength: 160,
        //template overlay (save new template)
        templateOverlay: false, 
        //emoticon trigger menu floater
        menu: false,
        templates: [],
       
         //templates selector 
        hidden: false,
        autoUpdate: true,
        templateSelected: null,
        isUpdating: false,

        //Hubspot contact token autocomplete 
        hsTokenSelected: null,
        hsTokens: [],

        //attachment inclussion
        files: [],
        //media fab drop for image toolbar 
        window: { //store location when page will need vertical space calculations ()
            width: 0,
            height: 0
        },
        messageHasAttachment: false

      }
    },
    computed: {
        ...mapGetters({
            currentCompanyPhone: 'company/getCurrentCompanyPhone',
            currentContactMessages: 'message/getCurrentContactMessages',
            currentCompany: 'company/getCurrentCompany',
            currentContact: 'contact/getCurrentContact',
            currentSelectedContact: 'contact/getCurrentSelectedContact',
            currentContactData: 'contact/getCurrentContactData'
        }),
        getVariableAppHeight (){
            let maxHeight = this.window.height;
            if(isMultiChat){
                maxHeight = this.window.height - this.appBarHeightConversations - this.conversationToolbarHeight - 152;
            }
            else{
                maxHeight = this.window.height - this.conversationToolbarHeight - 152;
            }
            return maxHeight;
        },
        checkForSplits(){
            var numberOfSplits = this.conversationMessage.length / this.maxConversationMessageLength;
            var splits = "";
            if( numberOfSplits > 1){
                splits = Math.ceil(numberOfSplits);
            }
            return splits;
        },
    },
    methods: {
        AppendSendTemplateToMessage(e){
            console.log(e.target);
        },
        sendTemplateToMessage(templateMessage){
            if(templateMessage != undefined){
               this.conversationMessage = templateMessage.text;
               if(templateMessage.fileName != undefined){
                 this.parseCompanyTemplates(templateMessage);
               }
            }else{
               this.conversationMessage = "";
               this.files = "";
            }
        },

        parseCompanyTemplates(template){
                if(template.fileName !== undefined){
                    this.$store.dispatch("template/convertBase64StringtoFile",template).then(file => {
                        this.files.push(file);
                    });       
                }
            
        },

        audioFinish(){
            this.msgs.push('The audio finished.');
        },
        selectEmoji(emoji) {
            console.log(emoji.data);
            this.conversationMessage = this.conversationMessage + emoji.data;
        },

        
        async getTemplatesByCompany(){
          let templates = await this.$store.dispatch('template/getCompanyTemplatesByCompanyId',this.currentCompany.id);  
           templates.map((template)  => {this.templates.push({name: template.messageName, text: template.messageText, group: 'Group 1', fileName: template.fileName, fileType: template.fileType, fileContent: template.fileContent})});
        },
       
        pushMessageToList(message){
            this.checkDate();
            let initials = this.getInitials(this.currentCompany.name);
             this.chatEntries.push({type: 'message',timeStamp:this.formatHour(moment.utc().local()), outbound: true,  hsFlow:null,
             fromNumber: this.currentCompanyPhone.phone,  messageStatusID:"0", messageBody: message,phoneInformation:this.getPhoneInformation(),initials:initials});
        },

        pushImageToList(img){
            this.checkDate();
            let initials = this.getInitials(this.currentCompany.name);
             this.chatEntries.push({type: 'media',timeStamp:this.formatHour(moment.utc().local()), outbound: true, hsFlow:null, 
             mediaType:'image',fromNumber:null, mediaURL:img.mediaUrl,phoneInformation:this.getPhoneInformation(),initials: initials});
             
        },

        getPhoneInformation(){
            let phoneInfo = '';
            if (this.currentCompanyPhone.phone !== ''){
                var PhoneIndex = this.BussinessPhones.findIndex(x => x.phone == this.currentCompanyPhone.phone);
                if (PhoneIndex >= 0){
                    if (this.BussinessPhones[PhoneIndex].alias !== ''){
                        phoneInfo = this.BussinessPhones[PhoneIndex].alias + ' ';
                    }
                 }
            }else{
                if (this.BussinessPhones.length > 0 ){
                    if (this.BussinessPhones[1].alias !== ''){
                        phoneInfo = this.BussinessPhones[1].alias + ' ';
                    }
                }
            }

            return phoneInfo;
        },

        checkDate(){
            let dates = this.chatEntries.filter(obj => {
                    return obj.type === "date"
                });
                let needsDate = true;
                if(dates.length > 0){
                    let latestDate = moment.utc(dates[dates.length - 1].day).local();
                    let messageDate = moment.utc().local();
                    let dayDiff = messageDate.diff(latestDate,'days');
                    if(dayDiff === 0){
                        needsDate = false;
                    }
                }
                let dateEntry = {type: 'date',day: moment.utc().local()};
                if(needsDate){
                    this.chatEntries.push(dateEntry);
                }
        },

        pushMessageToConversation(message){
            var contactIndex = this.entries.findIndex(x => x.id ==this.currentSelectedContact);

            if (contactIndex >= 0){
                let conversation = this.entries[contactIndex];
                this.entries.splice(contactIndex, 1);

                if(conversation != undefined){
                    conversation.timeAgo = this.timeDifference(moment.utc().local(), moment.utc().local());
                    conversation.lastMessage = message;

                    this.entries.unshift(conversation);
                }
            } else {
                let conversation = {
                    color: '#2196F3', 
                    fullName: this.currentContactData.fullName !== "" ? this.currentContactData.fullName : null, 
                    initials: this.currentContactData.initials !== null ? this.currentContactData.initials : '??', 
                    timeAgo: this.timeDifference(moment.utc().local(), moment.utc().local()), 
                    phone: this.currentContactData.phone, 
                    status: null,
                    pending: 0,
                    lastMessage: message,
                    id: this.currentContactData.id,
                    isFavorite: this.currentContactData.isFavorite
                }
                
                this.entries.unshift(conversation);
            }
        },

        async sendMessage(){
            let imgUrl = null;
            if(this.files.length > 0){
                imgUrl = this.files[0];
            }
            let message =  {contactId: this.currentSelectedContact, companyId: this.currentCompany.id,
            message: this.conversationMessage, companyPhone: this.currentCompanyPhone.phone, mmsContent: imgUrl};
            let response = await this.$store.dispatch('message/sendMessage', message);

            if(this.files.length > 0){
                if(response != undefined){
                    await this.pushImageToList(response.data);
                }
            }
            if(this.conversationMessage !== undefined && this.conversationMessage !== null && this.conversationMessage !== ''){
                await this.pushMessageToList(response.data.body);
            }
            
            if(this.isConversation == false){
                await this.pushMessageToConversation(this.conversationMessage);
            }

            this.scrollToEnd();

            this.conversationMessage = '';
            this.files = [];   
            this.templateSelected = null;
            this.hsTokenSelected = null;

        },

        

        unsubscribeModal(){
            this.$swal.fire({
                title: 'Are you sure you want to unsubscribe this contact?',
                icon: 'question',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'Yes',
                denyButtonText: "No",
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.unsubscribeContact();
                    }
                })
        },
        async unsubscribeContact(){
            let resultUnsubscribe = this.$store.dispatch('message/Unsubscribe',this.currentSelectedContact);
                if(resultUnsubscribe){
                    this.$swal({
                        icon: 'success',
                        title: 'Contact unsubscribed',
                        text: 'Contact successfully unsubscribed.',
                        toast: true,
                        timer: 3000,
                        position: 'top-end'
                    });
                    this.unsubscribed =true;
                }else{
                    this.$swal({
                        icon: 'error',
                        title: 'Error unsubscribing contact',
                        text: 'An error occurred while unsubscribing the contact. Please try again later.',
                        toast: true,
                        timer: 3000,
                        position: 'top-end'
                    });
                }
        },
        async getProperties(){
            this.hsTokens = [];
             let contactProperties = await this.$store.dispatch('company/getContactProperties', this.currentCompany.id);
             if(contactProperties != undefined){
                contactProperties.map(property  => {this.hsTokens.push({name: property.name, text: property.label})});
             }
        },
        async getContactHubspotDynamicDataById(){
            if (this.hsTokenSelected !== undefined && this.hsTokenSelected !== null){
                let contactProperty =
                await this.$store.dispatch('company/getContactPropertySelected',{id: this.currentCompany.id, contactId: this.currentSelectedContact, propertyName: this.hsTokenSelected});
                if(contactProperty.value !== undefined && contactProperty.value !== null){
                    this.conversationMessage = this.conversationMessage + ' ' + contactProperty.value;
                    // this.hsTokenSelected = null;
                }else{
                    this.$swal({
                        icon: 'error',
                        title: 'The selected field didnt return any information.',
                        text: '',
                        toast: true,
                        timer: 3000,
                        position: 'top-end'
                    });
                }
            }      
        },

        async setFavorite(){
            let result = true;
            if(this.isFavorite == true){
                result = false;
            }         
            await this.$store.dispatch('contact/setIsFavorite', {"id": this.currentSelectedContact, "isFavorite": result});
            this.isFavorite = result;
            this.$emit('setFavorite', this.isFavorite)

            let conversation = null;
            if (this.isConversation == false){
                var contactIndex = this.entries.findIndex(x => x.id == this.currentSelectedContact);
                if (contactIndex >= 0){
                    conversation = this.entries[contactIndex];
                    if(conversation != undefined){
                        conversation.isFavorite = result;
                        this.entries[contactIndex] = conversation;
                    }
                }
            }
        },

        goTemplates(){
            this.$router.push('/settings/templates')
        },
        async saveTemplate(){
           let templateObj = {
                "id": "00000000-0000-0000-0000-000000000000",
                "companyId": this.currentCompany.id,
                "name": this.templateName,
                "templateBody": this.conversationMessage,
                "imgUrl": this.imgUrl
            };
            let response = await this.$store.dispatch('template/createOrUpdateTemplate',{
                "template": templateObj,
                "companyId": this.currentCompany.id
            });

            if(response.result){
                this.$swal({
                    icon: 'success',
                    title: `Template ${this.capitalizeFirstLetter( "created")}`,
                    text: `Your template has been successfully created!`,
                    toast: true,
                    timer: 3000,
                    position: 'top-end'
                });

                this.getTemplatesByCompany();
            }else{
                this.$swal({
                    icon: 'error',
                    title: 'Error Creating Template',
                    text: 'An error occurred while updating the template. Please try again later.',
                });
            }

            this.templateName = "";
            this.conversationMessage = "";
            this.imgUrl = null;
            this.templateOverlay = false;
        },

        downloadImage(mediaURL){
             window.open(mediaURL, '_blank');
        },
        clearMessage(){
            this.conversationMessage = '';
        },
        selectAll(event){
            event.target.select();
        },
        scrollToEnd() {   
            let container = this.$el.querySelector("#conversation-pane");
            container.scrollTop = container.scrollHeight;
        },
    }
    
}
</script>
<style lang="scss">
    .v-file-input{
        &.isEmpty{
            .v-input__control{
                display: none;
            }
        }
    }

    .v-autocomplete:not(.v-input--is-focused).v-select--chips input {
        max-height: 25px !important; 
    }

</style>
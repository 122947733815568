<template>
    <v-container class="pa-0" fluid>
        <v-stepper flat v-model="step">
            <v-stepper-header>
                <template  v-for="(section, index) in sections">
                    <v-stepper-step  :step="section.id" :key="index" :complete=" step > index + 1" >
                        {{section.name}}
                        <small class="pt-1">{{section.subText}}</small>
                    </v-stepper-step>
                    <v-divider :key="'key-'+index" v-if="index !== sections.length - 1"></v-divider>
                </template>
            </v-stepper-header>
            <v-progress-linear :value="step * 100 / sections.length "></v-progress-linear>

            <v-stepper-items v-model="step" >
                <router-view :status="status"></router-view>
            </v-stepper-items>
        </v-stepper>
  </v-container>
</template>
<script>
    export default {
        data () {
            return{
                step: 1,
                sections:[
                    {id: '1', name: 'Systems',  subText:'Data Sources',comp: 'Systems', route: '/wizard/account'},
                    {id: '2', name: 'Triggers', subText:'Sync Initiators',comp: 'Triggers', route: '/wizard/triggers'},
                    {id: '3', name: 'Objects',  subText:'Company Data Objects',comp: 'Objects', route: '/wizard/objects'},
                    {id: '4', name: 'Fields',   subText:'Field Relations',comp: 'Fields', route: '/wizard/fields'},
                    {id: '5', name: 'Approval', subText:'Ready, Set Go!',comp: 'Approval', route: '/wizard/finish'},
                ],
                status:[
                    {type:'system', value: null},
                    {type:'system', value: null},
                    {type:'trigger', value: null},
                    {type:'trigger', value: null},  
                    {type: 'objects', value: []},
                    {type: 'fields', value: []},
                    {type: 'fields', value: []},
                ],
                
            } 
        },
        updated(){
            console.log( this.$route.name);
            this.step = this.sections.map(x => x.name).indexOf(this.$route.name)+1;
        }
       
    }
</script>
<style lang="scss">

    .v-card--link:focus:before{
        opacity: 0;
    }

</style>
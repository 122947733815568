import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VForm,{ref:"callForwardingForm",attrs:{"id":"callForwardingForm","lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('h1',{class:this.$vuetify.breakpoint.smAndDown ? 'white--text text-h5 text-center' : 'secondary--text text-h2'},[_vm._v("Where should we forward your calls? (Optional)")])]),_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{directives:[{name:"mask",rawName:"v-mask",value:(_vm.phoneMask),expression:"phoneMask"}],attrs:{"color":!this.$vuetify.breakpoint.smAndDown ? 'iq_dark' : 'iq_light',"background-color":!this.$vuetify.breakpoint.smAndDown ? 'iq_light' : '',"dense":this.$vuetify.breakpoint.smAndDown,"hint":"This is usually your primary business phone number.","label":"Call Forwarding Number","outlined":"","required":""},model:{value:(_vm.callForwardingForm.callForwardingNumber),callback:function ($$v) {_vm.$set(_vm.callForwardingForm, "callForwardingNumber", $$v)},expression:"callForwardingForm.callForwardingNumber"}})],1)],1)],1),_c(VFooter,{staticClass:"px-0 bg-transparent",class:this.$vuetify.breakpoint.smAndDown ? 'bg-mobile-overlay border-top':'bg-transparent',attrs:{"app":this.$vuetify.breakpoint.smAndDown,"padless":""}},[_c(VContainer,{staticClass:"pa-0",attrs:{"fluid":""}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VCardActions,{staticClass:"d-flex justify-space-between",class:this.$vuetify.breakpoint.smAndDown ? '':'px-0'},[_c(VBtn,{attrs:{"x-large":"","outlined":"","color":"primary","to":"step3"}},[_vm._v(" back ")]),(_vm.callForwardingForm.callForwardingNumber)?_c(VBtn,{attrs:{"x-large":"","color":"“primary”"},on:{"click":_vm.createCompany}},[_vm._v(" Next ")]):_c(VBtn,{attrs:{"x-large":"","color":"“primary”"},on:{"click":_vm.showAlert}},[_vm._v(" Skip ")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import Vue from 'vue';
import Vuex from 'vuex';
import Api from "@/services/api";

// IMPORT VUEX MODULES
import companyModule from './modules/company';
import templateModule from './modules/template';
import billingModule from './modules/billing';
import messageModule from './modules/message';
import contactModule from './modules/contact';
import adminModule from './modules/admin';
import registrationModule from './modules/registration';
import chartModule from './modules/chart';
import stripeCheckout from './modules/stripeCheckout';
import tag from './modules/tag';
import broadcast from './modules/broadcast';
import recipeModule from './modules/recipe';
import cloverModule from './modules/clover';
import status from './modules/StatusIQ/status';
import stockIQModule from './modules/StockIQ/stock';
import priceIQModule from './modules/PriceIQ/price';
import smartRampModule from './modules/smartRamp';

import router from '../router';

Vue.use(Vuex);
Vue.use(require('vue-cookies'));

const store = new Vuex.Store({
    modules: {
        company: companyModule,
        template: templateModule,
        billing: billingModule,
        message: messageModule,
        contact: contactModule,
        admin: adminModule,
        registration: registrationModule,
        chart: chartModule,
        stripeCheckout: stripeCheckout,
        tag: tag,
        broadcast: broadcast,
        recipe: recipeModule,
        clover: cloverModule,
        status: status,
        stockIQ: stockIQModule,
        priceIQ: priceIQModule,
        smartRamp: smartRampModule
    },
    state: {
        user: null,
        userRole: null
    },
    mutations: {
        SET_USER(state,user){
            state.user = user;
        },
        SET_USER_ROLE(state,userRole){
            state.userRole = userRole;
        }
    },
    actions: {
        async getUserCompany({dispatch,commit}){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            var user = await Api().get(`/Web/User/GetUserAndCompany`,config).catch(err => {
                if(err.response.status === 404){
                    let notFoundUserBody = err.response.data;
                    if(notFoundUserBody.isBadEmail){
                        Vue.prototype.$swal.fire({
                            title: "Invalid Email",
                            text: "Email Address is invalid. You must use a company email.",
                            icon: 'error',
                            allowOutsideClick: false,
                            confirmButtonText: "Ok",
                            confirmButtonColor: "#0CC27E"
                        }).then(() => {
                            router.push('logout');
                        });
                    }else{
                        router.push('setup');
                    }
                }else{
                    Vue.prototype.$swal.fire({
                        title: "Error",
                        text: "An error occurred while pulling your profile data. Please try again later.",
                        icon: 'error',
                        allowOutsideClick: false,
                        confirmButtonText: "Ok",
                        confirmButtonColor: "#0CC27E"
                    }).then(() => {
                        router.push('logout');
                    });
                }
            });
            commit("SET_USER",user.data.user);
            commit("SET_USER_ROLE",user.data.userRole);
            dispatch("company/setCurrentCompany",user.data.company);
            dispatch("company/setCurrentCompanyApps",user.data.companyApps);
            return user.data;
        },
        async getUserCompanyApps({dispatch,commit},app){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            var user = await Api().get(`/Web/User/GetUserAndCompany`,config).catch(err => {
                if(err.response.status === 404){
                    let notFoundUserBody = err.response.data;
                    if(notFoundUserBody.isBadEmail){
                        Vue.prototype.$swal.fire({
                            title: "Invalid Email",
                            text: "Email Address is invalid. You must use a company email.",
                            icon: 'error',
                            allowOutsideClick: false,
                            confirmButtonText: "Ok",
                            confirmButtonColor: "#0CC27E"
                        }).then(() => {
                            router.push('logout');
                        });
                    }else{
                        router.push({path: '/sr/registration', query: { app: app}});
                    }
                }else{
                    Vue.prototype.$swal.fire({
                        title: "Error",
                        text: "An error occurred while pulling your profile data. Please try again later.",
                        icon: 'error',
                        allowOutsideClick: false,
                        confirmButtonText: "Ok",
                        confirmButtonColor: "#0CC27E"
                    }).then(() => {
                        router.push('logout');
                    });
                }
            });
            commit("SET_USER",user.data.user);
            commit("SET_USER_ROLE",user.data.userRole);
            dispatch("company/setCurrentCompany",user.data.company);
            dispatch("company/setCurrentCompanyApps",user.data.companyApps);
            return user.data;
        },
        async upgradeUserAccount({},userId){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            await Api().put(`/Web/User/SetAuthIdByUserId/${userId}`,{},config).catch(err => {
                //TODO: Show error
                console.log(err);
                return false;
            });
            return true;
        },
        async impersonateUser({dispatch, commit},companyId){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let user = await Api().put(`/Web/Admin/ImpersonateUser/${companyId}`,{},config).catch(err => {
                //TODO: Show error
                console.log(err);
                return null;
            });
            commit("SET_USER",user.data.user);
            dispatch("company/setCurrentCompany",user.data.company);
            return user.data;
        },
        async loginAsAgencyCompany({dispatch, commit},id){
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };
            let user = await Api().put(`/Web/User/ImpersonateAgencyCustomer/${id}`,{},config)
            .catch(err => {
                console.log(err);
                return null;
            });
            commit("SET_USER",user.data.user);
            dispatch("company/setCurrentCompany",user.data.company);
            return user.data;
        },
        async loginAsAgencyCompany({dispatch, commit},id){
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };
            let user = await Api().put(`/Web/User/ImpersonateAgencyCustomer/${id}`,{},config)
            .catch(err => {
                console.log(err);
                return null;
            });
            commit("SET_USER",user.data.user);
            dispatch("company/setCurrentCompany",user.data.company);
            return user.data;
        },
        async addCompanyApp({dispatch},app){
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };
            await Api().put(`Web/Company/AddCompanyApp/${app}`,{},config).catch(err => {
                return false;
            });
            dispatch("company/addCompanyApp",app);
            return true;
        }
    },
    getters: {
        getUser: state => { return state.user },
        getUserRole: state => { return state.userRole }
    }
});

export default store;
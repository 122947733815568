import Api from "@/services/api";
import Vue from "vue";
export default {
    namespaced: true,
    state: {
        inventoryDetails: []
    },
    mutations: {
        SET_INVENTORY_DETAILS(state, inventoryDetails){
            state.inventoryDetails = inventoryDetails;
        }
    },
    actions: {
        async getInventoryDetails({commit},{portalId: portalId, dealId: dealId, userId: userId}){
            let response = await Api().get(`/Web/SmartRamp/StockIQ/${portalId}/GetInventory/${dealId}/${userId}`)
            .catch(err => {
                console.log(err);
                return null;
            });
            commit('SET_INVENTORY_DETAILS',response.data);
            return response.data;
        }
    },
    getters: {
        getInventoryDetails: state => { return state.inventoryDetails }
    }
};
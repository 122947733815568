<template>
    <div>
        <v-container class="my-5" >
            <v-form id="accountForm"> 
                <v-row>
                    <v-col cols="12" md="6"> 
                        <v-card-title class="text-h5 pl-0 pb-5">
                            IQ / Company Settings
                        </v-card-title>
                        <v-text-field
                            v-model="currentCompany.name"
                            label="Company Name"
                            outlined
                        ></v-text-field>                      
                        <v-text-field
                            v-model="currentCompany.webSite"
                            label="WebSite"
                            outlined
                        ></v-text-field>         
                        <v-text-field
                            :value="formatPhone(currentCompany.iqPhoneNumber)"
                            label="Your IQ Number"
                            readonly
                            outlined
                        ></v-text-field>         
                        <v-text-field
                            v-model="currentCompany.forwardingNumber"
                            v-mask="phoneMask"
                            label="Call Forwarding Number"
                            outlined
                        ></v-text-field>         
                        <v-autocomplete
                            v-model="currentCompany.timeZone"
                            label="Primary Time Zone"
                            :items="timeZones"
                            item-value="value"
                            item-text="name"
                            outlined
                        >
                            <template v-slot:item="data">
                                <template v-if="typeof data.item !== 'object'">
                                <v-list-item-content v-text="data.item"></v-list-item-content>
                                </template>
                                <template v-else>
                                    <v-list-item-content>
                                        <span><v-list-item-title class="d-flex flex-row justify-space-between">{{ data.item.name}} <span class="caption"> {{data.item.value}}</span></v-list-item-title>
                                        </span>
                                    </v-list-item-content>
                                </template>
                            </template>
                        </v-autocomplete>
                          
                    </v-col>

                    <v-col cols="12"  md="6"> 
                        <v-card-subtitle class="text-h5 pl-0 pb-5">
                            Address 
                        </v-card-subtitle>
                        <v-text-field
                            v-model="currentCompany.address1"
                            label="Address 1"
                            outlined
                        ></v-text-field>     
                            <v-text-field
                            v-model="currentCompany.address2"
                            label="Address 2"
                            outlined
                        ></v-text-field>     
                        <v-row>
                            <v-col cols="4">
                                <v-text-field
                                    v-model="currentCompany.city"
                                    label="City"
                                    outlined
                                ></v-text-field>                                
                            </v-col>
                            <v-col cols="4">
                                <v-autocomplete
                                    v-model="currentCompany.state"
                                    label="State"
                                    :items="USStates"
                                    item-value="value"
                                    item-text="name"
                                    outlined
                                >
                                <template v-slot:item="data">
                                    <template v-if="typeof data.item !== 'object'">
                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                    </template>
                                    <template v-else>
                                        <v-list-item-content>
                                            <span><v-list-item-title class="d-flex flex-row justify-space-between">{{ data.item.name}} <span class="caption"> {{data.item.value}}</span></v-list-item-title>
                                            </span>
                                        </v-list-item-content>
                                    </template>
                                </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field
                                    v-model="currentCompany.zip"
                                    label="Zip Code"
                                    outlined
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                
            </v-form>
            <v-overlay
                opacity="0.66"
                :value="passwordChangeOverlay"
                class="password-overlay"
            >
                <v-card elevation="10" rounded="" min-width="365px">
                    <v-card-title> Password Change</v-card-title>
                    <v-divider></v-divider>
                    <v-card-title class="caption-1"> Enter both old and new password </v-card-title>
                    <v-card-text>
                    <v-row>
                        <v-col>
                            <v-form id="passwordform" ref="passwordForm" v-model="passwordFormValid" lazy-validation> 
                                <v-text-field
                                    :append-icon="password.showCurrent ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="password.showCurrent ? 'text' : 'password'"
                                    @click:append="password.showCurrent = !password.showCurrent"
                                    :rules="[rules.required, rules.currentMatch]"
                                    v-model="password.currentPasswordVerification"
                                    label="Current Password"
                                    outlined
                                ></v-text-field>  
                                <v-text-field
                                    :append-icon="password.showNew ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="password.showNew ? 'text' : 'password'"
                                    :rules="[rules.required, rules.min, rules.passwodMustChange]"
                                    @click:append="password.showNew = !password.showNew"
                                    v-model="password.newPassword"
                                    label="New Password"
                                    outlined
                                ></v-text-field>  
                                <v-text-field
                                    :append-icon="password.showNewVerify ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="password.showNewVerify ? 'text' : 'password'"
                                    @click:append="password.showNewVerify = !password.showNewVerify"
                                    :rules="[rules.required, rules.passwordMatch]"
                                    v-model="password.newPasswordVerification"
                                    label="Retype New Password"
                                    outlined
                                ></v-text-field>
                            </v-form>
                        </v-col>
                    </v-row>
                        
                    </v-card-text>
                    <v-card-actions class="d-flex justify-space-between">
                        <v-btn @click="passwordChangeOverlay = false" outlined large color="primary">Cancel</v-btn>
                        <v-btn :disabled="!passwordFormValid || this.password.newPasswordVerification === null || this.password.newPassword === null || this.password.currentPasswordVerification === null" large color="primary">Change</v-btn>
                    </v-card-actions>
                </v-card>
            </v-overlay>
            
            
        </v-container>
        <v-container fluid >
            <v-footer padless absolute >
                <v-container>
                    <v-row>
                        <v-col>
                            <v-card-actions class="d-flex justify-space-between pa-0">
                                <v-spacer></v-spacer>
                                <span class="justify-self-end">
                                    <v-btn color="primary" :disabled="!accountFormChange" v-on:click="updateCompany()"> Save </v-btn>
                                    <v-btn outlined color="secondary" :disabled="!accountFormChange" @click="restoreForm" > discard </v-btn>
                                </span>
                            </v-card-actions>
                        </v-col>
                    </v-row>
                </v-container>
            </v-footer>
        </v-container>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters({
            currentCompany: 'company/getCurrentCompany'
        })
    },
    data () {
      return {
            accountFormBuffer: {},
            phoneMask: '(###) ###-####',
            password:{
                currentPasswordVerification: null,
                showCurrent: false,
                newPassword: null,
                showNew: false,
                newPasswordVerification: null,
                showNewVerify: false
            },
            passwordFormValid : true,
            rules: {
                required: value => !!value || 'Required.',
                min: v => {    if (v) return v.length >= 8 || 'Min 8 characters';    else return false;  },
                passwordMatch: () => (this.password.newPassword === this.password.newPasswordVerification) || 'Password must match',
                passwodMustChange: () => (this.currentPasswordCheckup  !== this.password.newPassword ) || 'The new password cannot be the same as your current one',
                currentMatch: () => (this.password.currentPasswordVerification === this.currentPasswordCheckup) || 'This password does not match your current'
            },
            currentPasswordCheckup: "Password5!",
            accountFormChange: false,
            passwordChangeOverlay: false,
            timeZones: [
                {value:"EST", name:" Eastern Standard Time"},
                {value:"CST", name:" Central Standard Time"},
                {value:"MST", name:" Mountain Standard Time"},
                {value:"PST", name:" Pacific Standard Time"}
            ],
            USStates: [
                { value:"AL" , name:"Alabama"},
                { value:"AK" , name:"Alaska"},
                { value:"AZ" , name:"Arizona"},
                { value:"AR" , name:"Arkansas"},
                { value:"CA" , name:"California"},
                { value:"CO" , name:"Colorado"},
                { value:"CT" , name:"Connecticut"},
                { value:"DE" , name:"Delaware"},
                { value:"DC" , name:"District of Columbia"},
                { value:"FL" , name:"Florida"},
                { value:"GA" , name:"Georgia"},
                { value:"HI" , name:"Hawaii"},
                { value:"ID" , name:"Idaho"},
                { value:"IL" , name:"Illinois"},
                { value:"IN" , name:"Indiana"},
                { value:"IA" , name:"Iowa"},
                { value:"KS" , name:"Kansas"},
                { value:"KY" , name:"Kentucky"},
                { value:"LA" , name:"Louisiana"},
                { value:"ME" , name:"Maine"},
                { value:"MD" , name:"Maryland"},
                { value:"MA" , name:"Massachusetts"},
                { value:"MI" , name:"Michigan"},
                { value:"MN" , name:"Minnesota"},
                { value:"MS" , name:"Mississippi"},
                { value:"MO" , name:"Missouri"},
                { value:"MT" , name:"Montana"},
                { value:"NE" , name:"Nebraska"},
                { value:"NV" , name:"Nevada"},
                { value:"NH" , name:"New Hampshire"},
                { value:"NJ" , name:"New Jersey"},
                { value:"NM" , name:"New Mexico"},
                { value:"NY" , name:"New York"},
                { value:"NC" , name:"North Carolina"},
                { value:"ND" , name:"North Dakota"},
                { value:"OH" , name:"Ohio"},
                { value:"OK" , name:"Oklahoma"},
                { value:"OR" , name:"Oregon"},
                { value:"PA" , name:"Pennsylvania"},
                { value:"RI" , name:"Rhode Island"},
                { value:"SC" , name:"South Carolina"},
                { value:"SD" , name:"South Dakota"},
                { value:"TN" , name:"Tennessee"},
                { value:"TX" , name:"Texas"},
                { value:"UT" , name:"Utah"},
                { value:"VT" , name:"Vermont"},
                { value:"VA" , name:"Virginia"},
                { value:"WA" , name:"Washington"},
                { value:"WV" , name:"West Virginia"},
                { value:"WI" , name:"Wisconsin"},
                { value:"WY" , name:"Wyoming"}
            ]
      }
    },
    async created() {
        this.$auth.auth0ClientPromise.then(async () => {
            console.log(this.currentCompany);
            if(Object.keys(this.currentCompany).length === 0){
                let usercompany = await this.$store.dispatch('getUserCompany');
                //makes a security copy of source data for restore 
                this.accountFormBuffer = JSON.parse(JSON.stringify(usercompany.company))
            }
        });
    },
    watch: {
        currentCompany: {
            handler: function(v) {
                return this.accountObjHasChanged();
            },
            deep: true
        },
        password: {
            handler: function(v){
                return this.passwordObjHasChanged();
            },
            deep: true
        }
    },
    methods: {
        formatPhone(phone){
            return this.formatPhoneNumber(phone);
        },
        accountObjHasChanged(){
            if(JSON.stringify(this.currentCompany) !== JSON.stringify(this.accountFormBuffer)){
                this.accountFormChange = true;
            }else{
                 this.accountFormChange = false;
            }
        },
        passwordObjHasChanged(){
            this.$refs.passwordForm.validate();
        },
        async restoreForm(){
            if(JSON.stringify(this.currentCompany) === JSON.stringify(this.accountFormBuffer)){
                //do nothing no changes just set the variable for buttons is confirmed 
                this.accountFormChange = false;
            }
            else{
                // if anything has changed it resets and sets flag
                this.currentCompany = JSON.parse(JSON.stringify(this.accountFormBuffer));
                this.accountFormChange = false;
            }
            let userCompany = await this.$store.dispatch('getUserCompany');
            this.accountFormBuffer = JSON.parse(JSON.stringify(userCompany.company));
        },

        async updateCompany(){
        if(this.validPhone(this.currentCompany.forwardingNumber)){
              let updateResponse = await this.$store.dispatch('company/updateCompany');
              if(updateResponse){
                this.$swal({
                    icon: 'success',
                    title: 'Account Update Successful',
                    text: 'Your account has been successfully updated!',
                    toast: true,
                    timer: 3000,
                    position: 'top-end'
                });
            }else{
                this.$swal({
                    icon: 'error',
                    title: 'Error Updating Account',
                    text: 'An error occurred while updating the account. Please try again later.',
                });
            }
        }else{
            this.$swal({
                    icon:  'error',
                    title: 'Incorrect format',
                    text:  'Incorrect phone number format. Please try again.',
                });
        }
           
         
        }
    }    
}
</script>
import Api from "@/services/api";
import Vue from 'vue';
export default {
    namespaced: true,
    state: {
        currentContactData: {},
        currentContact: {},
        currentSelectedContact:{},
        currentHubspotURL:{},
        currentContactPhone:{},
        contactToSendMessage: null
    },
    mutations: {
        SET_CURRENT_CONTACT_DATA(state, currentContactData){
            state.currentContactData = currentContactData;
        },
        SET_CURRENT_CONTACT(state, currentContact){
            state.currentContact = currentContact;
        },
        SET_CURRENT_SELECTED_CONTACT(state, currentSelectedContact){
            state.currentSelectedContact = currentSelectedContact;
        },
        SET_CURRENT_HUBPOT_URL(state, currentHubspotURL){
            state.currentHubspotURL = currentHubspotURL;
        },
        SET_CURRENT_CONTACT_PHONE(state,currentContactPhone){
            state.currentContactPhone = currentContactPhone;
        },
        SET_CONTACT_TO_SEND_MESSAGE(state,contactToSendMessage){
            state.contactToSendMessage = contactToSendMessage;
        },
    },
    actions: {
        async getContactData({commit}, id){
            let response = await Api().get(`/Web/Contact/GetHubSpotContactData/${id}`)
            .catch(err => {
                console.log(err);
            });
            commit('SET_CURRENT_CONTACT_DATA',response.data);
            return response.data;
        },
        setCurrentContact({commit},contact){
            commit('SET_CURRENT_CONTACT',contact);
        },
        setCurrentSelectedContact({commit},contactId){
            commit('SET_CURRENT_SELECTED_CONTACT',contactId);
        },
        async setIsFavorite({},{id:id,isFavorite:isFavorite}){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            await Api().put(`/Web/Contact/SetFavorite/${id}/${isFavorite}`,config).catch(err => {
                console.log(err);
            });
        },
        async getCurrentHubspotURL({commit}, id){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let response = await Api().get(`/Web/Contact/GetHubSpotContactURL/${id}`,config)
            .catch(err => {
                console.log(err);
            });
            commit('SET_CURRENT_HUBPOT_URL',response.data);
            return response.data;
        },
        async updateChatInfo({}, {id: id, firstname: firstname, lastname: lastname, phonenumber: phonenumber, email: email}){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            await Api().put(`/Web/Contact/UpdateContact`,{
                Id: id,
                FirstName: firstname,
                LastName: lastname,
                PhoneNumber: phonenumber,
                Email: email
            }).catch(() => {
                return false;
            });
            return true;
        },
        async archiveConversation({},{id:id,archive:archive}){
            await Api().put(`/Web/Contact/Archive/${id}/${archive}`)
            .catch(err => {
                return false;
            });
            return true;
        },
        async markContactConversationAsReadOrUnread({},id){
            await Api().put(`/Web/Contact/MarkContactConversationAsReadOrUnread/${id}`)
            .catch(err => {
                return false;
            });
            return true;
        },
        async getContactByPhoneNumber({commit}, {companyId:companyId,phoneNumber:phoneNumber}){

            if(typeof phoneNumber === 'undefined'){
                phoneNumber = "";
            }else{       
                phoneNumber = phoneNumber.replace("+", '');
            }

            let response = await Api().get(`/Web/Contact/GetContactByPhoneNumber/${companyId}/${phoneNumber}`)
            .catch(err => {
                console.log(err);
            });
            commit('SET_CURRENT_SELECTED_CONTACT',response.data.id);
            return response.data;
        },

        setCurrentContactPhone({commit},contactPhone){
            commit('SET_CURRENT_CONTACT_PHONE',contactPhone);
        },

        setContactToSendMessage({commit},contact){
            commit('SET_CONTACT_TO_SEND_MESSAGE',contact);
        },
},
    getters: {
        getCurrentContactData: state => { return state.currentContactData},
        getCurrentContact: state => { return state.currentContact},
        getCurrentSelectedContact: state => { return state.currentSelectedContact},
        getCurrentHubspotURL: state => {return state.currentHubspotURL},
        getCurrentContactPhone: state => {return state.currentContactPhone},
        getContactToSendMessage: state => {return state.contactToSendMessage},
    }
};
<template>
    <v-container>
              <v-row>
                  <v-col>
                    <h1>Business Hours</h1>
                    
                    <v-sheet  :height="window.height - this.appBarHeight - this.settingsHeadingElement - 12 - 42 - 12">
                    <v-overlay
                        opacity="0.66"
                        :value="hoursEditor"
                        class="editorOverlay"
                    >
                        <v-card id="HoursEditor" min-width="385px" elevation="10" rounded="">
                            <v-card-title>
                                {{this.editorName}} &nbsp;
                                <span v-if="editorIsOpen">Business Hours </span>
                                <span v-else>(Day Closed)</span>
                                <v-spacer></v-spacer>
                                
                            </v-card-title>
                            <v-card-text>
                                <v-row v-if="editorIsOpen" >
                                    <v-col cols="12" md="6">
                                        <v-time-picker
                                            class="d-flex flex-column-reverse"
                                            v-model="editorStart"
                                            :disabled="!editorIsOpen"
                                            :landscape="this.$vuetify.breakpoint.lgAndUp"
                                            scrollable
                                            v-if="!this.$vuetify.breakpoint.smAndDown"
                                        >
                                        <template vslot>
                                         <span> Day Start:</span>
                                        </template>
                                        </v-time-picker>
                                        <v-menu
                                            v-else
                                            ref="menuStart"
                                            v-model="editorStartPickerMenu"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            :return-value.sync="editorStart"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="290px"
                                            :disabled="!editorIsOpen"

                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="editorStart"
                                                label="Day Start"
                                                prepend-icon="mdi-clock-time-four-outline"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                            </template>
                                            <v-time-picker
                                                v-if="editorStartPickerMenu"
                                                v-model="editorStart"
                                                full-width
                                                scrollable
                                                @click:minute="$refs.menuStart.save(editorStart)"
                                            ></v-time-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-time-picker
                                            v-model="editorEnd"
                                            class="d-flex flex-column-reverse"
                                            :disabled="!editorIsOpen"
                                            :landscape="this.$vuetify.breakpoint.lgAndUp"
                                            scrollable
                                            v-if="!this.$vuetify.breakpoint.smAndDown"
                                        >
                                        <template vslot>
                                         <span> Day End:</span>
                                        </template>
                                        </v-time-picker>
                                        <v-menu
                                            v-else
                                            ref="menuEnd"
                                            v-model="editorEndPickerMenu"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            :return-value.sync="editorEnd"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="290px"
                                            :disabled="!editorIsOpen"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="editorEnd"
                                                label="Day End"
                                                prepend-icon="mdi-clock-time-four-outline"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                            </template>
                                            <v-time-picker
                                                v-if="editorEndPickerMenu"
                                                v-model="editorEnd"
                                                full-width
                                                scrollable
                                                @click:minute="$refs.menuEnd.save(editorEnd)"
                                            ></v-time-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                                <v-row v-else>
                                    <v-col>
                                        <span class="display-2"> Day Closed</span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions> 
                                <v-btn @click="saveBusinessHours(editorName)">Save</v-btn>
                                <v-btn @click="hoursEditor = false" outlined  color="primary">Cancel</v-btn>

                                <v-spacer></v-spacer>
                                <v-switch
                                    color="primary"
                                    v-model="editorIsOpen"
                                    :label="'Is '+ this.editorName + ' open?'" 
                                    inset
                                ></v-switch>
                            </v-card-actions>
                        </v-card>
                    </v-overlay>
                        <v-calendar
                        ref="calendar"
                        :value="weekstart"
                        :events="businessHoursEvents"
                        @click:event="dayEventEditor"
                        type="week">
                            <template v-slot:day-label-header="{}">-</template>
                        </v-calendar>
                        
                    </v-sheet>

                  </v-col>
              </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters({
            currentCompany: 'company/getCurrentCompany',
            currentCompanyBusinessHours: 'company/getCurrentCompanyBusinessHours'
        }),
        weekstart(){
            return this.setToMonday(new Date())
        },
        businessHoursEvents(){
            return Array.from({ length: 7}, (element,index) => {

                let colorOpen = "";
                let hoursStart = "";
                let hoursEnd = "";
                let dayName = "";
                
                if( this.businessHours[index].open ){
                    colorOpen = "blue";
                    hoursStart =  " " + this.businessHours[index].start;
                    hoursEnd =  " " + this.businessHours[index].end;
                    dayName = this.businessHours[index].day;
                }
                else{
                    colorOpen = "grey";
                    dayName = this.businessHours[index].day + " (CLOSED)";
                }
                return{
                    name: dayName,
                    start: this.formatDate( this.getDateFromWeekDay( index ) ) + hoursStart,
                    end: this.formatDate( this.getDateFromWeekDay( index ) )  + hoursEnd,
                    color: colorOpen 
                }

            } )
        }
    },
    data () {
      return {
        hoursEditor: false,
        editorName: null,
        editorStart: null,
        editorEnd: null, 
        editorIsOpen: null,
        editorStartPickerMenu: false,
        editorEndPickerMenu: false,
        businessHours:[
            {day: 'Sunday', start:'07:00', end: '16:00', open: false},
            {day: 'Monday', start:'08:00', end: '15:00', open: true},
            {day: 'Tuesday', start:'09:00', end: '19:00', open: true},
            {day: 'Wednesday', start:'06:30', end: '13:00', open: true},
            {day: 'Thursday', start:'09:00', end: '19:00', open: true},
            {day: 'Friday', start:'10:00', end: '22:00', open: true},
            {day: 'Saturday', start:'09:00', end: '19:00', open: false}
        ],
        window: {
            width: 0,
            height: 0
        }
      }
    },
    async mounted () {
        this.$auth.auth0ClientPromise.then(async () => {
            if(Object.keys(this.currentCompany).length === 0){
                await this.$store.dispatch('getUserCompany');
                
                await this.$store.dispatch('company/getCompanyBusinessHours',this.currentCompany.id);

                this.parseCompanyBusinessHours();
            }else{
                await this.$store.dispatch('company/getCompanyBusinessHours',this.currentCompany.id);

                this.parseCompanyBusinessHours();
            }
            this.$refs.calendar.scrollToTime('06:00')
        });
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods:{
        dayEventEditor({ nativeEvent, event }){
            console.log(event);
            // console.log(nativeEvent);

            console.log( event.color );
            console.log(this.businessHoursEvents);
          
            console.log( event.start );
            console.log( event.end );
            var startHour = event.start.split(/(\s+)/);
            var endHour = event.end.split(/(\s+)/);
            console.log(startHour[2]);
            console.log(endHour[2]);
            

            var eventDay = event.name.split(/(\s+)/);
            var daySelection = null;

            switch(eventDay[0]) {
                case 'Sunday':
                    daySelection = 0;
                    break;
                case 'Monday':
                    daySelection = 1;
                    // code block
                    break;
                case 'Tuesday':
                    daySelection = 2;
                    // code block
                    break;
                case 'Wednesday':
                    daySelection = 3;
                // code block
                    break;
                case 'Thursday':
                    daySelection = 4;
                    // code block
                    break;
                case 'Friday':
                    daySelection = 5;
                    // code block
                    break;
                case 'Saturday':
                    daySelection = 6;
                    // code block
                    break;
            }
            console.log(this.businessHours[daySelection]);
            this.editorStart = this.businessHours[daySelection].start;
            this.editorEnd = this.businessHours[daySelection].end;
            this.editorName = this.businessHours[daySelection].day;
            this.editorIsOpen = this.businessHours[daySelection].open;

            this.hoursEditor = true;

        },
        setToMonday( date ) {
            //will always return the monday of this week's date, JS Date order Monday = 1 Sunday = 0 
            var day = date.getDay() || 7;  
            if( day !== 1 ) 
                date.setHours(-24 * (day - 1)); 
            return date;
        },
        getDateFromWeekDay( number ){
            //get day "number" of the current week
            var curr = new Date;
            var firstday = new Date(curr.setDate(curr.getDate() - curr.getDay()));
            var day = new Date(curr.setDate(curr.getDate() - curr.getDay()+number));
            return day;
        },
        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            return [year, month, day].join('-');
        },
        async saveBusinessHours(dayString){
            let daySelection = this.businessHours.findIndex(h => h.day === dayString);

            this.businessHours[daySelection].start =  this.editorStart;
            this.businessHours[daySelection].end = this.editorEnd;
            this.businessHours[daySelection].open = this.editorIsOpen;

            let hourUpdateData = {
                'companyId': this.currentCompany.id,
                'dayOfWeek': dayString,
                'startTime': this.editorStart,
                'endTime': this.editorEnd,
                'isOpen': this.editorIsOpen
            };
            this.hoursEditor = false;

            let result = await this.$store.dispatch('company/updateCompanyBusinessHours',hourUpdateData);
            if(result){
                this.$swal({
                    icon: 'success',
                    title: 'Business Hours Updated',
                    text: 'Business Hours successfully updated.',
                    toast: true,
                    timer: 3000,
                    position: 'top-end'
                });
            }else{
                this.$swal({
                    icon: 'error',
                    title: 'Error Updating Business Hours',
                    text: 'An error occurred while updating the business hours. Please try again later.',
                    toast: true,
                    timer: 3000,
                    position: 'top-end'
                });
            }
        },
        parseCompanyBusinessHours(){
            let businessHours = this.businessHours;
            let serverBusinessHours = this.currentCompanyBusinessHours;
            let a = 0;
            businessHours.forEach(bh => {
                switch(bh.day) {
                    case 'Sunday':
                        businessHours[a].start = serverBusinessHours.sundayStartTime;
                        businessHours[a].end = serverBusinessHours.sundayEndTime;
                        businessHours[a].open = serverBusinessHours.sundayIsOpen;
                        break;
                    case 'Monday':
                        businessHours[a].start = serverBusinessHours.mondayStartTime;
                        businessHours[a].end = serverBusinessHours.mondayEndTime;
                        businessHours[a].open = serverBusinessHours.mondayIsOpen;
                        break;
                    case 'Tuesday':
                        businessHours[a].start = serverBusinessHours.tuesdayStartTime;
                        businessHours[a].end = serverBusinessHours.tuesdayEndTime;
                        businessHours[a].open = serverBusinessHours.tuesdayIsOpen;
                        break;
                    case 'Wednesday':
                        businessHours[a].start = serverBusinessHours.wednesdayStartTime;
                        businessHours[a].end = serverBusinessHours.wednesdayEndTime;
                        businessHours[a].open = serverBusinessHours.wednesdayIsOpen;
                        break;
                    case 'Thursday':
                        businessHours[a].start = serverBusinessHours.thursdayStartTime;
                        businessHours[a].end = serverBusinessHours.thursdayEndTime;
                        businessHours[a].open = serverBusinessHours.thursdayIsOpen;
                        break;
                    case 'Friday':
                        businessHours[a].start = serverBusinessHours.fridayStartTime;
                        businessHours[a].end = serverBusinessHours.fridayEndTime;
                        businessHours[a].open = serverBusinessHours.fridayIsOpen;
                        break;
                    case 'Saturday':
                        businessHours[a].start = serverBusinessHours.saturdayStartTime;
                        businessHours[a].end = serverBusinessHours.saturdayEndTime;
                        businessHours[a].open = serverBusinessHours.saturdayIsOpen;
                        break;
                }
                a++;
            });
            this.businessHours = businessHours;
        }
    }
}
</script>
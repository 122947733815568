import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from './colors';

//Billing
import messagingIcon from '../components/shared/IQIcons/billing/messaging.vue';
import deliveryIcon from '../components/shared/IQIcons/billing/delivery.vue';
import scalingIcon from '../components/shared/IQIcons/billing/scaling.vue';
import reportsIcon from '../components/shared/IQIcons/billing/reports.vue';
import hubspotIcon from '../components/shared/IQIcons/billing/hubspot.vue';

//PRODUCTS
import iqIcon from '../components/shared/IQIcons/products/iqIcon.vue';
import activeCampaignIcon from '../components/shared/IQIcons/products/activeCampaignIcon.vue';
import messageIQIcon from '../components/shared/IQIcons/products/messageIQIcon.vue';
import integrateIQIcon from '../components/shared/IQIcons/products/integrateIQIcon.vue';
import smartRampIcon from '../components/shared/IQIcons/products/smartRampIcon.vue';
import statusIQIcon from '../components/shared/IQIcons/products/statusIQIcon.vue';
import priceIQIcon from '../components/shared/IQIcons/products/priceIQicon.vue';
import stockIQicon from '../components/shared/IQIcons/products/stockIQicon.vue';
//NAV
import startIcon from '../components/shared/IQIcons/nav/startIcon.vue';
import chartsIcon from '../components/shared/IQIcons/nav/chartsIcon.vue';
import callsIcon from '../components/shared/IQIcons/nav/callsIcon.vue';
import dashboardIcon from '../components/shared/IQIcons/nav/dashboardIcon.vue';
import settingsIcon from '../components/shared/IQIcons/nav/settingsIcon.vue';
import supportIcon from '../components/shared/IQIcons/nav/supportIcon.vue';
import contactsIcon from '../components/shared/IQIcons/nav/contactsIcon.vue';
import conversationsIcon from '../components/shared/IQIcons/nav/conversationsIcon.vue';
//GUI
import userIcon from '../components/shared/IQIcons/gui/userIcon.vue';
Vue.use(Vuetify);

// // Breakpoints
// xs: boolean
// sm: boolean
// md: boolean
// lg: boolean
// xl: boolean

// // Conditionals
// xsOnly: boolean
// smOnly: boolean
// smAndDown: boolean
// smAndUp: boolean
// mdOnly: boolean
// mdAndDown: boolean
// mdAndUp: boolean
// lgOnly: boolean
// lgAndDown: boolean
// lgAndUp: boolean
// xlOnly: boolean

const iqicons = {
  
  messagingIcon: {
    component: messagingIcon,
  },
  deliveryIcon: {
    component: deliveryIcon,
  },
  scalingIcon: {
    component: scalingIcon,
  },
  reportsIcon: {
    component: reportsIcon,
  },
  miqIcon: {
    component: iqIcon,
  },
  statusIQIcon: {
    component: statusIQIcon,
  },
  priceIQIcon: {
    component: priceIQIcon,
  },
  stockIQicon: {
    component: stockIQicon,
  },
  smartRampIcon: {
    component: smartRampIcon,
  },
  activeCampaignIcon: {
    component: activeCampaignIcon,
  },
  messageIQIcon: {
    component: messageIQIcon,
  },
  integrateIQIcon: {
    component: integrateIQIcon,
  },
  startIcon: {
    component: startIcon,
  },
  chartsIcon: {
    component: chartsIcon,
  },
  callsIcon: {
    component: callsIcon,
  },
  userIcon: {
    component: userIcon,
  },
  dashboardIcon: {
    component: dashboardIcon,
  },
  supportIcon: {
    component: supportIcon,
  },
  settingsIcon: {
    component: settingsIcon,
  },
  contactsIcon: {
    component: contactsIcon,
  },
  conversationsIcon: {
    component: conversationsIcon,
  },
  hubspotIcon: {
    component: hubspotIcon,
  }
}

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: colors.miqfucsia, //f50886
        secondary: colors.miqslategray, //868e96
        accent: colors.miqspaceblue, //based on 212e60 (darken4) using 415A99 due to offset

        error: colors.miqfiercerose, //FF586B
        info: colors.miqlightblue, //1CBCD8
        success: colors.miqgreen, //0CC27E
        warning: colors.miqtangerine,  //FF8D60

        iq_slategray: colors.miqslategray,
        iq_lightslategray: colors.miqlightslategray,
        iq_desaturatedblue: colors.miqdesaturatedblue, //6686A6
        iq_lightblue: colors.miqlightblue, // based on CDE1F6 color (lighten4) using 6BA1F5 due to offset
        iq_darkblue: colors.miqspaceblue, // miq color used in accent
        iq_darkerblue: colors.miqblackblue, // 202332
        iq_aquagreen: colors.miqturquoise, //was 778da4
        iq_cyan: '#ed0c87', 
        
        iq_light: '#ffffff',
        iq_dark: '#000000',
        iq_concrete: colors.iq_concrete,

        iq_opaque: "ffffff80"

      },
      dark: {
        primary: '#F50886',
        secondary: '#868e96',
        accent: '#212e60',
        error: '#FF586B',
        info: '#1CBCD8',
        success: '#0CC27E',
        warning: '#FF8D60',     

        iq_lightblue: '#CDE1F6',
        iq_desaturatedblue: '#7c98b6',
        iq_darkblue: '#222d60',
        iq_darkerblue: '#202332',
        iq_aquagreen: '#778da4', 
        iq_cyan: '#ed0c87',
        iq_light: '#ffffff',
        iq_dark: '#000000',

      }
    },
    breakpoint: {
      mobileBreakpoint: 'sm',
      scrollBarWidth: 15,
    },
  },
  icons: {
    values: iqicons,
  }
});


<template>
    <v-container class="fill-height pa-0" fluid>
        <v-navigation-drawer class="conversations-nav border-right"
            v-model="conversationsList"
            absolute
            floating
            left
            mobile-breakpoint="xs"
            :width="this.conversationsNavWidth"
            
            >
            <v-list-item class="px-1">
                <v-list-item-content class="pb-1 pt-1">
                            <v-text-field
                                class="rounded-pill"
                                v-model="conversationFilter"
                                label="Search"
                                dense
                                flat
                                placeholder="Type your search"
                                clearable
                                solo
                                @change="search"
                                @click:clear="clearSearch"
                                hide-details="true"
                                prepend-inner-icon="mdi-magnify"
                            ></v-text-field>  
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item class="px-2 py-0">
                <v-list-item-content class="pt-2 pb-0">
                            <v-select
                                :items="statusesFilter"
                                :value="statusesFilter[0]"
                                v-model="selectedFilter"
                                label="Filter by Conversation Status"
                                dense
                                flat
                                clearable
                                @change="search"
                                hide-details = "true"
                            ></v-select>
                </v-list-item-content>
            </v-list-item>
            <v-list-item class="px-2 py-0">
                <v-list-item-content class="pt-2 pb-0">
                    <v-select
                                :items="hubspotStatusFilter"
                                :value="statusesFilter[0]"
                                v-model="selectedMessage"
                                label="Filter By HubSpot Message"
                                dense
                                flat
                                clearable
                                @change="search"
                                hide-details = "true"
                            ></v-select>
                </v-list-item-content>
            </v-list-item>
            <v-list-item class="px-2 py-0" v-if="this.isFilterByUser">
                <v-list-item-content class="pt-2 pb-0">
                            <v-select
                                v-model="userSelected"
                                :items="userFilter"
                                :value="name"
                                label="Filter by User"
                                dense
                                flat
                                clearable
                                item-text="name"
                                item-value="value"
                                @change="getConversationByUser(userSelected)"
                                hide-details = "true"
                            ></v-select>
                </v-list-item-content>
            </v-list-item>
            <v-list-item-group
                    color="primary"
                    v-model="item"
                    active-class="active-selection"
                    mandatory
            >
                <v-virtual-scroll
                    class="conversations-nav-list"
                    :items="entries"
                    :item-height="72"
                    :height="window.height - this.conversationsNavFilterElement - this.conversationsNavFilterElement - this.conversationsNavFilterElement - this.hasFilterbyUserVisible - this.appBarHeight - dividerHeight - dividerHeight"
                    style="margin-bottom: 72;"
                    v-scroll.self="onScroll"
                    
                    >
                    <template v-slot:default="{ item, index }">
                        <v-list-item class="conversations-nav-item" two-line :data-id="index+1" :class="[item.status == 'new' ? 'new': '', item.id === currentSelectedContact ? 'active-chat':'']" v-on:click="selectContact(item.id,item.isFavorite)">
                            <v-list-item-avatar>
                                <v-badge v-if="item.status == 'new'"
                                    overlap
                                    offset-x="15"
                                    offset-y="15"                                
                                >                          
                                    <template v-slot:badge>
                                        <span>{{ item.pending }}</span>
                                    </template>     
                                    <v-avatar
                                        :color=" $vuetify.theme.dark ? 'iq_darkblue' : 'iq_desaturatedblue lighten-2'"
                                        :class=" $vuetify.theme.dark ? 'iq_light--text' : 'iq_dark--text'"
                                        class="font-weight-bold" 
                                    >
                                    <v-icon v-if="item.initials == '??'" :class=" $vuetify.theme.dark ? 'iq_light--text' : 'iq_dark--text'"> mdi-account </v-icon>
                                    <template v-else>{{ item.initials }} </template>
                             
                                    </v-avatar>
                                </v-badge>
                                <v-avatar
                                        v-else
                                        :color=" $vuetify.theme.dark ? 'iq_darkblue' : 'iq_desaturatedblue lighten-2'"
                                        :class=" $vuetify.theme.dark ? 'iq_light--text' : 'iq_dark--text'"
                                        class="font-weight-bold" 
                                    >
                                    <v-icon v-if="item.initials == '??'" :class=" $vuetify.theme.dark ? 'iq_light--text' : 'iq_dark--text'"> mdi-account </v-icon>
                                    <template v-else>{{ item.initials }} </template>
                                </v-avatar>
                            </v-list-item-avatar>
                            
                            <v-list-item-content class="pa-0">                           
                                <v-list-item-title>{{ item.fullName }}</v-list-item-title>
                                <v-list-item-subtitle class="d-flex flex-row justify-space-between">
                                            <span class="font-weight-light">{{ formatPhoneNumber(item.phone) }}</span>
                                            <span class="text-caption">{{ item.timeAgo }}</span>
                                   
                                </v-list-item-subtitle>
                                <v-list-item-subtitle><v-icon class="ma-0" left x-small>mdi-check</v-icon> {{item.lastMessage}} </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-virtual-scroll>
            </v-list-item-group>
            <template v-slot:append>
            <div class="d-flex flex-grow-1 flex-column  justify-end px-3 pb-1" style="min-height: 50px">
                <v-slide-y-reverse-transition>
                    <v-btn text block small rounded outlined primary :loading="loadingMoreContacts" v-show="loadMoreContacts" v-on:click="getNextPage">Load More...</v-btn>
                </v-slide-y-reverse-transition>
                <v-btn
                    rounded
                    block
                    class=""
                    height="50px"
                    color="primary"
                    @click="createChatOverlay = !createChatOverlay"
                    >
                    <v-icon left>mdi-forum-outline</v-icon>
                    New Conversation
                </v-btn>

                    
            </div>
            </template>
            <v-overlay absolute opacity="1" color="iq_darkerblue" v-model="createChatOverlay" class="d-flex align-end" > 
                <v-row class="pa-2">
                    <v-col cols="12">
                        Create a new  Chat
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            v-model="newChatNumber"
                            :dense="this.$vuetify.breakpoint.smAndDown"
                            hint="Enter a valid number to start a new conversation."
                            v-mask="phoneMask"
                            label="New chat number"
                            outlined
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-space-between">
                        <v-btn color="secondary" @click="createChatOverlay = !createChatOverlay">Cancel</v-btn>
                        <v-btn color="primary" @click="startChat">Start Conversation</v-btn>
                    </v-col>
                </v-row>
            </v-overlay>
                 
        </v-navigation-drawer>   
        <v-card
        :class="[conversationsList ? 'panel-push-left' : '', conversationDetail ? 'panel-push-right' : '' ]"
        class="fill-height flex-grow-1 conversation-card" 
        flat
        tile
        >  
            <v-overlay :value="loadingOverlay">
                <v-progress-circular
                indeterminate
                size="70"
                width="7"
                color="primary"
                ></v-progress-circular>
            </v-overlay>
            <div class="conversation">
                <ConversationToolbar 
                :isMultiChat="this.isMultiChat"
                :isFavorite="this.isFavorite"
                @setFavorite = "setFavorite"
                :conversationsList="this.conversationsList"
                :conversationDetail="this.conversationDetail"
                :currentUser="this.currentUser"
                :currentChatInfo="this.currentChatInfo"
                :currentChattRecepientrPhoneList = "this.currentChattRecepientrPhoneList"
                :BussinessPhoneSelected="this.BussinessPhoneSelected"
                :BussinessPhones="this.BussinessPhones"
                @setConvesationListVisibility = "setConvesationListVisibility"
                @setConversationDetailVisibility = "setConversationDetailVisibility"
                @setBussinessPhoneSelected = "setBussinessPhoneSelected"
                :ContactSelected="this.currentSelectedContact"
                :entries="this.entries"
                :chatEntries="this.chatEntries"
                :read="this.read"
                ></ConversationToolbar>

                <ConversationBody
                :isMultiChat="this.isMultiChat"
                :isFavorite="this.isFavorite"
                @setFavorite = "setFavorite"
                :unsubscribed="this.unsubscribed"
                :appBarHeight="this.appBarHeight"
                :currentUser="this.currentUser"
                :currentChatInfo="this.currentChatInfo"
                :chatEntries="this.chatEntries"
                :conversationsList="this.conversationsList"
                :conversationDetail="this.conversationDetail"
                :entries="this.entries"
                :BussinessPhones="this.BussinessPhones"
                ></ConversationBody>
                
            </div>

        </v-card>
        <v-navigation-drawer class="user-info-nav"
            v-model="conversationDetail"
            absolute
            right
            mobile-breakpoint="xs"
            width="250"
        >
            <v-toolbar-title class="d-flex align-center justify-space-between py-1 px-3">
                User Information
                <v-btn icon @click="closeUserInformation()">
                    <v-icon>mdi-window-close</v-icon>
                </v-btn>    
            </v-toolbar-title>
            <v-divider></v-divider>
            <v-container class="d-flex flex-column">
                <v-row class="my-auto flex-grow-1">
                    <v-col cols="12" >
                        <v-list-item class="d-flex flex-column">
                                <v-list-item-avatar color="iq_lightblue" elevation="5" class="ma-0 bordered text-h4" size="62">
                                    <!-- <img src="https://randomuser.me/api/portraits/men/81.jpg"> -->
                                    {{currentChatInfo.initials}}
                                </v-list-item-avatar>
                                <v-list-item-content class="text-center ma-0">
                                    <v-list-item-title>{{currentChatInfo.firstName}} {{currentChatInfo.lastName}}</v-list-item-title>
                                    <v-list-item-title>{{currentChatInfo.phone}}</v-list-item-title>
                                </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-text-field v-model="currentChatInfo.firstName"
                            label="First Name"
                        ></v-text-field>
                        <v-text-field v-model="currentChatInfo.lastName"
                            label="Last Name"
                        ></v-text-field>
                        <v-text-field :value="formatPhoneNumber(currentChatInfo.phone)"
                            label="Phone Number"
                            readonly
                            disabled
                        ></v-text-field>
                        <v-text-field v-model="currentChatInfo.email"
                            label="Email"
                        ></v-text-field>
                        <v-row >
                            <v-col class="d-flex justify-space-between">
                                <v-btn text @click="closeUserInformation()">
                                    Close
                                </v-btn>
                                <v-btn color="primary" @click="updateCurrentChatInfo">
                                    Save Changes
                                </v-btn>
                            </v-col>

                        </v-row>
                    </v-col>

                    
                        
                </v-row>
               
           </v-container>
           <template  v-slot:append>
               <v-col>
                    <v-btn rounded block color="warning darken-1" @click="getHubspotURL"> 
                        View Hubspot Contact
                    </v-btn>
                </v-col>
           </template>
        </v-navigation-drawer>
        <v-snackbar 
            v-for="(message, idx) in messageEntryList" :key="idx" 
            :style="{'margin-bottom':calcMargin(idx)}"
            v-model="snackbar"
            :timeout="timeout"
            >
            <div>{{ message.msg }}</div>
            <span class="caption">{{ message.phone }}</span>

            <template v-slot:action="{ attrs }">
                <v-btn
                color="blue"
                text
                v-bind="attrs"
                @click="snackbar = false"
                >
                Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
    
</template>

<script>

// vendor components
import ConversationToolbar from '../components/shared/_conversationToolbar.vue';
import ConversationBody from '../components/shared/_conversationBody.vue';
import moment from "moment";
import { mapGetters } from 'vuex';
import messageHub from '../messageHub';
export default {
   components: {
        ConversationToolbar,
        ConversationBody
    },
    data () {
      return {
        loadingOverlay: true,
        //snackbar demo purpose only, TODO: needs enhancement 
        newMessage: false, //this is watch for changes 
        messageEntryList: [
            {phone: '(305) 800 2533', msg: 'Hi, good day, I would like info'},
            {phone: '(305) 800 2500', msg: 'Hi, good morning, I would like info'},
            {phone: '(305) 800 3000', msg: 'Hi, good night, I would like info'}
        ],
        snackbar: false,
        timeout: 5000,
        selectedFilter: 'View All',
        selectedMessage: 'View All',
        read: false,

        //layout
        loadMoreContacts: false,
        loadingMoreContacts: false,
        isMultiChat: true,
        isFavorite: false,
        unsubscribed: false,
        conversationsList: true,
        conversationDetail: false,
        selectedConversation: null,
        desktopConversationsWidth: "250px",
        isFilterByUser:false,
        createChatOverlay: false,
        phoneMask: '(###) ###-####',
        newChatNumber: null,
        userSelected:"",
        //check for resize 
        window: {
            width: 0,
            height: 0
        },
        //mock data for user data
        currentUser: {
            userName: '', userLastName: '', iqPhone: '', email:''
        },
        //mock data for selected user (not dinamically switching yet)
        currentChatInfo: {
            firstName: ' ', lastName: ' ', phone: ' ', email:' ',initials:' '
        },
        currentChattRecepientrPhoneList:[
            { value:'50660499456' , phone: '(506)6049-9456', alias:"Ricardo Salazar (M)" , userPhoneIndex:'1'},
        ],
        chatEntries: [],
        selectedChatId: null,
        entries: [],

        userFilter: [],
        
        //Conversations filter search pattern
        conversationFilter:"",

        //temp statuses for conversations filters 
        statusesFilter: ['View All', 'Unread', 'Read', 'UnReplied', 'Sent', 'Starred', 'Closed'],
        hubspotStatusFilter: ['View All'],
        
        //Selector for Bussiness Phone 
        BussinessPhoneSelected: "",
        BussinessPhones: [],

        entries: [],
        window: { //store location when page will need vertical space calculations ()
            width: 0,
            height: 0
        },
        currentSelectedContact: null,
        lastProcessedMessage: "",
        currentChatInfoInitialData: {
            firstName: '', lastName: '', phone: '', email:'',initials:''
        },
        item: null,
        name: null
      }
    },
    created() {
        this.$auth.auth0ClientPromise.then(async () => {
            if(Object.keys(this.currentCompany).length === 0){
                await this.$store.dispatch('getUserCompany');
                if(this.currentCompany.confirmed){
                    if(this.currentCompany.isHubSpotIntegrated || this.currentCompany.isActiveCampaignIntegrated || this.currentCompany.isBroadcastCompany){
                        await this.getCompaniesPhones();
                        this.getConversations('all');
                        if(this.currentCompany.isHubSpotIntegrated){
                            this.getHubSpotMessages();
                        }
                        this.getFilterByUser();
                        this.setCurrentUser();
                        window.addEventListener('resize', this.handleResize);
                        this.handleResize();
                    }else{
                        this.$swal({
                            title: `Please connect to HubSpot to Send Message.`,
                            icon: 'error',
                            confirmButtonText: "Connect to HubSpot"
                        }).then(async result => {
                            if(result.isConfirmed){
                                this.$router.push("/settings/integrations");
                            }
                        });
                    }
                }else{
                    this.$swal({
                        title: `Please confirm email.`,
                        text: `To send messages, you must confirm your account's email address. Please click the link in the email that was sent when registering to confirm your account.`,
                        icon: 'error',
                        confirmButtonText: "Okay"
                    }).then(async result => {
                        if(result.isConfirmed){
                            this.$router.push("/");
                        }
                    });
                }
            }else{
                if(this.currentCompany.confirmed){
                    if(this.currentCompany.isHubSpotIntegrated || this.currentCompany.isActiveCampaignIntegrated || this.currentCompany.isBroadcastCompany){
                        await this.getCompaniesPhones();
                        this.getConversations('all');
                        if(this.currentCompany.isHubSpotIntegrated){
                            this.getHubSpotMessages();
                        }
                        this.getFilterByUser();
                        this.setCurrentUser();
                        window.addEventListener('resize', this.handleResize);
                        this.handleResize();
                    }else{
                        this.$swal({
                            title: `Please connect to HubSpot to Send Message.`,
                            icon: 'error',
                            confirmButtonText: "Connect to HubSpot"
                        }).then(async result => {
                            if(result.isConfirmed){
                                this.$router.push("/settings/integrations");
                            }
                        });
                    }
                }else{
                    this.$swal({
                        title: `Please confirm email.`,
                        text: `To send messages, you must confirm your account's email address. Please click the link in the email that was sent when registering to confirm your account.`,
                        icon: 'error',
                        confirmButtonText: "Okay"
                    }).then(async result => {
                        if(result.isConfirmed){
                            this.$router.push("/");
                        }
                    });
                }
            }
        });
    },
    mounted(){
        messageHub.client.on("Send",(message) => {
            this.handelIncommingMessage(message);
        });
        messageHub.start();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    computed: {
        ...mapGetters({
            currentCompany: 'company/getCurrentCompany',
            currentCompanyPhone: 'company/getCurrentCompanyPhone',
            currentContact: 'contact/getCurrentContact',
            currentUserRole: 'getUserRole',
            user: 'getUser',
            contactToSendMessage: 'contact/getContactToSendMessage'
        }),
        hasFilterbyUserVisible(){
            let elementheight;

            if(this.isFilterByUser){
                elementheight =  this.conversationsNavFilterElement;
            }
            else{
                elementheight =  0;
            }
            return elementheight;
        }
    },
    watch: {
        isUpdating (val) {
            if (val) {
            setTimeout(() => (this.isUpdating = false), 3000)
            }
        },
        newMessage: function (newValue, oldValue) {
            console.log(oldValue);
            console.log("now");
            console.log(newValue);
            this.snackbar = true;
        },
    },
    methods: {
        test(){
            console.log('click image for viewer goes here')
        },
        calcMargin(i) {
            return (i*70) + 'px'
        },
        genRandomIndex (length) {
            return Math.ceil(Math.random() * (length - 1))
        },
        uploadFile() {
            this.conversationDetail = !this.conversationDetail;
        },
        remove (item) {
            const i = this.friends.indexOf(item.name)
            if (i >= 0) this.friends.splice(i, 1)
        },
        onScroll (e) {
            let container = e.target;
            let elementsOnDisplay = container.querySelectorAll('.v-virtual-scroll__container .v-virtual-scroll__item .conversations-nav-item');

            let totalEntries = this.entries.length;

            if(parseInt(elementsOnDisplay[elementsOnDisplay.length-1].getAttribute('data-id')) === totalEntries){
                this.loadMoreContacts = true;
            }
            else{
                this.loadMoreContacts = false;
            }
        },
        async setCurrentUser(){
            let contactInfo = await this.getCurrentContactInfo(this.user.userId);
            this.currentChatInfo.firstName = contactInfo.firstName;
            this.currentChatInfo.lastName = contactInfo.lastName !== null ? contactInfo.lastName : " ";  
            this.currentChatInfo.phone = contactInfo.phone;
            this.currentChatInfo.email = contactInfo.email;
            this.currentChatInfo.initials = this.getConversationInitials(contactInfo.firstName + " " + contactInfo.lastName);
        },

        async getFilterByUser(){
            if(this.currentUserRole === "SuperAdmin" || this.currentUserRole === "AccountAdmin"){
               let users = await this.$store.dispatch('company/getUsersWithPhoneNumbers', this.currentCompany.id);
               if(users.length > 0){
                   this.userFilter.push({ value: "", name: "View All", phone:""});
                   users.map((user)  => {this.userFilter.push({ value: user.userId, name: (user.firstName +" "+ user.lastName)})});
                   this.isFilterByUser = true;
                   this.userSelected = this.userFilter[0];
               }
            }
        },
        
        setConvesationListVisibility( value) {
            this.conversationsList = value;
        },
        setConversationDetailVisibility( value) {
            this.conversationDetail = value;
        },
        setBussinessPhoneSelected(value) {
            this.BussinessPhoneSelected = value;
            this.$store.dispatch('company/setCurrentCompanyPhone',value);
            this.getContactMessages();
        },
        search(){
            let filter = this.selectedFilter ? this.selectedFilter : 'all';
            filter = filter === "View All" ? 'all' : filter;

            let messageHubSpot = this.selectedMessage ? this.selectedMessage : '';
            messageHubSpot = messageHubSpot === "View All" ? '' : messageHubSpot;

            let search = this.conversationFilter ? this.conversationFilter : '';

            this.getConversations(filter, messageHubSpot, search);
        },
        clearSearch() { 
            this.conversationFilter = '';
            this.getConversations(this.selectedFilter, this.selectedMessage, '');
        },

        async getConversationByUser(value) {
            let filter = this.selectedFilter ? this.selectedFilter : 'all';
            filter = filter === "View All" ? 'all' : filter;

            let messageHubSpot = this.selectedMessage ? this.selectedMessage : '';
            messageHubSpot = messageHubSpot === "View All" ? '' : messageHubSpot;

            let search = this.conversationFilter ? this.conversationFilter : '';

            await this.getCompaniesPhones();
            this.getConversations(filter, messageHubSpot, search, value);
        },

        async getCompaniesPhones() {
            this.BussinessPhones = [];
            let companiesPhones = [];

            if(this.currentUserRole === 'User'){
                 companiesPhones = await this.$store.dispatch('message/getCompanyPhonesByUserId', this.user.userId);
            }else if(this.userSelected != "" && this.userSelected != undefined && this.userSelected !=null){
                 companiesPhones = await this.$store.dispatch('message/getCompanyPhonesByUserId', this.userSelected);
            }else{
                 companiesPhones = await this.$store.dispatch('message/getCompanyPhones', this.currentCompany.id);
            }

            if(companiesPhones != undefined){
                console.log(companiesPhones);
                if(companiesPhones.length > 1){
                    this.BussinessPhones.push({ value: '--', phoneIndex: '0', alias: 'View All', phone: ''});
                }
                companiesPhones.map((company, index)  => {this.BussinessPhones.push({ value: '--', phoneIndex: (index + 1), alias: company.name, phone: company.phoneNumber})});
                if(this.BussinessPhones.length > 1){
                   this.setBussinessPhoneSelected(this.BussinessPhones[0].phone);
                }else{
                    this.setBussinessPhoneSelected("");
                }
                

            }
            
          
        },
        async getContactMessages() {
           this.read = false;
           let contactMessages = await this.$store.dispatch('message/getContactMessages',{"contactId": this.currentSelectedContact, "companyPhone":  this.BussinessPhoneSelected}); 
           this.chatEntries = [];
            if(contactMessages != undefined){ 
                Object.keys(contactMessages).map(key => {
                    this.chatEntries.push({type: 'date',day: this.formatDay(contactMessages[key].item1)});
                    contactMessages[key].item2.map(message => {
                        let initials = '';
                        let hsMessage = '';
                        let direction = false;
                        let phoneInfo = '';
                        let hubspotMessage = false;
                        if(message.direction === 'outbound-api'){
                            direction = true;
                            if (message.fromPhone !== ''){
                                var PhoneIndex = this.BussinessPhones.findIndex(x => x.phone == message.fromPhone);
                                if (PhoneIndex >= 0){
                                    if (this.BussinessPhones[PhoneIndex].alias !== ''){
                                        phoneInfo = this.BussinessPhones[PhoneIndex].alias + ' |';
                                    }
                                }
                            }

                            if(message.sourceType === 'hubspot'){
                                hsMessage =message.smsMessageHubSpotData.hubSpotWorkflowName + ' | ';
                                hubspotMessage = true;
                            }else{
                                initials = this.getInitials(this.currentCompany.name).slice(0, 2);
                                hsMessage = '';
                            }
                        }
                        if(message.mediaType === 'Image'){
                            this.chatEntries.push({type: 'message',timeStamp: this.formatHour(message.dateCreated), outbound: direction,  hsFlow:null, 
                            fromNumber: message.fromPhone,  messageStatusID:"0", messageBody: message.body,phoneInformation:phoneInfo,initials: initials, hubSpotWorkflowName:hsMessage, hubspotMessage:hubspotMessage});
                        }else if(message.mediaType === 'video/3gpp'){
                            this.chatEntries.push({type: 'media',  timeStamp:  this.formatHour(message.dateCreated), outbound: direction,   
                            mediaType:'video',   hsFlow:null, fromNumber:null,  mediaURL:message.mediaUrl,phoneInformation:phoneInfo,initials: initials, hubSpotWorkflowName:hsMessage, hubspotMessage:hubspotMessage});
                        }else{
                            this.chatEntries.push({type: 'media',  timeStamp:  this.formatHour(message.dateCreated), outbound: direction,   
                            mediaType:'image',   hsFlow:null, fromNumber:null,  mediaURL:message.mediaUrl,phoneInformation:phoneInfo,initials: initials, hubSpotWorkflowName:hsMessage, hubspotMessage:hubspotMessage});
                        }
                    });
                });
                
            }
        },

        async getContactInfo(){
             this.currentChatInfo = {firstName: '',lastName: '',phone: '',email: '',initials: ''};
             let contactInfo = await this.getCurrentContactInfo(this.currentSelectedContact);
             let initials = this.getConversationInitials(contactInfo.firstName + " " + contactInfo.lastName);
             this.currentChatInfo = {firstName: contactInfo.firstName,lastName: contactInfo.lastName !== null ? contactInfo.lastName : " ",phone: contactInfo.phone,email: contactInfo.email,initials:initials};
             this.currentChatInfoInitialData = {firstName: contactInfo.firstName,lastName: contactInfo.lastName !== null ? contactInfo.lastName : " ",phone: contactInfo.phone,email: contactInfo.email,initials:initials};
             if(contactInfo.isUnsubscribed){
                 this.unsubscribed = true;
             }else{
                  this.unsubscribed = false;
             }
        },

        async getCurrentContactInfo(contact){
            let contactInfo;

            this.contactToSendMessage ? 
                contactInfo = await this.$store.dispatch('contact/getContactData',this.contactToSendMessage) :
                contactInfo = await this.$store.dispatch('contact/getContactData',contact);

            if(contactInfo.firstName == null || contactInfo.firstName == undefined){
                contactInfo.firstName = "";
            }
            if(contactInfo.lastName == null || contactInfo.lastName == undefined){
                contactInfo.lastName = "";
            }

            return contactInfo;
        },

        async startChat(){
            let newConversation = await this.$store.dispatch('message/getnewConversation',{"companyId": this.currentCompany.id, "contactPhone": this.newChatNumber}); 
            let conversation = null;
            if (newConversation != undefined){
                var contactIndex = this.entries.findIndex(x => x.id == newConversation.id);
                if (contactIndex >= 0){
                    conversation = this.entries[contactIndex];
                    this.entries.splice(contactIndex, 1);
                    this.entries.unshift(conversation);
                }else{
                    conversation = {color: '#2196F3', 
                        fullName: null, 
                        initials: '??', 
                        timeAgo: this.timeDifference(moment.utc().local(), moment.utc().local()), 
                        phone: this.newChatNumber, 
                        status: 'new',
                        pending: 0,
                        lastMessage: '',
                        id: newConversation.id,
                        isFavorite: false
                   }
                    this.chatEntries = [];
                }
                await this.selectContact(conversation.id,conversation.isFavorite);
              }
           this.createChatOverlay = false;
        },

        async getConversations(cFilter, messageHubSpot = "", selMessageName = "",user = ""){
            this.entries = [];
            let conversations = await this.$store.dispatch('company/getConversations',{id: this.currentCompany.id,skip: 0, top: 20, filter:cFilter, messageHubSpot:messageHubSpot, messageName:selMessageName, idUser:user}); 
            conversations.map((conversation)  => {

                let name = "";
                let initials = "";

                let status = "";

                if(conversation.fullName == "") {
                    name = this.formatPhoneNumber(conversation.phone);
                    initials = "??";
                } else {
                    initials = this.getConversationInitials(conversation.fullName);
                }

                if(conversation.unreadMessagesCount > 0) {
                    status = "new";
                }

                this.entries.push({
                    color: '#2196F3', 
                    fullName: conversation.fullName, 
                    initials: initials.toUpperCase(), 
                    timeAgo: this.timeDifference(moment.utc().local(), moment.utc(conversation.lastContactDate).local()), 
                    phone: conversation.phone, 
                    status: status,
                    pending: conversation.unreadMessagesCount,
                    lastMessage: conversation.lastMessageText,
                    id: conversation.id,
                    isFavorite: conversation.isFavorite
                });
            });
            if(conversations.length > 0){
                await this.selectContact(conversations[0].id,conversations[0].isFavorite);
                await this.getContactMessages();
                await this.getContactInfo();
                await this.chatWindowScroll();
                this.clearContactToSendMesssage();
                this.loadingOverlay = false;
            }
        },

        getNextPage(){
            this.loadingMoreContacts = true;

            let skip = this.entries.length;
            if(skip < 20){
                return;
            }

            let filter = this.selectedFilter ? this.selectedFilter : 'all';
            filter = filter === "View All" ? 'all' : filter;

            let messageHubSpot = this.selectedMessage ? this.selectedMessage : '';
            messageHubSpot = messageHubSpot === "View All" ? '' : messageHubSpot;

            let search = this.conversationFilter ? this.conversationFilter : '';

            let user = this.userSelected;

            setTimeout(async () => {

                let conversations = await this.$store.dispatch('company/getConversations',{id: this.currentCompany.id,skip: skip, top: 20, filter:filter, messageHubSpot:messageHubSpot, messageName:search, idUser:user}); 
                conversations.map((conversation)  => {

                    let name = "";
                    let initials = "";

                    let status = "";

                    if(conversation.fullName == "") {
                        name = this.formatPhoneNumber(conversation.phone);
                        initials = "??";
                    } else {
                        initials = this.getConversationInitials(conversation.fullName);
                    }

                    if(conversation.unreadMessagesCount > 0) {
                        status = "new";
                    }

                    this.entries.push({
                        color: '#2196F3', 
                        fullName: conversation.fullName, 
                        initials: initials.toUpperCase(), 
                        timeAgo: this.timeDifference(moment.utc().local(), moment.utc(conversation.lastContactDate).local()), 
                        phone: conversation.phone, 
                        status: status,
                        pending: conversation.unreadMessagesCount,
                        lastMessage: conversation.lastMessageText,
                        id: conversation.id,
                        isFavorite: conversation.isFavorite
                    });
                });
                this.loadingMoreContacts = false;
                this.loadMoreContacts = false;
            },500);
            
        },

        getConversationInitials(fullName){
            let initials = '';
            let  name = fullName.split(" ");
             if(name.length > 1){
                initials = name[0][0] + "" + name[1][0];
            }else{
                initials = name[0][0];
            }

            return initials;
        },

        async searchMessages(searchQuery) {

            this.entries = [];

            let results = await this.$store.dispatch('company/searchMessages', {id: this.currentCompany.id, searchText: searchQuery});
            results.map((conversation)  => {

                let name = "";
                let initials = "";

                if(conversation.fullName == "") {
                    name = conversation.phone.replace("+1", "").replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
                    initials = "??";
                } else {
                    initials = this.getConversationInitials(conversation.fullName);
                }

                this.entries.push({
                    color: '#2196F3', 
                    fullName: name, 
                    initials: initials.toUpperCase(), 
                    timeAgo: this.timeDifference(moment.utc().local(), moment.utc(conversation.dateSent).local()), 
                    phone: conversation.phone, 
                    status: conversation.status,
                    pending: conversation.unreadMessagesCount,
                    lastMessage: conversation.body 
                });

            });

        },
        async getHubSpotMessages() {

            let messages = await this.$store.dispatch('company/getHubSpotMessages', {id: this.currentCompany.id});

            messages.forEach( (message) => {
                this.hubspotStatusFilter.push(message);
            });

        },
        async selectContact(contactId,isFavorite){
            this.contactToSendMessage

            this.contactToSendMessage ? 
                this.currentSelectedContact = this.contactToSendMessage : 
                this.currentSelectedContact = contactId;

            this.isFavorite = isFavorite;

            this.contactToSendMessage ? 
                await this.$store.dispatch('contact/setCurrentSelectedContact',this.contactToSendMessage) :
                await this.$store.dispatch('contact/setCurrentSelectedContact',contactId);
            await this.getContactInfo();
            await this.getContactMessages();
            let conversation = null;
            var contactIndex = this.entries.findIndex(x => x.id == this.currentSelectedContact);
            if (contactIndex >= 0){
                conversation = this.entries[contactIndex];
                conversation.status= '',
                conversation.pending = 0;
                conversation.color =  '#2196F3'
                this.entries[contactIndex] = conversation;
            }

            this.scrollToEnd();
        },

        handelIncommingMessage(incommingMessage){
            this.pushMessage(incommingMessage);
            if(this.currentSelectedContact === incommingMessage.smsMessageContactData.contactId && this.lastProcessedMessage !== incommingMessage.id){
                this.lastProcessedMessage = incommingMessage.id;
                let dates = this.chatEntries.filter(obj => {
                    return obj.type === "date"
                });
                let needsDate = true;
                if(dates.length > 0){
                    let latestDate = moment.utc(dates[dates.length - 1].day).local();
                    let messageDate = moment.utc(incommingMessage.dateCreated).local();
                    let dayDiff = messageDate.diff(latestDate,'days');
                    if(dayDiff === 0){
                        needsDate = false;
                    }
                }
                let dateEntry = {type: 'date',day: incommingMessage.dateCreated};
    
                if(needsDate){
                    this.chatEntries.push(dateEntry);
                }
                this.pushMessageToConversation(incommingMessage);
                setTimeout(() => {
                    this.chatWindowScroll();
                },200);
            }
        },

        pushMessage(incommingMessage){
            var contactIndex = this.entries.findIndex(x => x.id == incommingMessage.smsMessageContactData.contactId);
            if (contactIndex >= 0){
                let conversation = this.entries[contactIndex];
                this.entries.splice(contactIndex, 1);
                if(conversation != undefined){
                    conversation.timeAgo = this.timeDifference(moment.utc().local(), moment.utc(incommingMessage.dateCreated).local());
                    conversation.lastMessage = incommingMessage.body;
                    conversation.status= 'new',
                    conversation.pending = 1; //pending ???
                    conversation.color =  '#f8f3f5'
                    this.entries.unshift(conversation);
                }
            }
        },

        pushMessageToConversation(incommingMessage){
            if(incommingMessage.mediaType === 'Image'){
                let messageEntry = {
                    type: 'message',
                    timeStamp: this.formatHour(incommingMessage.dateCreated), 
                    outbound: false,  
                    hsFlow:null, 
                    fromNumber: incommingMessage.fromPhone,  
                    messageStatusID:"0", 
                    messageBody: incommingMessage.body
                };  

                  this.chatEntries.push(messageEntry);

            }else if(incommingMessage.mediaType === 'video/3gpp'){
            }else{
                let messageEntry = {
                    type: 'media',
                    timeStamp: this.formatHour(incommingMessage.dateCreated), 
                    outbound: false,  
                    mediaType:'image',
                    hsFlow:null, 
                    fromNumber: incommingMessage.fromPhone,  
                    messageStatusID:"0", 
                    mediaURL:incommingMessage.mediaUrl
                };  
                this.chatEntries.push(messageEntry);
            }
        },

        async getHubspotURL(){
             let url = await this.$store.dispatch('contact/getCurrentHubspotURL',this.currentSelectedContact);
             if (url != undefined){
                 window.open(url, '_blank');
             }
        },
        async updateCurrentChatInfo(){
            await this.$store.dispatch('contact/updateChatInfo',{id:this.currentSelectedContact,firstname: this.currentChatInfo.firstName,
            lastname:this.currentChatInfo.lastName,phonenumber: this.currentChatInfo.phone, email:this.currentChatInfo.email});

            let conversation = null;
             var contactIndex = this.entries.findIndex(x => x.id == this.currentSelectedContact);
             if (contactIndex >= 0){
                conversation = this.entries[contactIndex];
                if(conversation != undefined){
                    conversation.fullName = this.currentChatInfo.firstName + ' ' + this.currentChatInfo.lastName;
                    this.entries[contactIndex] = conversation;
                }
            }
            let initials = this.getConversationInitials(this.currentChatInfo.firstName + " " + this.currentChatInfo.lastName);
            this.currentChatInfoInitialData = {firstName: this.currentChatInfo.firstName,lastName: this.currentChatInfo.lastName !== null ? this.currentChatInfo.lastName : " " ,phone: this.currentChatInfo.phone,email: this.currentChatInfo.email,initials:initials};
        },
        setFavorite(isFavorite) {
            this.isFavorite = isFavorite;
        },
        closeUserInformation(){
           let initials = this.getConversationInitials(this.currentChatInfoInitialData.firstName + " " + this.currentChatInfoInitialData.lastName);
           this.currentChatInfo = {firstName: this.currentChatInfoInitialData.firstName,lastName: this.currentChatInfoInitialData.lastName !== null ? this.currentChatInfoInitialData.lastName : " " ,phone: this.currentChatInfoInitialData.phone,email: this.currentChatInfoInitialData.email,initials:initials};
           this.conversationDetail = !this.conversationDetail;
        },
        clearContactToSendMesssage() {
            if (this.contactToSendMessage) this.$store.dispatch('contact/setContactToSendMessage', null);
        },

        scrollToEnd() {   
            let container = this.$el.querySelector("#conversation-pane");
            container.scrollTop = container.scrollHeight;
        },
    }
  }
</script>
<style lang="scss" scoped>
    .v-list-item--active{
        color: rgba(0, 0, 0, 0.87);
        &::before{
            background-color: transparent;
        }
    }
    .v-list-item{
        &.active-chat{
            &::before{
                background-color: #f50886;
                opacity: 0.12;
            }
        }
    }
</style>
<template>
    <div>
        <v-overlay color="iq_dark" :value="loading">
            <v-icon color="iq_light" x-large>mdi-loading mdi-spin</v-icon>
            <span class="iq_light--text font-weight-bold "> Pulling Inventory Data... </span>
        </v-overlay>
        <v-card elevation="2" v-if="!loading">
            <v-simple-table>
                <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left">
                            Product Name
                        </th>
                        <th class="text-left">
                            SKU Number
                        </th>
                        <th class="text-left">
                            Requested Amount
                        </th>
                        <th class="text-left">
                            Available Inventory
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                    v-for="item in inventoryDetails.items"
                    :key="item.id"
                    >
                        <td>{{ item.name }}</td>
                        <td>{{ item.sku }}</td>
                        <td>{{ item.requestedAmount }}</td>
                        <td>{{ item.availableInventory }}</td>
                    </tr>
                </tbody>
                </template>
            </v-simple-table>
        </v-card>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default{
    data(){
        return{
            loading: true,
            hubSpotPortalId: null,
            hubSpotDealId: null,
            hubSpotUserId: null
        }
    },
    computed: {
        ...mapGetters({
            inventoryDetails: 'stockIQ/getInventoryDetails'
        })
    },
    async mounted(){
        this.hubSpotPortalId = this.$route.query.c;
        this.hubSpotDealId = this.$route.query.co;
        this.hubSpotUserId = this.$route.query.u;

        let response = await this.$store.dispatch('stockIQ/getInventoryDetails',{
            portalId: this.hubSpotPortalId,
            dealId: this.hubSpotDealId,
            userId: this.hubSpotUserId
        });

        if(response !== null){
            this.loading = false;
        }else{
            this.$swal({
                icon: 'error',
                title: 'Error Pulling Inventory Data',
                text: 'There was an error pulling the inventory data. Please try again later.',
            });
        }
    }
}
</script>

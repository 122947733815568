<template>
    <v-card>
        <v-card-title>
            Agency Clients
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="tableData"
            dense
        >
            <template v-slot:item="row">
                <tr>
                    <td>{{row.item.clientName}}</td>
                    <td>{{row.item.usersNumber}}</td>
                    <td>{{row.item.hubSpotConnection}}</td>
                    <td>{{row.item.accountType}}</td>
                    <td>
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                outlined
                                x-small
                                color="primary"
                                v-bind="attrs"
                                v-on="on"
                                >
                                    Actions <v-icon small>mdi-chevron-down</v-icon>
                                
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item
                                v-for="(action, index) in actions"
                                :key="index" 
                                @click="callAction(row.item,action.action)"
                                >
                                    <v-list-item-title v-if="action.action == 'login' && currentCompany.id === row.item.companyId" disabled>Logged In</v-list-item-title>
                                    <v-list-item-title v-else>{{ action.title }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </v-card>
</template>
<script>
    import { mapGetters } from 'vuex';
    export default{
        computed: {
            ...mapGetters({
                currentCompany: 'company/getCurrentCompany',
                user: 'getUser'
            }),
            headers() {
                return [
                {
                            text: "Client Name",
                            align: "start",
                            value: "clientName",
                            filter: this.nameFilter,
                        },
                        {
                            text: "Total Users",
                            value: "usersNumber"
                        },
                        {
                            text: "HubSpot Connection",
                            value: "hubSpotConnection"
                        },
                        {
                            text: "Account Type",
                            value: "accountType"
                        },
                        {
                            text: "Actions",
                            value: "companyId"
                        }
                
                ]
            },
        },
        data(){
            return{
                search: '',
                actions: [
                    { title: 'Login', action: 'login' }
                ],
                tableData: []
            }
        },
        async mounted(){
            this.$auth.auth0ClientPromise.then(async () => {
                if(Object.keys(this.currentCompany).length === 0){
                    await this.$store.dispatch('getUserCompany');
                    if(this.currentCompany.isAgency || this.user.agencyId !== null){
                        let companyId = this.currentCompany.id;
                        // Check is Agency User is logged into another
                        // customer already
                        if(this.user.agencyId !== null){
                            companyId = this.user.agencyId;
                        }
                        let data = await this.$store.dispatch('company/getAgencyCompanies',companyId);
                        this.tableData = data;
                    }else{
                        this.$router.push('/');
                    }                  
                }else{
                    if(this.currentCompany.isAgency || this.user.agencyId !== null){
                        let companyId = this.currentCompany.id;
                        // Check is Agency User is logged into another
                        // customer already
                        if(this.user.agencyId !== null){
                            companyId = this.user.agencyId;
                        }
                        let data = await this.$store.dispatch('company/getAgencyCompanies',companyId);
                        this.tableData = data;
                    }else{
                        this.$router.push('/');
                    }
                }
            });
        },
        methods: {
            nameFilter(value) {
                // If this filter has no value we just skip the entire filter.
                if (!this.search) {
                    return true;
                }

                // Check if the current loop value (The dessert name)
                // partially contains the searched word.
                return value.toLowerCase().includes(this.search.toLowerCase());
            },
            async callAction(company,action){
                if(action == 'login'){
                    await this.$store.dispatch('loginAsAgencyCompany',company.companyId);
                    this.$router.push('/');
                }
            }
        }
    }
</script>
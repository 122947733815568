import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',[_vm._v(" Step 5 - plan selection (skipped on trial registration) ")]),_c(VFooter,{staticClass:"px-0 bg-transparent",class:this.$vuetify.breakpoint.smAndDown ? 'bg-mobile-overlay border-top':'bg-transparent',attrs:{"app":this.$vuetify.breakpoint.smAndDown,"padless":""}},[_c(VContainer,{staticClass:"pa-0",attrs:{"fluid":""}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VCardActions,{staticClass:"d-flex justify-space-between",class:this.$vuetify.breakpoint.smAndDown ? '':'px-0'},[_c(VBtn,{attrs:{"x-large":"","outlined":"","color":"primary","to":"step4"}},[_vm._v(" back ")]),_c(VBtn,{attrs:{"x-large":"","color":"primary","to":"step6"}},[_vm._v(" Next ")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
<v-container fluid class="pa-0">
    <v-card flat>
        <v-overlay color="iq_dark" :value="globalLoader">
            <v-progress-circular
                indeterminate
                size="70"
                width="7"
                color="primary"
            ></v-progress-circular>
        </v-overlay>
        <v-toolbar
        dark
        color="iq_darkerblue"
        app>
          <v-toolbar-title class="display-3">
            Stats
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items >
            <div class="d-flex flex-column align-self-center">
                <span class="caption py-2"> Last Update:  {{ new Date() | moment( this.dateFormat) }}</span>
            </div>
          </v-toolbar-items>
        </v-toolbar>
        <v-card flat>   
            <v-container fluid>
                <v-row class="justify-center">
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12" md="6">
                                <h1>General Overview</h1>
                            </v-col>
                            <v-col cols="12" md="6" class="d-flex align-end">
                                <v-select
                                    :items="getPredefinedRanges"
                                    v-model="selectedRange"
                                    item-text="text"
                                    item-value="value"
                                    return-object
                                    dense
                                    hide-details="true"
                                    class="mb-1 pa-0"
                                    outlined
                                    @change="getData(false)"
                                >
                                    <template v-slot:selection="{item}">
                                        {{item.name}}
                                    </template>
                                    <template v-slot:item="{active, item, attrs, on}">
                                        <v-list-item :input-value="active" dense v-on="on" v-bind="attrs" :default="{active}">
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{item.name}}
                                                </v-list-item-title>
                                                <v-list-item-subtitle>
                                                    {{ item.from }} - {{ item.to }} 
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-select>
                                <div class="d-flex flex-row" v-if="this.selectedRange">
                                    <v-menu
                                        v-if="this.selectedRange.name === 'Custom Range'"
                                        v-model="fromDateMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y 
                                        max-width="290px"
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                label="From"
                                                readonly
                                                :value="fromDateDisp"
                                                v-on="on"
                                                hide-details="true"
                                                outlined
                                                class="mb-1"
                                                dense
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            locale="en-in"
                                            v-model="fromDateVal"
                                            no-title
                                            @input="fromDateMenu = false"
                                            :min="minDate"
                                        ></v-date-picker>
                                    </v-menu>
                                    <v-menu
                                        v-if="this.selectedRange.name === 'Custom Range'"
                                        v-model="toDateMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y 
                                        max-width="290px"
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                label="To"
                                                readonly
                                                :value="toDateDisp"
                                                v-on="on"
                                                hide-details="true"
                                                outlined
                                                class="mb-1"
                                                dense

                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            locale="en-in"
                                            v-model="toDateVal"
                                            no-title
                                            @input="toDateMenu = false"
                                            :min="minDate"
                                        ></v-date-picker>
                                    </v-menu>
                                    <v-btn 
                                    v-if="this.selectedRange.name === 'Custom Range'"
                                    :disabled="!this.fromDateVal || !this.toDateVal"
                                    color="primary"
                                    @click="getDataByCustomRange()">
                                        Ok
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
            
                    </v-col>
                    <v-col cols="12" md="4" lg="2" sm="6" v-for="globalStat in globalStats" :key="globalStat.name">
                        <v-sheet  class="fill-height d-flex flex-column" style="padding: 1px;" elevation="2"  :color="globalStat.color"  outlined rounded> 
                        <!--   color="secondary"-->
                            <v-card elevation="0" class="fill-height lighten-1" style="overflow: hidden" rounded>
                                <v-card-title style="position:relative; z-index:2;" class="display-4 font-weight-medium d-flex align-start iq_dark--text text--lighten-3">
                                    <span>{{splitDecimals(globalStat.value)[0]}} </span>
                                    <span v-if="globalStat.isPercentile" class="text-h4 pt-1">{{splitDecimals(globalStat.value)[1]}} </span>
                                    
                                    <span class="text-h5 pt-1 font-weight-thin" v-if="globalStat.isPercentile">%</span> 
                                </v-card-title>
                                <v-card-subtitle style="position:relative; z-index:2;" class="font-weight-bold">{{globalStat.name}}</v-card-subtitle>
                                <v-card-title style="position:relative; z-index:2;" class="py-0" v-if="globalStat.hasEmailAvg"> 
                                    <div class="d-block w-100" v-if="!globalStat.reverseMetric">
                                        <span v-if="getMarketEmailComparison2(globalStat.name, globalStat.value)[0].value >= 0" class="d-block w-100"> 
                                            <v-chip color="success" class="chip-block" outlined>
                                                <v-icon> mdi-triangle-small-up</v-icon>
                                                <span class="body-1 font-weight-bold"> +{{ getMarketEmailComparison2(globalStat.name, globalStat.value)[0].value}}% </span>
                                                <span class="font-weight-thin caption">( {{ getMarketEmailComparison2(globalStat.name, globalStat.value)[0].reference}} %) </span>
                                                <Popover :title="globalStat.infoTitle" :content="globalStat.description" :hintColor="'secondary'" :html="globalStat.html" ></Popover>
                                            </v-chip> 
                                            <p class="caption text-center">Above Average</p> 
                                        </span>
                                        <span v-if="getMarketEmailComparison2(globalStat.name, globalStat.value)[0].value < 0 " class="d-block w-100">
                                            <v-chip color="error darken-1" class="chip-block" outlined>
                                                <v-icon> mdi-triangle-small-down</v-icon>
                                                <span class="body-1  font-weight-bold">{{ getMarketEmailComparison2(globalStat.name, globalStat.value)[0].value}}% </span>
                                                <span class="font-weight-thin caption">( {{ getMarketEmailComparison2(globalStat.name, globalStat.value)[0].reference}} %) </span>
                                                <Popover :title="globalStat.infoTitle" :content="globalStat.description" :hintColor="'secondary'" :html="globalStat.html" ></Popover>
                                            </v-chip>
                                            <p class="caption text-center">Bellow Average </p>
                                        </span>
                                        <span v-if="getMarketEmailComparison2(globalStat.name, globalStat.value)[0].value === 'infinity' " class="d-block w-100">
                                            <v-chip color="accent lighten-1" class="chip-block" outlined> 
                                                <v-icon small> mdi-chevron-double-up</v-icon>
                                                <span class="body-1  font-weight-bold">&infin;</span>
                                                <Popover :title="globalStat.infoTitle" :content="globalStat.description" :hintColor="'secondary'" :html="globalStat.html" ></Popover>
                                            </v-chip> 
                                            <p class="caption text-center"> Way Way above average! </p>
                                        </span>

                                    </div>
                                    <div class="d-block w-100" v-else> 
                                        <span v-if="getMarketEmailComparison2(globalStat.name, globalStat.value)[0].value < 0" class="d-block w-100"> 
                                            <v-chip color="success" class="chip-block" outlined>
                                                <v-icon> mdi-triangle-small-down</v-icon>
                                                <span class="body-1 font-weight-bold">{{ getMarketEmailComparison2(globalStat.name, globalStat.value)[0].value}}% </span>
                                                <span class="font-weight-thin caption ">( {{ getMarketEmailComparison2(globalStat.name, globalStat.value)[0].reference}} % ) </span>
                                                <Popover :title="globalStat.infoTitle" :content="globalStat.description" :hintColor="'secondary'" :html="globalStat.html" ></Popover>
                                            </v-chip> 
                                            <p class="caption text-center">Bellow  Average (lowest is Better) </p> 
                                        </span>
                                        <span v-if="getMarketEmailComparison2(globalStat.name, globalStat.value)[0].value >= 0 " class="d-block w-100">
                                            <v-chip color="error darken-1" class="chip-block" outlined>
                                                <v-icon> mdi-triangle-small-up</v-icon>
                                                <span class="body-1 font-weight-bold">+ {{ getMarketEmailComparison2(globalStat.name, globalStat.value)[0].value}}%  </span>
                                                <span class="font-weight-thin caption ">( {{ getMarketEmailComparison2(globalStat.name, globalStat.value)[0].reference}} % ) </span>
                                                <Popover :title="globalStat.infoTitle" :content="globalStat.description" :hintColor="'secondary'" :html="globalStat.html" ></Popover>
                                            </v-chip>
                                            <p class="caption text-center">Above Average ( lower is better)</p>
                                        </span>
                                        <span v-if="getMarketEmailComparison2(globalStat.name, globalStat.value)[0].value === 'infinity' " class="d-block w-100">
                                            <v-chip color="accent lighten-1" class="chip-block" outlined> 
                                                <v-icon small> mdi-chevron-double-down</v-icon>
                                                <span class="body-1 font-weight-bold">&infin;</span>
                                                <Popover :title="globalStat.infoTitle" :content="globalStat.description" :hintColor="'secondary'" :html="globalStat.html" ></Popover>
                                            </v-chip> 
                                            <p class="caption text-center"> Way Way above average! (lower is better) </p>
                                        </span>
                                    </div>
                                    
                                </v-card-title>
                                <span class="icon-bg">
                                    <v-icon :color="globalStat.color"> {{globalStat.icon}} </v-icon>
                                </span>
                            </v-card>
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-container>
            
        </v-card>
        <v-card-text class="pa-0">
            <v-col cols="12">
                <h1>Message Report</h1>

            </v-col>
            <v-data-table
                
                :search="search"
                :headers="statsHeaders"
                :items="statsItems"
                :single-expand="singleExpand"
                :expanded.sync="expanded"
                show-expand
                item-key="messageName"
                @item-expanded="getMessagDetailReport">   
            >
                <template v-slot:item.messageName="{item}">
                    <v-list-item two-line>
                        <v-list-item-content>
                                <v-list-item-title>
                                {{item.messageName}}
                                </v-list-item-title>
                                <v-list-item-subtitle class="overflow-hidden text-wrap">
                                {{item.messageBody}} 
                                </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </template>
                <template v-slot:item.deliveryRate="{item}">
                 <span class="d-flex justify-space-between">
                    <span class="caption text-right"> {{item.delivered}} / {{item.total}}</span>
                    <span class="body-1 font-weight-bold text-center flex-grow-1">{{item.deliveryRate}}% </span>
                    <v-chip :color="item.deliveryRate >= get_deliveryRateAvgMarketing ? 'success' : 'error' "  class="px-1" x-small>
                            <v-icon small class="pa-0" v-if="item.deliveryRate >= get_deliveryRateAvgMarketing">mdi-triangle-small-up</v-icon>
                            <v-icon small v-else>mdi-triangle-small-down</v-icon>
                            <!-- <span class="caption">{{ get_deliveryRateAvgMarketing }}% avg. </span> -->
                            <span class="caption" v-if="item.deliveryRate >= get_deliveryRateAvgMarketing">
                                <b>{{ compare(item.deliveryRate , get_deliveryRateAvgMarketing)}}% </b>
                                ({{get_deliveryRateAvgMarketing}}% avg.)
                            </span>
                            <span class="caption" v-else> 
                                <b>{{ compare(item.deliveryRate , get_deliveryRateAvgMarketing)}}% </b>
                                ({{get_deliveryRateAvgMarketing}}% avg.)
                            </span>
                    </v-chip> 
                 </span>
                    <v-progress-linear 
                        rounded
                        :value="item.deliveryRate"
                        color="success darken-2"
                        height="3"
                    >
                        
                    </v-progress-linear>
                
                </template>
                <template v-slot:item.clickRate="{item}">
                    <span class="d-flex justify-space-between" v-if="item.hasUrl === true">
                        <span class="caption text-right"> {{item.clicked}} / {{item.total}} </span>
                        <span class="body-1 font-weight-bold ml-6 text-center">{{item.clickRate}}% </span>
                        <v-chip :color="item.clickRate >= get_clickRateAvgMarketing ? 'success' : 'error' "  class="px-1" x-small>
                            <v-icon small class="pa-0" v-if="item.clickRate >= get_clickRateAvgMarketing">mdi-triangle-small-up</v-icon>
                            <v-icon small v-else>mdi-triangle-small-down</v-icon>
                            <!-- <span class="caption">{{ get_clickRateAvgMarketing }}% avg. </span> -->
                            <span class="caption" v-if="item.clickRate >= get_clickRateAvgMarketing">
                                <b>{{ compare(item.clickRate , get_clickRateAvgMarketing)}}% </b>
                                ({{get_clickRateAvgMarketing}}% avg.)
                            </span>
                            <span class="caption" v-else> 
                                <b>{{ compare(item.clickRate , get_clickRateAvgMarketing)}}% </b>
                                ({{get_clickRateAvgMarketing}}% avg.)
                            </span>

                            
                        </v-chip> 
                    </span>
                    <span class="" v-else>
                        <span class="body-1 font-weight-bold ml-6 text-center">N/A</span>
                       
                    </span>
                    <v-progress-linear 
                        v-if="item.hasUrl === true"
                        rounded
                        :value="item.clickRate"
                        :color="item.clickRate >= get_clickRateAvgMarketing  ? 'success' : 'error' "
                        height="3"
                    >
                    </v-progress-linear>
                </template>
                
                 <template v-slot:item.replyRate="{item}">
                    <span class="d-flex justify-space-between">
                        <span class="caption text-right">  {{item.replied}} / {{item.total}} </span>
                        <span class="body-1 font-weight-bold  text-center flex-grow-1">{{item.replyRate}}% </span>
                    </span>
                    <v-progress-linear 
                        rounded
                        :value="item.replyRate"
                        color="primary darken-2"
                        height="3"
                    >
                    </v-progress-linear>
                </template>
                <template v-slot:expanded-item="{ item }">
                    <td :colspan="statsHeaders.length">
                        <v-card elevation="1">
                            <v-overlay color="iq_light" absolute :value="detailLoader">
                                <v-icon color="iq_dark" x-large>mdi-loading mdi-spin</v-icon>
                                <span class="iq_dark--text font-weight-bold "> Loading </span>
                            </v-overlay>
                            <v-data-table
                                dense
                                :items="item.details" 
                                :headers="detailHeaders"
                                item-key="contactId"
                                class="elevation-1 my-3"
                                >
                                <template v-slot:item.contactName="{item}">
                                    <v-list-item class="d-flex flex-row">
                                        <v-list-item-content v-if="item.contatName !== null" class="d-flex flex-row align-center">
                                            <v-list-item-title style="width: auto; flex: 0 1 auto;" class="mr-1" >{{item.contactName}}</v-list-item-title>
                                            <v-list-item-action  v-if="item.contactHubspotID != null && item.contactHubspotID != undefined " style="width: auto; flex: 0 1 auto;"  class="mr-1"> <v-btn x-small text @click="openHSUser(item.contactHubspotID)"> Hubspot Profile </v-btn></v-list-item-action>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-data-table>
                        </v-card>
                    </td>   
                </template>
            </v-data-table>       
        </v-card-text>

        <v-card-text class="pa-0">
            <v-col cols="12">
                <h1>Broadcast Report</h1>

            </v-col>
            <v-data-table
                
                :search="searchBroadcast"
                :headers="BroadcastHeaders"
                :items="broadcastItems"
                :single-expand="singleExpand"
                :expanded.sync="expandedBroadcast"
                show-expand
                item-key="broadcastName"
                
                @item-expanded="getMessageBroadcastReport">     
                >    
            >
                <template v-slot:item.broadcastName="{item}">
                    <v-list-item two-line>
                        <v-list-item-content>
                                <v-list-item-title>
                                {{item.broadcastName}}
                                </v-list-item-title>
                                <v-list-item-subtitle class="overflow-hidden text-wrap">
                                {{item.messageBody}} 
                                </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </template>
                <template v-slot:item.deliveryRate="{item}">
                 <span class="d-flex justify-space-between">
                    <span class="caption text-right"> {{item.delivered}} / {{item.total}}</span>
                    <span class="body-1 font-weight-bold text-center flex-grow-1">{{item.deliveryRate}}% </span>
                    <v-chip :color="item.deliveryRate >= get_deliveryRateAvgMarketing ? 'success' : 'error' "  class="px-1" x-small>
                            <v-icon small class="pa-0" v-if="item.deliveryRate >= get_deliveryRateAvgMarketing">mdi-triangle-small-up</v-icon>
                            <v-icon small v-else>mdi-triangle-small-down</v-icon>
                            <span class="caption" v-if="item.deliveryRate >= get_deliveryRateAvgMarketing">
                                <b>{{ compare(item.deliveryRate , get_deliveryRateAvgMarketing)}}% </b>
                                ({{get_deliveryRateAvgMarketing}}% avg.)
                            </span>
                            <span class="caption" v-else> 
                                <b>{{ compare(item.deliveryRate , get_deliveryRateAvgMarketing)}}% </b>
                                ({{get_deliveryRateAvgMarketing}}% avg.)
                            </span>
                    </v-chip> 
                 </span>
                    <v-progress-linear 
                        rounded
                        :value="item.deliveryRate"
                        color="success darken-2"
                        height="3"
                    >
                        
                    </v-progress-linear>
                
                </template>
                <template v-slot:item.clickRate="{item}">
                    <span class="d-flex justify-space-between" v-if="item.hasUrl === true">
                        <span class="caption text-right"> {{item.clicked}} / {{item.total}} </span>
                        <span class="body-1 font-weight-bold ml-6 text-center">{{item.clickRate}}% </span>
                        <v-chip :color="item.clickRate >= get_clickRateAvgMarketing ? 'success' : 'error' "  class="px-1" x-small>
                            <v-icon small class="pa-0" v-if="item.clickRate >= get_clickRateAvgMarketing">mdi-triangle-small-up</v-icon>
                            <v-icon small v-else>mdi-triangle-small-down</v-icon>
                            <span class="caption" v-if="item.clickRate >= get_clickRateAvgMarketing">
                                <b>{{ compare(item.clickRate , get_clickRateAvgMarketing)}}% </b>
                                ({{get_clickRateAvgMarketing}}% avg.)
                            </span>
                            <span class="caption" v-else> 
                                <b>{{ compare(item.clickRate , get_clickRateAvgMarketing)}}% </b>
                                ({{get_clickRateAvgMarketing}}% avg.)
                            </span>

                            
                        </v-chip> 
                    </span>
                    <span class="" v-else>
                        <span class="body-1 font-weight-bold ml-6 text-center">N/A</span>
                       
                    </span>
                    <v-progress-linear 
                        v-if="item.hasUrl === true"
                        rounded
                        :value="item.clickRate"
                        :color="item.clickRate >= get_clickRateAvgMarketing  ? 'success' : 'error' "
                        height="3"
                    >
                    </v-progress-linear>
                </template>
                 <template v-slot:item.replyRate="{item}">
                    <span class="d-flex justify-space-between">
                        <span class="caption text-right">  {{item.replied}} / {{item.total}} </span>
                        <span class="body-1 font-weight-bold  text-center flex-grow-1">{{item.replyRate}}% </span>
                    </span>
                    <v-progress-linear 
                        rounded
                        :value="item.replyRate"
                        color="primary darken-2"
                        height="3"
                    >
                    </v-progress-linear>
                </template>
                <template v-slot:expanded-item="{ item }">
                    <td :colspan="BroadcastHeaders.length">
                        <v-card elevation="1">
                            <v-overlay color="iq_light" absolute :value="detailLoaderBroadcast">
                                <v-icon color="iq_dark" x-large>mdi-loading mdi-spin</v-icon>
                                <span class="iq_dark--text font-weight-bold "> Loading </span>
                            </v-overlay>
                            <v-data-table
                                dense
                                :items="item.detailsBroadcast" 
                                :headers="detailHeadersBroadcast"
                                item-key="contactId"
                                class="elevation-1 my-3"
                                >
                                <template v-slot:item.contactName="{item}">
                                    <v-list-item class="d-flex flex-row">
                                        <v-list-item-content v-if="item.contatName !== null" class="d-flex flex-row align-center">
                                            <v-list-item-title style="width: auto; flex: 0 1 auto;" class="mr-1" >{{item.contactName}}</v-list-item-title>
                                            <v-list-item-action  v-if="item.contactHubspotID != null && item.contactHubspotID != undefined " style="width: auto; flex: 0 1 auto;"  class="mr-1"> <v-btn x-small text @click="openHSUser(item.contactHubspotID)"> Hubspot Profile </v-btn></v-list-item-action>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-data-table>
                        </v-card>
                    </td>   
                </template>
            </v-data-table>       
        </v-card-text>
    </v-card>

</v-container>
</template>
<script>

import moment from "moment";
import Templates from '../components/AdminComponents/Templates.vue';
import { mapGetters } from 'vuex';
import Popover from '../components/shared/_popover.vue';
export default {
  components: { Templates,Popover },
    data () {
        return {
            globalLoader : true,
            detailLoader : false,
            detailLoaderBroadcast: false,
            search: "",
            searchBroadcast: "",
            singleExpand: true,
            expanded: [],
            expandedBroadcast:[],
            dateFormat: "DD-MM-YYYY h:mm:ss a",
            simpleDateFormat: "DD-MM-YYYY",
            apiDateFormat: "YYYY-MM-DD",
            apiEndDateFormat: "YYYY-MM-DD 23:59:59",
            globalStats: [],
            clickRateAvgMarketing: 2.6, 
            deliveryRateAvgMarketing: 80,
            unsubscribeRateAvgMarketing: 0.48,
            statsHeaders: [
                {text:  "Message Name", align: 'start',  value:'messageName', width: '50%'},
                {text:  "Delivered", align: 'center',  value:'deliveryRate' , width: '15%' },
                {text:  "Click Rate", align: 'center',  value:'clickRate' , width: '15%' },
                {text:  "Reply Rate", align: 'center',  value:'replyRate' , width: '15%'},
                {text:  "Details", align: 'end',  value: 'data-table-expand', groupable: false, sortable: false }
            ],
            BroadcastHeaders: [
                {text:  "Broadcast Name", align: 'start',  value:'broadcastName', width: '50%'},
                {text:  "Delivered", align: 'center',  value:'deliveryRate' , width: '15%' },
                {text:  "Click Rate", align: 'center',  value:'clickRate' , width: '15%' },
                {text:  "Reply Rate", align: 'center',  value:'replyRate' , width: '15%'},
                {text:  "Details", align: 'end',  value: 'data-table-expand', groupable: false, sortable: false } 
            ],
            statsItems: [],
            broadcastItems:[],
            detailHeaders: [
                {text:  'Contact Name',  align: 'start' , value: 'contactName'},
                {text:  'Replies',  align: 'start' , value: 'contactReplies'},
                {text:  'Clicks',  align: 'start' , value: 'contactClicks'},
                {text:  'Contact Status',  align: 'start' , value: 'contactStatus'}
            ], 
            detailHeadersBroadcast: [
                {text:  'Contact Name',  align: 'start' , value: 'contactName'},
                {text:  'Replies',  align: 'start' , value: 'contactReplies'},
                {text:  'Clicks',  align: 'start' , value: 'contactClicks'},
                {text:  'Contact Status',  align: 'start' , value: 'contactStatus'}
            ], 
            fromDateMenu: false,
            fromDateVal: null,
            toDateMenu: false,
            toDateVal: null,
            predefinedRanges: [
                {name:"Today",  value:null, from: null, to: null},
                {name:"Yesterday",  value:null, from: null, to: null},
                {name:"Last 7 Days",    value:null,from: null, to: null},
                {name:"Last 14 Days",   value:null,from: null, to: null},
                {name:"Last 30 Days",   value:null,from: null, to: null},
                {name:"This Month",  value:null,from: null, to: null},
                {name:"Last 3 Months",  value:null,from: null, to: null},
                {name:"Last 6 Months",  value:null,from: null, to: null},
                {name:"Year",   value:null,from: null, to: null},
                {name:"Custom Range",   value:'--',from: null, to: null}
            ],
            selectedRange: null,
            minDate: "2020-01-05",
            maxDate: "2019-08-30",
            window: { //LAYOUT TOOL: store location when page will need vertical space calculations ()
                width: 0,
                height: 0
            },
            selectedFromDate:null,
            selectedToDate:null
        }
    }, 
    computed: {
        ...mapGetters({
            currentCompany: 'company/getCurrentCompany'
        }),
        get_clickRateAvgMarketing(){
            return this.clickRateAvgMarketing;
        },
        get_deliveryRateAvgMarketing(){
            return this.deliveryRateAvgMarketing;
        },
        get_unsubscribeRateAvgMarketing(){
            return this.unsubscribeRateAvgMarketing;
        },
        getTotals(){
            let calculatedStatsData = this.statsItems.slice();

            calculatedStatsData.map((el) => ( el.deliveryRate =  (el.delivered * 100) / el.total) );
            calculatedStatsData.map((el) => (el.clickRate =  (el.clicked * 100 ) / el.total ) );
            calculatedStatsData.map((el) => (el.replyRate =  (el.replied * 100 ) / el.total ));

            return  calculatedStatsData;
        },
        getPredefinedRanges(){
            let todayDate = moment.utc().local().startOf('day').format(this.apiDateFormat);
            let today = moment.utc().local().format(this.apiEndDateFormat);
            let yesterday = moment.utc().local().subtract(1, 'days').startOf('day').format(this.apiDateFormat);
            let minus7Days = moment.utc().local().subtract(7, 'days').startOf('day').format(this.apiDateFormat);
            let minus14Days = moment.utc().local().subtract(14, 'days').startOf('day').format(this.apiDateFormat);
            let minus30Days = moment.utc().local().subtract(30, 'days').startOf('day').format(this.apiDateFormat);
            let monthDay1 = moment.utc().local().startOf('month').format(this.apiDateFormat);
            let months3AgoDay1 = moment.utc().local().subtract(3, 'month').startOf('month').format(this.apiDateFormat);
            let months6AgoDay1 = moment.utc().local().subtract(6, 'month').startOf('month').format(this.apiDateFormat);
            let currentYear = moment.utc().local().startOf('year').format(this.apiDateFormat);

            let calculatedRanges = this.predefinedRanges.slice();

            calculatedRanges.forEach((element) => {
                switch (element.name) {
                case 'Today':
                    element.from = todayDate;
                    element.to =  today;
                    element.value = today + " > " + today;
                    break;
                case 'Yesterday':
                    element.from = yesterday;
                    element.to = yesterday + ' 23:59:59';
                    element.value = yesterday + " > " + yesterday;

                    break;
                case 'Last 7 Days':
                    element.from = minus7Days;
                    element.to = today;
                    element.value = minus7Days + " > " + today;
                    break;
                case 'Last 14 Days':
                    element.from = minus14Days;
                    element.to = today;
                    element.value = minus14Days + " > " + today;

                    break;
                case 'Last 30 Days':
                    element.from = minus30Days;
                    element.to = today;
                    element.value = minus30Days + " > " + today;

                    break;   
                case 'This Month':
                    element.from = monthDay1;
                    element.to = today;
                    element.value = monthDay1 + " > " + today;

                    break;
                case 'Last 3 Months':
                    element.from = months3AgoDay1;
                    element.to = today;
                    element.value = months3AgoDay1 + " > " + today;

                    break;
                case 'Last 6 Months':
                    element.from = months6AgoDay1;
                    element.to = today;
                    element.value = months6AgoDay1 + " > " + today;

                    break;
                case 'Year':
                    element.from = currentYear;
                    element.to = today;
                    element.value = currentYear + " > " + today;

                    break;
                default:
                    break;
                }

            });
            this.selectedRange = calculatedRanges[5];
            return calculatedRanges;
        },
        fromDateDisp() {
            return this.fromDateVal;
            //   /do something with date
        },
        toDateDisp() {
            return this.toDateVal;
            // format/do something with date
        }
    }, 
    created(){
        this.$auth.auth0ClientPromise.then(async () => {
            if(Object.keys(this.currentCompany).length === 0){
                await this.$store.dispatch('getUserCompany');
                if(this.currentCompany.isHubSpotIntegrated){
                   window.addEventListener('resize', this.handleResize);
                   this.getData(true);
                   this.handleResize();
                }else{
                    this.$swal({
                        title: `Please connect to HubSpot to Send Message.`,
                        icon: 'error',
                        confirmButtonText: "Connect to HubSpot"
                    }).then(async result => {
                        if(result.isConfirmed){
                            this.$router.push("/settings/integrations");
                        }
                    });
                }
            }else{
                if(this.currentCompany.isHubSpotIntegrated){
                    window.addEventListener('resize', this.handleResize);
                    this.getData(true);
                    this.handleResize();
                }else{
                    this.$swal({
                        title: `Please connect to HubSpot to Send Message.`,
                        icon: 'error',
                        confirmButtonText: "Connect to HubSpot"
                    }).then(async result => {
                        if(result.isConfirmed){
                            this.$router.push("/settings/integrations");
                        }
                    });
                }
            }
        });        
    },
    destroyed() { //LAYOUT TOOL: on leave dispose
        window.removeEventListener('resize', this.handleResize);
    },
     methods: {
        getData(firstLoad){
            if(this.selectedRange != undefined){
                if(this.selectedRange.name != 'Custom Range'){
                    this.selectedFromDate = this.selectedRange.from;
                    this.selectedToDate = this.selectedRange.to;

                    this.getMessageReport();
                    this.getMessagePerformanceData();
                    this.getBroadcastReport();
                }
            }
        },

        getDataByCustomRange(){
                this.selectedFromDate = this.fromDateVal;
                this.selectedToDate = this.toDateVal;
                this.getMessageReport();
                this.getMessagePerformanceData();
                this.getBroadcastReport();

        },
        async getMessageReport(){
            this.globalLoader = true;

            let data = await this.$store.dispatch('chart/getMessageReport',{id: this.currentCompany.id,startFilter: this.selectedFromDate,endFilter: this.selectedToDate}); // TODO: + ' 23:59:59'
            this.statsItems =[];
            if(data != undefined){
                this.globalLoader = false;
                data.map((datainfo,index)  => 
                this.statsItems.push(
                    {messageName: datainfo[1],
                    messageBody: datainfo[0],                    
                    total: datainfo[8],
                    delivered: datainfo[5], 
                    clicked: datainfo[6], 
                    replied:  datainfo[7], 
                    deliveryRate: parseInt(datainfo[2].substr(0,  datainfo[2].indexOf('%'))),
                    clickRate: datainfo[3] !== 'N/A' ?parseInt(datainfo[3].substr(0,  datainfo[3].indexOf('%'))): datainfo[3],
                    replyRate: parseInt(datainfo[4].substr(0,  datainfo[4].indexOf('%'))),
                    hasUrl:datainfo[9],
                    details:[]
                    })     
                );
            }
        },

        async getBroadcastReport(){
            let data = await this.$store.dispatch('chart/getBroadcastDataReport',{id: this.currentCompany.id,startFilter: this.selectedFromDate,endFilter: this.selectedToDate}); // TODO: + ' 23:59:59'
            this.broadcastItems =[];
            if(data != undefined){
                data.map((datainfo,index)  => 
                this.broadcastItems.push(
                    {broadcastName: datainfo[1],
                    messageBody: datainfo[0],                    
                    total: datainfo[8],
                    delivered: datainfo[5], 
                    clicked: datainfo[6], 
                    replied:  datainfo[7], 
                    deliveryRate: parseInt(datainfo[2].substr(0,  datainfo[2].indexOf('%'))),
                    clickRate: datainfo[3] !== 'N/A' ?parseInt(datainfo[3].substr(0,  datainfo[3].indexOf('%'))): datainfo[3],
                    replyRate: parseInt(datainfo[4].substr(0,  datainfo[4].indexOf('%'))),
                    hasUrl:datainfo[9],
                    detailsBroadcast:[]
                    })     
                );

            }
        },

         async getMessagDetailReport({item}){
            this.detailLoader = true;
            let data = await this.$store.dispatch('chart/getMessagDetailReport',{id: this.currentCompany.id,startFilter: this.selectedFromDate,endFilter: this.selectedToDate,messageName: item.messageName}); // TODO: + ' 23:59:59'
             let details = [];
              if(data != undefined){
                this.detailLoader = false;
                 data.map((datainfo)  => 
                 details.push(
                     {timeStamp: "05 Jan 2022 04:37:48",    
                      contactName: datainfo[1],
                         contactNumber: "305-3312-4400",
                         contactReplies: datainfo[2], 
                         contactClicks: datainfo[3],
                         contactStatus: datainfo[4],
                         contactHubspotID: datainfo[5],
                         contactId: datainfo[0]                
                     })
                 );
             }
             item.details = details;
        },
        
        async getMessageBroadcastReport({item}){
            this.detailLoaderBroadcast = true;
            let data = await this.$store.dispatch('chart/getMessageBroadcastReport',{id: this.currentCompany.id,startFilter: this.selectedFromDate,endFilter: this.selectedToDate,messageName: item.broadcastName}); // TODO: + ' 23:59:59'
             let details = [];
              if(data != undefined){
                this.detailLoaderBroadcast = false;
                 data.map((datainfo)  => 
                 details.push(
                     {timeStamp: "05 Jan 2022 04:37:48",    
                      contactName: datainfo[1],
                         contactNumber: "305-3312-4400",
                         contactReplies: datainfo[2], 
                         contactClicks: datainfo[3],
                         contactStatus: datainfo[4],
                         contactHubspotID: datainfo[5],
                         contactId: datainfo[0]            
                     })
                 );
             }
             item.detailsBroadcast = details;
        },
        
        async getMessagePerformanceData(){
            this.globalLoader = true;
            let data = await this.$store.dispatch('chart/getMessagePerformanceData',{id: this.currentCompany.id,startFilter: this.selectedFromDate,endFilter: this.selectedToDate}); // TODO: + ' 23:59:59'
            this.globalStats = [];
            
            if (data != undefined){
                this.globalLoader = false;
                this.globalStats.push(
                    {name:"Message Sent",       color: 'primary',   icon:'mdi-message-fast',                    isPercentile: false,  hasEmailAvg: false, reverseMetric: false, infoTitle:'',  value: (data.totalMessagesSent != undefined)?data.totalMessagesSent:"0", description: null, html:'' },
                    {name:"Delivery Rate",      color: 'secondary', icon:'mdi-cellphone-message',               isPercentile: true,   hasEmailAvg: true,  reverseMetric: false, infoTitle:'Compared to Email Delivery Rate', value: (data.totalDeliveryRate != undefined)?data.totalDeliveryRate.substr(0,  data.totalDeliveryRate.indexOf('%')):"0",  description: 'The average Email delivery rate is '+ this.get_deliveryRateAvgMarketing+'%, MessageIQ has a delivery rate close to 99.9% making our tool a great options to ensure you can reach your customers.' , html:"<p>Metric Source: <a  target='_blank' href='https://neverbounce.com/blog/setting-and-reaching-your-email-deliverability-benchmark#:~:text=What%20is%20a%20Good%20Email,what%20you%20should%20aim%20for.'>Neverbounce</a></p>"},
                    {name:"Credits Used",       color: 'warning',   icon:'mdi-piggy-bank-outline',              isPercentile: false,  hasEmailAvg: false, reverseMetric: false, infoTitle:'',value: (data.totalCreditsUsed != undefined)?data.totalCreditsUsed:0, description: null , html: ''},
                    {name:"Average Reply Rate", color: 'success',   icon:'mdi-forum',                           isPercentile: true,   hasEmailAvg: true,  reverseMetric: false, infoTitle:'Compared to Email Reply Rate',  value: (data.totalAvgReplyRate != undefined)?data.totalAvgReplyRate.substr(0,  data.totalAvgReplyRate.indexOf('%')):"0", description: 'There is really no room for comparisson,  your customer email is not checked often enough where SMS is part of our daily routine.' , html:"<p><b>LOL!</b>, do people reply to your automated emails?</p>"},
                    {name:"Unsubscribed",      color: 'error',     icon:'mdi-message-off-outline',             isPercentile: true,   hasEmailAvg: true,  reverseMetric: true, infoTitle:'Compared to Email Unsubscribed Rate',  value: (data.totalAvgUnsubscribedRate != undefined)?data.totalAvgUnsubscribedRate.substr(0,  data.totalAvgUnsubscribedRate.indexOf('%')):"0", description: 'The Average Unbsubscribed Rate for Emails is'+ this.get_unsubscribeRateAvgMarketing +'%, Values bellow that are desirable. ' ,html:"<p>Metric Source: <a  target='_blank' href='https://blog.hubspot.com/sales/average-email-open-rate-benchmark'>Hubspot</a></p>"},
                )
            }
        },

        getMarketEmailComparison (name, value){
            let comparisson = null;
            if(value != undefined){
                var removepercent = value.replace("%", "");
                var valueInt = parseFloat(removepercent);
                switch (name) {
                    case 'Delivery Rate':
                        comparisson = valueInt * 100 / this.deliveryRateAvgMarketing  - 100;
                    break;
                    case 'Average Reply Rate': 
                        comparisson = 'infinity';
                    break;
                    case 'Unsubscribed':
                        comparisson = valueInt * 100 / this.unsubscribeRateAvgMarketing  - 100;
                    break;
                }
                if( comparisson !== null && typeof comparisson !== "string" ){
                    comparisson = comparisson.toFixed(2);
                }
            }
            
            return comparisson;
        },
        getMarketEmailComparison2 (name, value){
            let comparisson = [];
            if(value != undefined){
                var removepercent = value.replace("%", "");
                var valueInt = parseFloat(removepercent);
                switch (name) {
                    case 'Delivery Rate':
                        let DeliveryRate = valueInt - this.deliveryRateAvgMarketing;
                        DeliveryRate = DeliveryRate.toFixed(2);
                        comparisson.push ({ value: DeliveryRate , reference: this.deliveryRateAvgMarketing });
                    break;
                    case 'Average Reply Rate': 
                        comparisson.push( { value: 'infinity', reference: 'infinity' });
                    break;
                    case 'Unsubscribed':
                        let UnsubscribedRate = valueInt - this.unsubscribeRateAvgMarketing;
                        UnsubscribedRate =  UnsubscribedRate.toFixed(2);
                        comparisson.push({ value: UnsubscribedRate , reference: this.unsubscribeRateAvgMarketing });
                    break;
                }
    
            }
            
            return comparisson;
        },
        compare(a,b){
            return a - b;
        },

         openHSUser(url){
                window.open(url, '_blank');
        },

     }
}
</script>

<style lang="scss">

    .icon-bg{
        position: absolute;
        top: 0;
        right: 0;
        z-index: 0;
        i.v-icon{
            opacity: 0.15;
            font-size: 90px;
            filter: brightness(1.0);
            &::before{
                transform: translate(-20%, 10%);
            }
        }

    }
    .w-100{
        width: 100%;
    }
    .v-chip.chip-block{
        display: block;
        width: 100%;
        .v-chip__content{
            display: flex;
            justify-content: space-between;
        }
    }


</style>
import Api from "@/services/api";
import Vue from "vue";
export default {
    namespaced: true,
    state: {
        companyPriceLists: [],
        hubSpotProducts: []
    },
    mutations: {
        SET_COMPANY_PRICE_LISTS(state, companyPriceLists){
            state.companyPriceLists = companyPriceLists;
        },
        SET_HUBSPOT_PRODUCTS(state, hubSpotProducts){
            state.hubSpotProducts = hubSpotProducts;
        }
    },
    actions: {
        async getCompanyPriceLists({commit}){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let response = await Api().get(`/Web/SmartRamp/PriceIQ/GetCompanyPriceLists`,config)
                .catch(err => {
                    console.log(err);
                    return false;
                });
            commit('SET_COMPANY_PRICE_LISTS', response.data);
            return response.data;
        },
        async getHubSpotProducts({commit}) {
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let response = await Api().get(`/Web/SmartRamp/PriceIQ/GetCompanyProducts`,config)
                .catch(err => {
                    console.log(err);
                    return false;
                });
            commit('SET_HUBSPOT_PRODUCTS', response.data);
            return response.data;
        },
        async createPriceList({}, {name: name, discount: discount, allProducts: allProducts, products: products}) {
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { 
                    "Authorization": `Bearer ${token}`, 
                    "Content-Type": "application/json"
                }
            };
            let response = await Api().post(`/Web/SmartRamp/PriceIQ/PriceList/Create`, {
                Name: name,
                Discount: discount,
                AllProducts: allProducts,
                Products: products
            },config)
                .catch(err => {
                    console.log(err);
                    return false;
                });
            return true;
        }
    },
    getters: {
        getCompanyPriceLists: state => { return state.companyPriceLists },
        getHubSpotProducts: state => { return state.hubSpotProducts }
    }
};
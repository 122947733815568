<template>
    <v-card>
        <v-card-title>
            Admin Report
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="searchPerson"
                append-icon="mdi-magnify"
                label="Search by user"
                @click:clear="clearSearch"
                @change="searchByPerson"
                single-line
                hide-details
            ></v-text-field>
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="tableData"
            dense
        >
            <template v-slot:item="row">
                <tr>
                    <td>{{row.item.clientName}}</td>
                    <td>{{row.item.usersNumber}}</td>
                    <td>{{formatDateTime(row.item.lastLoginDate)}}</td>
                    <td>{{row.item.hubSpotConnection}}</td>
                    <td>{{row.item.accountType}}</td>
                    <td>
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                outlined
                                x-small
                                color="primary"
                                v-bind="attrs"
                                v-on="on"
                                >
                                    Actions <v-icon small>mdi-chevron-down</v-icon>
                                
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item
                                v-for="(action, index) in actions"
                                :key="index" 
                                @click="callAction(row.item,action.action)"
                                >
                                    <v-list-item-title v-if="action.action == 'login' && currentCompany.id === row.item.companyId" disabled>Logged In</v-list-item-title>
                                    <v-list-item-title v-else>{{ action.title }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </v-card>
</template>
<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
export default {
    name: "AdminReport",
    computed: {
        ...mapGetters({
            currentCompany: 'company/getCurrentCompany',
            userRole: 'getUserRole'
        }),
        headers() {
        return [
          {
                    text: "Client Name",
                    align: "start",
                    value: "clientName",
                    filter: this.nameFilter,
                },
                {
                    text: "Total Users",
                    value: "usersNumber"
                },
                {
                    text: "Last Login Date",
                    value: "lastLoginDate"
                },
                {
                    text: "HubSpot Connection",
                    value: "hubSpotConnection"
                },
                {
                    text: "Account Type",
                    value: "accountType"
                },
                {
                    text: "Actions",
                    value: "companyId"
                }
          
        ]
      },
    },
    data(){
        return{
            search: '',
            searchPerson: '',

            allData: [],
            tableData: [],
            actions: [
                { title: 'Login', action: 'login' },
                { title: 'Mark As Spam', action: 'markAsSpam' },
                { title: 'Delete', action: 'delete' },
            ]
        }
    },
    created(){
        this.$auth.auth0ClientPromise.then(async () => {
            if(Object.keys(this.currentCompany).length === 0){
                await this.$store.dispatch('getUserCompany');
                
                if(this.userRole === "SuperAdmin"){
                    this.getAdminReportData();
                }else{
                    this.$router.push("/");
                }
            }else{
                if(this.userRole === "SuperAdmin"){
                    this.getAdminReportData();
                }else{
                    this.$router.push("/");
                }
            }
        });
    },
    methods: {
        formatDateTime(dt){
            if(dt !== null){
                return moment(dt).format("MM/DD/YYYY hh:mm:ss")
            }
            return "";
        },
        async callAction(company,action){
            if(action == 'login'){
                await this.$store.dispatch('impersonateUser',company.companyId);
                this.$router.push('/');
            }else if(action == 'markAsSpam'){
                this.$swal({
                    title: `Are you sure you want to mark ${company.clientName} as spam? This will blacklist their HubSpot Portal ID and all email domains associated with this account.`,
                    icon: 'waring',
                    showDenyButton: true,
                    confirmButtonText: "Yes",
                    denyButtonText: "No"
                }).then(async result => {
                    if(result.isConfirmed){
                        await this.$store.dispatch('company/markCompanyAsSpam',company.companyId);
                        this.$swal({
                            icon: 'success',
                            title: 'Company Marked As Spam',
                            text: `${company.clientName} has been marked as a spammer. It is now safe to delete the account`,
                            toast: true,
                            timer: 3000,
                            position: 'top-end'
                        });
                    }
                });
            }else if(action == 'delete'){
                this.$swal({
                    title: `Are you sure you want to delete this company?`,
                    icon: 'waring',
                    showDenyButton: true,
                    confirmButtonText: "Yes",
                    denyButtonText: "No"
                }).then(async result => {
                    if(result.isConfirmed){
                        await this.$store.dispatch('company/deleteCompanyById',company.companyId);
                        this.getAdminReportData();
                        this.$swal({
                            icon: 'success',
                            title: 'Successfully Delete Company',
                            text: 'Company has been successfully deleted',
                            toast: true,
                            timer: 3000,
                            position: 'top-end'
                        });
                    }
                });
            }
        },

        nameFilter(value) {
            // If this filter has no value we just skip the entire filter.
            if (!this.search) {
            return true;
            }

            // Check if the current loop value (The dessert name)
            // partially contains the searched word.
            return value.toLowerCase().includes(this.search.toLowerCase());
        },

        async getAdminReportData() {
            let adminReportData = await this.$store.dispatch("admin/getAdminReport");
            this.tableData = adminReportData;
            this.allData = adminReportData;
        },

        async searchByPerson(){
            let newData = [];

            if (this.searchPerson !== '') {
                let users = await this.$store.dispatch('admin/getCompaniesByUser',{search: this.searchPerson.toLowerCase()})

                this.allData.map((row => {
                    if(users.find((user) => user.companyId == row.companyId))newData.push(row);
                }))

                this.tableData = newData;

            } else {
                this.getAdminReportData();
            }

        },
        clearSearch() { 
            this.searchPerson = '';
            this.getAdminReportData();
        },

    }
}
</script>
<template>
    <div>
        <v-form v-model="valid" id="verifyPhoneForm" ref="verifyPhoneForm"> 
            <v-row>
                <v-col>
                    <h1 :class="this.$vuetify.breakpoint.smAndDown ? 'white--text text-h5 text-center' : 'secondary--text text-h2'">Verify Phone Number </h1>
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        ref="formEntryInput"
                        v-model="verifyPhoneForm.phoneNumber"
                        v-mask="phoneMask"
                        :color="!this.$vuetify.breakpoint.smAndDown ? 'iq_dark' : 'iq_light'"
                        :background-color="!this.$vuetify.breakpoint.smAndDown ? 'iq_light' : ''"
                        :dense="this.$vuetify.breakpoint.smAndDown"
                        label="Phone Number (Must be US or Canada Mobile Phone)"
                        outlined
                        required
                        :rules="requiredRule"
                    ></v-text-field> 
                </v-col>
            </v-row>
        </v-form>
        <v-dialog
            v-model="dialog"
            persistent
            width="500"
        >
            <v-card>
                <v-card-title class="text-h4 justify-center pt-8">
                    Phone Verification
                </v-card-title>
                <v-card-text class="justify-center text-center py-6">
                        Pleace enter the verification code that was sent to you<br>
                        via SMS. If you did not receive a code <a href="javascript:" @click.prevent="sendAnotherCode">click here</a> to<br>
                        send another code.
                </v-card-text>
                <div class="ma-auto position-relative pt-6 pb-16" style="max-width: 300px">
                    <v-otp-input
                        v-model="phoneCode"
                        :disabled="loading"
                        @finish="onFinish"
                        length="6"
                        type="number"
                    ></v-otp-input>
                    <v-overlay absolute :value="loading">
                        <v-progress-circular
                        indeterminate
                        color="primary"
                        ></v-progress-circular>
                    </v-overlay>
                </div>
                <v-snackbar
                    v-model="snackbar"
                    :color="snackbarColor"
                    :timeout="5000"
                >
                    <div class="text-center">{{ text }}</div>
                </v-snackbar>
            </v-card>
        </v-dialog>
        <v-footer 
        :app ="this.$vuetify.breakpoint.smAndDown"
        :class="this.$vuetify.breakpoint.smAndDown ? 'bg-mobile-overlay border-top':'bg-transparent'"
        padless 
        class="px-0 bg-transparent" 
        >
            <v-container fluid class="pa-0">
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-card-actions class="d-flex justify-space-between" :class="this.$vuetify.breakpoint.smAndDown ? '':'px-0'">
                            <v-btn 
                                x-large 
                                outlined 
                                :loading="loadingVerify"
                                :disabled="loadingVerify"
                                color="primary" 
                                @click="verifyPhoneNumber()"
                            > 
                                Verify 
                            </v-btn>
                        </v-card-actions>
                    </v-col>
                </v-row>
            </v-container>
        </v-footer>
    </div>           
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    name: "AccountStep1",
    props: {
    },
    computed: {
        ...mapGetters({
            phoneNumber: 'registration/getCurrentPhoneNumber'
        })
    },
    data () {
        return {
            valid: false,
            subscriptionId: null,
            phoneMask: '(###) ###-####',
            requiredRule: [
                v => !!v || 'Field is required'
            ],
            verifyPhoneForm:{
                phoneNumber: null,
            },
            dialog: false,
            loading: false,
            phoneCode: null,
            snackbar: false,
            loader: null,
            loadingVerify: false,
            sendAnotherOne: true,
            snackbarColor: 'default',
            text: '',
            campaign: null
        }
    },
    created() {
        this.getSubscriptionId();
        this.$route.query.campaign ? this.campaign = this.$route.query.campaign : this.campaign = null;
        this.$store.dispatch('registration/saveCampaign',this.campaign); 
    },
    watch: {
        verifyPhoneForm: {
            handler: function (v){
                return this.setupFormObjHasChanged();
            }
        },
    },
    methods: {
        async getSubscriptionId(){
            this.$route.query.id ? this.subscriptionId = this.$route.query.id : this.subscriptionId = "00000000-0000-0000-0000-000000000000";
            await this.$store.dispatch('registration/saveSubscriptionId', this.subscriptionId);
        },

        unmask(phone){
            return phone.replace(/[^\d]/g, ''); 
        },

        setupFormObjHasChanged(){
            console.log('checking rules');
            console.log(this.verifyPhoneForm);
            this.$refs.verifyPhoneForm.validate();
        },

        async verifyPhoneNumber() {
            this.loadingVerify = true;
             if(this.validPhone(this.verifyPhoneForm.phoneNumber)){
                 let response = await this.$store.dispatch('registration/verifyPhoneNumber', this.unmask(this.verifyPhoneForm.phoneNumber));
                 if(response){
                     this.dialog = true;
                 }else{
                     this.showVerifyError();
                 }
             } else {
                 this.showVerifyError();
             }
            this.loadingVerify = false
        },

        showVerifyError(){
            this.$swal({
                icon: 'error',
                title: 'We could not verify this phone number.',
                text: 'Please enter a US or Canada Mobile phone number and try again',
            });
        },

        async onFinish (rsp) {
            this.loading = true;
            let response = await this.$store.dispatch('registration/VerifyPhoneAuthCode', {"phoneNumber": this.unmask(this.verifyPhoneForm.phoneNumber), "phoneCode": this.phoneCode});
            if(response){
                this.loading = false;
                if(this.$route.fullPath.match('registration/MessageIQ')){
                    this.$router.push('/registration/MessageIQ/step2');
                }else{
                    this.$router.push('/setup/step2');
                }
            }else{
                this.$swal({
                    icon: 'error',
                    title: '',
                    text: 'The code you have entered is not correct. Please try again.',
                });
                this.loading = false;
            }
        },

        async sendAnotherCode(event) {
            if (this.sendAnotherOne) {
                let response = await this.$store.dispatch('registration/SendAnotherCode', this.unmask(this.verifyPhoneForm.phoneNumber));
                if(response){
                    this.snackbarColor = 'success'
                    this.text = `A new code has been sent!`
                    this.snackbar = true
                }else{
                    this.snackbarColor = 'red accent-2';
                    this.text = `There was an error sending the verification code to your phone number. Please try again later.`
                    this.snackbar = true
                }
                event.target.style.pointerEvents = 'none';
                event.target.style.color = 'rgba(0,0,0,.26)';
                this.sendAnotherOne = false;
            }
            setTimeout(() => {
                this.sendAnotherOne = true;
                event.target.style.pointerEvents = '';
                event.target.style.color = '#f50886';
            }, 30000)
        }
    }
  
};
</script>
<template>
    <v-app>
    
        <!-- Main loading area for this template **************************************************************** -->
        <v-main :class="currentRouteName">
            <slot />
        </v-main>


    </v-app>
</template>
<script>
export default {
    name: "iframed",
    data() {
        return{

        }
    },
    computed: {
      currentRouteName() {
          return this.$route.name;
      }
    },
}
</script>
<template>
    <v-container fluid class="pa-0 d-flex fill-height statusIQ-gradient-bg">
        <v-row class="justify-center ma-0"> 
            <v-col cols="12" md="11" class="mt-8 mb-4" >
                <v-row justify="center">
                    <v-img
                        contain
                        class="justify-center"
                        :max-width="182.85"  
                        transition="scale-transition"
                        src="../../assets/images/siqicon.svg">
                    </v-img>
                </v-row>
            </v-col>
            <v-container>
                <v-row>
                    <v-col :cols="this.vertical ? '3' : '12'" :sm="this.vertical ? '2' : '12'"  :offset-md="this.vertical ? '0' : '1'" :md="this.vertical ? '2': '10'" :class="this.vertical ? 'stepper-container-vertical': 'stepper-container-horizontal' " > 
                        <v-stepper
                            flat
                            style="background-color: transparent"
                            non-linear
                            dark
                            v-model="currentStep"
                            :vertical="this.vertical"
                            class="status-iq-stepper"
                            :class="this.$vuetify.breakpoint.smAndDown ? ' status-iq-stepper-small' : ''"
                        >
                            <!-- **************** -->
                            <!--    Horizontal    -->
                            <!-- **************** -->
                            <v-stepper-header v-if="!vertical">
                                <template v-for="(step, index) in steps">
                                    <v-stepper-step 
                                        :step="index + 1"
                                        :key="index"
                                        class="d-block text-center"
                                    >   
                                        {{step.title.length > charLimit ? step.title.substring(0, charLimit) + '...': step.title}}
                                    </v-stepper-step>
                                    <v-divider v-if="index < steps.length - 1" :key="index + 22"></v-divider>
                                </template>
                            </v-stepper-header>


                            <!-- **************** -->
                            <!--     Vertical     -->
                            <!-- **************** -->
                            <div v-if="vertical">
                                <template v-for="(step, index) in steps" >
                                    <v-stepper-step :step="index + 1" :key="index">
                                        {{step.title.length > charLimit ? step.title.substring(0, charLimit) + '...': step.title}}
                                    </v-stepper-step>
                                    <v-stepper-content v-if="index < steps.length - 1" :step="index + 1" :key="index + 22"></v-stepper-content>
                                
                                </template>
                            </div>
                        </v-stepper>
                            
                    </v-col>
                    <v-col :cols="this.vertical ? '9' : '12'" :sm="this.vertical ? '10' : '12'"   :md="this.vertical ? '9' : '12'" :class="this.vertical ? 'pl-0':'pt-0'"> 
                        <v-card  elevation="20" rounded class="text-center d-flex flex-column justify-space-between" style="height: 100%;">
                            <v-card-title class="justify-center py-8 d-block">
                                <h1 class="text-center text-h3">Hello {{this.helloName ? this.helloName : "friend"}}!</h1>
                            </v-card-title>
                            <v-card-text >
                                <h6 class="secondary--text text-h6 mt-4">
                                    Your order status is currently: 
                                </h6>
                                <span class="text-h4 ">
                                    {{ this.currentStatus }}
                                </span>
                            </v-card-text>
                            <v-card-text class="py-8">
                                <h6 class="secondary--text text-h6">
                                    If you have questions, please contact us at {{ this.phoneStr }}  <a :href="'mailto:' + this.emailStr">{{ this.emailStr }}</a> {{ this.emailSpaceStr }} <a target="_blank" :href="'//' + this.supportUrl">{{ this.urlStr }}</a>
                                </h6>
                            </v-card-text>
                            <v-card-actions>
                                <v-row class="my-0 justify-center">
                                    <v-col cols="10" class="d-flex justify-center">
                                        <span>© 2022 - Smart Ramp</span>
                                    </v-col>
                                </v-row>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-row> 
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        tracker: {
            type: Object,
            default: null
        },
        stages: {
            type: Array,
            default: null
        },
    },
    data () {
        return {
            steps: [],
            currentStep: 0,
            vertical: false,
            charLimit: 15,

            //MAPPED VALUES
            helloName: null,
            currentStatus: null,
            supportPhone: null,
            supportEmail: null,
            supportUrl: null,
            
            //MESSAGE HELPERS
            phoneStr: "",
            emailStr: "",
            urlStr: "",
            emailSpaceStr: "",


            window: { //LAYOUT TOOL: store location when page will need vertical space calculations         )
                width: 0,
                height: 0
            },
        }
    },
    methods: {
        mapData() {
            this.helloName = 'Friend'
            this.supportEmail = this.tracker ? this.tracker.Email : null;
            this.supportPhone = this.tracker ? this.tracker.PhoneNumber : null;
            this.supportUrl = this.tracker ? this.tracker.SupportUrl : null;

            this.buildPhoneStr();
            this.buildEmailSpaceStr();
            this.buildEmailStr();
            this.buildUrlStr();

            this.steps = this.stages;

            this.vertical = this.steps.length > 5 ? true : false;
        },
        async setSteps() {
            this.steps = this.currentTracker ? this.currentTracker.item2 : [];

            if (this.steps) {
                this.vertical = this.steps.length > 5 ? true : false;
            }
        },
        setActiveStep() {
            if(this.currentTracker.item1 != null && this.currentTracker.item1 != undefined ){
                this.currentStep = this.currentTracker.item2.findIndex(stage => stage.id === this.currentTracker.item1) + 1;
                this.currentStatus = this.currentTracker.item2.filter(stage => stage.id === this.currentTracker.item1);
            }
        },
        buildPhoneStr() {
            this.phoneStr = this.supportPhone 
                ? (this.supportPhone && this.supportEmail && this.supportUrl) 
                    ? this.supportPhone + ", " 
                    : (this.supportPhone && this.supportEmail || this.supportPhone && this.supportUrl) 
                        ? this.supportPhone + " or " 
                        : this.supportPhone
                : "";
        },
        buildEmailStr() {
            this.emailStr = this.supportEmail 
                ? this.supportEmail
                : "";
        },
        buildEmailSpaceStr() {
            this.emailSpaceStr = (this.supportEmail && this.supportUrl 
                    ? " or " 
                    : "")
        },
        async setStages(stages) {
            this.steps = stages;
            this.vertical = stages.length > 5 ? true : false;

            this.$nextTick(async function () {
                let current = Math.floor((Math.random() * stages.length) + 1);

                this.currentStep = current
                this.currentStatus = stages[current-1].title;
            })
        },
        buildUrlStr() {
            this.urlStr = this.supportUrl ? this.supportUrl : "";
        },
        setPhone(supportPhone) {
            this.supportPhone = supportPhone;
            this.buildStr();
        },
        setEmail(supportEmail) {
            this.supportEmail = supportEmail;
            this.buildStr();
        },
        setUrl(supportUrl) {
            this.supportUrl = supportUrl;
            this.buildStr();
        },
        buildStr() { 
            this.buildPhoneStr();
            this.buildEmailSpaceStr();
            this.buildEmailStr();
            this.buildUrlStr();
        }
    },
    created() {
        this.mapData();

        //LAYOUT TOOL: resize handler
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() { //LAYOUT TOOL: on leave dispose
        window.removeEventListener('resize', this.handleResize);
    },
    computed: {
        ...mapGetters({
            currentTracker: 'status/getCurrentTracker',
        })
    },
}
</script>

<style lang="scss">
    .v-stepper-header > .v-stepper__label{
        padding: 5px;
    }
    .v-stepper__header{
        flex-wrap: nowrap;
        .v-stepper__label{
            text-align: center;
        }
    }
</style>

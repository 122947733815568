<template>
    <v-container >
              <v-row > 
                  <v-col cols="4" class="pr-0">    
                    <v-text-field
                        clearable
                        v-model="search"
                        prepend-inner-icon="mdi-magnify"
                        label="Search Templates"
                        single-line
                        solo
                        hide-details="true"
                        flat
                    ></v-text-field>
                    <v-divider></v-divider>
                    <v-list 
                    dense
                    id="scroll-target"
                    style="max-height: 100%"
                    class="overflow-y-auto list-editor border-right"
                    :height="window.height - this.appBarHeight - this.settingsHeadingElement - this.dividerHeight - 12  - this.conversationsNavFilterElement - 12">
                        <v-subheader class="body-1">Existing Templates: </v-subheader>
                        <v-list-item-group color="primary" dense>
                         <v-list-item 
                            class="chat-bubble left d-flex flex-column"
                            v-for="(template) in searching"
                            :key="template.id"
                            @click="editTemplate(template)"
                            hint="Keep a short yet significative name."
                        >
                            <v-list-item-title class="overline pb-1">
                            {{template.name}}
                            </v-list-item-title>
                            <v-list-item-content class="body-2">
                            {{template.templateBody}}
                            </v-list-item-content>
                        </v-list-item>
                        </v-list-item-group>

                    </v-list>
                  </v-col>
                  <v-col cols="8" class="pl-0 pb-0">
                    <v-card elevation="1" class="fill-height">
                            <v-card-title>
                                <h3 v-if="!selectedTemplate.id">Create New Template</h3>
                                <h3 v-else>Edit Template</h3>
                            </v-card-title>
                            <v-card-text>
                                <v-form  v-model="templateValid" id="templateForm" ref="templateForm" lazy-validation>
                                    <v-row class="d-flex flex-column justify-space-between" style="height: 100%">
                                        <v-col class="flex-grow-1" >

                                            <v-text-field
                                                outlined
                                                label="Template Name"
                                                v-model="selectedTemplate.name"
                                                @change="dirtyForm"
                                                @keydown="[templateObjHasChanged, dirtyForm]"
                                                :rules="[templateRules.required]"
                                            ></v-text-field>
                                            <v-textarea
                                                flat
                                                class="chat-bubble-override left mb-6 pt-4 "
                                                v-model="selectedTemplate.templateBody"
                                                @change="dirtyForm"
                                                @keydown="[templateObjHasChanged, dirtyForm]"
                                                label="Template Message Body"
                                                auto-grow
                                                rows="3"
                                                counter="160"
                                                hint="Type a message of max 160 chars"
                                                placeholder="Type a message"
                                                :rules="[templateRules.max, templateRules.required]"
                                            ></v-textarea>
                                            <v-file-input
                                                outlined
                                                prepend-icon=""
                                                prepend-inner-icon="mdi-paperclip"
                                                v-model="selectedTemplate.imgUrl"
                                                chips
                                                label="Upload Image (optional)"
                                            ></v-file-input>
                                        </v-col>
                                    
                                        
                                    </v-row>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>    
                            <v-footer absolute>
                                <v-row>
                                    <v-col cols="12" class="d-flex justify-space-between">
                                        <div>
                                            <!--TODO:  Need to improve on form checkups so that when a change is made it would show btn status inmediately -->
                                            <v-btn color="primary" v-if="!selectedTemplate.id" @click="saveTemplate" :disabled="!templateValid || !formState.formIsDirty || this.selectedTemplate.name === null || this.selectedTemplate.templateBody === null">
                                                <span>Save New Template</span>
                                            </v-btn>
                                            <v-btn v-else color="primary" @click="saveTemplate">
                                                <span>Save Edits</span>
                                            </v-btn>
                                            <v-btn color="primary"  @click="clearTemplate" outlined>Clear</v-btn>
                                        </div>
                                        <v-btn color="error" v-if="selectedTemplate.id" v-on:click="deleteTemplate"> Delete Selected </v-btn>
                                    </v-col>
                                </v-row>
                            </v-footer>
                        </v-card-actions>
                    </v-card>
                    
                  </v-col>
              </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters({
            currentCompany: 'company/getCurrentCompany',
            companyTemplates: 'template/getCompanyTemplates'
        }),
        searching () {
            if (!this.search) return this.templates

            const search = this.search.toLowerCase()

            return this.templates.filter(template => {
            const text = template.templateBody.toLowerCase()
            const name = template.name.toLowerCase()

            return text.indexOf(search) > -1 ||  name.indexOf(search) > -1 
            })
        },
    },
    data (){
        return {
            templates: [],
            search: '',
            selectedTemplate:{},
            templateValid: true,
            formState:{
                formIsDirty: false
            },
            templateRules: {
                max: v => { if (v) return v.length <= 160 || 'Max 160 characters';    else return false;  },
                required: v => !!v || 'Required.'
            },
            window: {
                width: 0,
                height: 0
            }
        }
    },
    async created() {
        this.$auth.auth0ClientPromise.then(async () => {
            if(Object.keys(this.currentCompany).length === 0){
                await this.$store.dispatch('getUserCompany');
                await this.$store.dispatch('template/getCompanyTemplatesByCompanyId',this.currentCompany.id);
                this.parseCompanyTemplates();
            }else{
                await this.$store.dispatch('template/getCompanyTemplatesByCompanyId',this.currentCompany.id);

                this.parseCompanyTemplates();
            }
            window.addEventListener('resize', this.handleResize);
            this.handleResize();
        });
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    watch: {
        selectedTemplate: {
            handler: function(v){
                return this.templateObjHasChanged();
            },
            deep: true
        }
    },
    methods: {
        editTemplate(template){
            this.selectedTemplate = Object.assign({}, template);
            this.formState.formIsDirty = false;
            this.templateValid = true;
        },
        clearTemplate(){
            this.selectedTemplate ={};
            this.formState.formIsDirty = false;

        },
        dirtyForm(){
            this.formState.formIsDirty = true;
        },
        async saveTemplate(){
            let successText = "updated";
            //Check if New Template
            if(!this.selectedTemplate.hasOwnProperty('id')){
                this.selectedTemplate.id = "00000000-0000-0000-0000-000000000000";
                successText = "created";
            }
            let response = await this.$store.dispatch('template/createOrUpdateTemplate',{
                "template": this.selectedTemplate,
                "companyId": this.currentCompany.id
            });

            if(response.result){
                this.selectedTemplate.id = response.id;
                this.$swal({
                    icon: 'success',
                    title: `Template ${this.capitalizeFirstLetter(successText)}`,
                    text: `Your template has been successfully ${successText}!`,
                    toast: true,
                    timer: 3000,
                    position: 'top-end'
                });
            }else{
                this.$swal({
                    icon: 'error',
                    title: 'Error Updating Template',
                    text: 'An error occurred while updating the template. Please try again later.',
                });
            }
            this.parseCompanyTemplates();
        },
        async deleteTemplate(){
            this.$swal({
                title: `Are you sure you want to delete template: ${this.selectedTemplate.name}?`,
                showDenyButton: true,
                confirmButtonText: "Yes",
                denyButtonText: "No"
            }).then(async result => {
                if(result.isConfirmed){
                    let response = await this.$store.dispatch('template/deleteTemplate',{
                        "template": this.selectedTemplate,
                        "companyId": this.currentCompany.id
                    });
                    if(response){
                        this.$swal({
                            icon: 'success',
                            title: 'Template Successfully Deleted',
                            text: 'Template Successfully Deleted',
                            toast: true,
                            timer: 3000,
                            position: 'top-end'
                        });
                    }else{
                        this.$swal({
                            icon: 'error',
                            title: 'Error Deleting Template',
                            text: 'An error occurred while deleting the template. Please try again later.',
                            toast: true,
                            timer: 3000,
                            position: 'top-end'
                        });
                    }
                    this.selectedTemplate = {};
                    this.parseCompanyTemplates();
                }
            });
        },
        templateObjHasChanged(){
            this.$refs.templateForm.validate();
        },
        parseCompanyTemplates(){
            let templates = this.companyTemplates;
            let formattedTemplates = [];
            templates.forEach(template => {
                let obj = {
                    "id": template.id,
                    "name": template.messageName,
                    "templateBody": template.messageText,
                    "imgUrl": null
                };
                if(template.fileName !== null){
                    this.$store.dispatch("template/convertBase64StringtoFile",template).then(file => {
                        obj.imgUrl = file;
                    });
                }
                formattedTemplates.push(obj);
            });
            this.templates = formattedTemplates;
        }
    }
}
</script>
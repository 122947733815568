<template>
    <v-container>
    <v-row>
        <template v-for="(state, stateIndex) in wizardState">
            <template  v-if="state.type === 'system'" >
                <v-col cols="2" v-if="stateIndex === 1" :key="stateIndex" class="my-auto text-center" :class="stepName === 'Systems' ? 'd-none' : ''">
                        <div v-if="state.value !== null">
                            <v-icon :x-large="isLarge">mdi-arrow-right</v-icon>
                        </div>
                </v-col>
                <v-col cols="5" :key="stateIndex" class="text-center py-4" :class="stepName === 'Systems' ? 'd-none' : ''">
                    <div v-if="state.value !== null">
                        <v-img 
                            v-if="state.value !== null"
                            position="top center"
                            :src="require('@/assets/images/integrations/' + getSystemImage(state.value).src )"
                            contain
                            class="mx-auto"
                            :max-height="isLarge ? '120px':'60px'"
                            :max-width="isLarge ? '120px':'60px'"
                        ></v-img>
                        <span v-if="value !== null">{{state.value}}</span>
                    </div>
                </v-col>
                <v-col v-if="stateIndex === 1" :key="stateIndex" cols="12" class="pa-0"></v-col>
            </template>
         
            <template  v-if="state.type === 'trigger'">
                <v-col cols="auto"  :key="stateIndex" class="text-center py-1" :class="stepName === 'Triggers' ? 'd-none' : ''">
                    <div v-if="state.value !== null" >
                        <span  :class="isLarge ? 'text-h6': 'body-1'" class="mr-2" v-if="stateIndex === 2"> Trigger <v-icon>mdi-arrow-right</v-icon></span>
                        <v-chip color="accent" :large="isLarge"> {{state.value}}</v-chip>
                        <span class="ml-3" v-if="stateIndex === 2"> when:</span>
                    </div>
                </v-col>
                <v-col v-if="stateIndex === 3" :key="stateIndex" cols="12" class="pa-0"></v-col>
            </template>
       
            <template v-if="state.type === 'objects'" >
                <v-col cols="12" class="py-1" :key="stateIndex" :class="stepName === 'Objects' ? 'd-none' : ''">
                    <div v-if="state.value.length > 0">
                        <span :class="isLarge ? 'text-h6': 'body-1'" class="mr-2">Objects: </span>
                        <v-chip :large="isLarge" dark class="ma-1" v-for="(object ,objIndex) in state.value" :key="objIndex" color="iq_lightblue"> {{state.value[objIndex]}}</v-chip>
                    </div>
                </v-col>
                <v-col v-if="stateIndex === 4" :key="stateIndex" cols="12" class="pa-0"></v-col>
            </template>
          
            <template v-if="state.type === 'fields'">
                <v-col  cols="12" md="6" class="py-1" :key="stateIndex" :class="stepName === 'Fields' ? 'd-none' : ''">
                    <div v-if="state.value.length > 0">
                        <span :class="isLarge ? 'display-1 py-3 d-block': 'body-1'" class="mr-2" v-text="stateIndex === 5 ? 'User Selected Fields: ':'Preselected Fields:'" ></span>
                        <span v-if="isLarge">
                            <v-card outlined class="pa-1" v-for="(field, fieldIndex) in state.value" :key="fieldIndex" :class="field.selected ? 'd-block':'d-none'">
                                <div class="d-flex flex-row">
                                    <v-icon large>{{getParentObject(field.object).icon}}</v-icon>
                                        <div class="ml-1">
                                            <span class="d-block body-1 font-weight-medium" v-if="state.value[fieldIndex].name === 'Custom field'"> {{state.value[fieldIndex].customFieldName}}  </span>
                                            <span class="d-block body-1 font-weight-medium" v-else> {{state.value[fieldIndex].name}}  </span>
                                            <span class="caption"> {{state.value[fieldIndex].object}}  </span>
                                        </div>
                                </div>
                            </v-card>
                        </span>
                        <span class="font-weight-bold" v-else>
                            {{state.value.length}}
                        </span>
                    </div>
                </v-col>
            </template>
            
        </template>
    </v-row>
    </v-container>
</template>
<script>
import Templates from '../../AdminComponents/Templates.vue';
export default {
  components: { Templates },
    name: 'Status',
    props: {
        wizardState: Array,
        stepName: String, 
        isLarge: {
            type: Boolean, 
            default: false
        }
    },
    data () {
        return {
            systems: [
                { name: 'recurly',                  title:'Recurly',    src: 'Recurly.png'},
                { name: 'salesforce',               title:'Sales Force',    src: 'SalesForce.png'},
                { name: 'sendgrid',                 title:'SendGrid',   src: 'SendGrid.png'},
                { name: 'smartsheet',               title:'Smartsheet', src: 'smartsheet.png'},
                { name: 'square',                   title:'Square', src: 'square.png'},
                { name: 'stripe',                   title:'Stripe', src: 'Stripe.png'},
                { name: 'sugarcrm',                 title:'Sugar CRM',  src: 'SugarCRM.png'},
                { name: 'toast',                    title:'Toast',  src: 'toast.png'},
                { name: 'trello',                   title:'Trello', src: 'Trello.png'},
                { name: 'xero',                     title:'Xero',   src: 'xero.png'},
                { name: 'zendesk',                  title:'ZenDesk',    src: 'zendesk.png'},
                { name: 'zohocrm',                  title:'Zoho CRM',   src: 'ZOHOCRM.png'},
                { name: 'activecampaign',           title:'Active Campaign',    src: 'ActiveCampaign.png'},
                { name: 'brighttree',               title:'Brighttree', src: 'brighttree.png'},
                { name: 'bullhorn',                 title:'Bullhorn',   src: 'bullhorn.png'},
                { name: 'chargebee',                title:'Chargebee',  src: 'ChargeBee.png'},
                { name: 'chargify',                 title:'Chargify',   src: 'chargify.png'},
                { name: 'clover',                   title:'Clover', src: 'clover.png'},
                { name: 'drift',                    title:'Drift',  src: 'drift.png'},
                { name: 'freshbooks',               title:'Freshbooks', src: 'freshbooks.png'},
                { name: 'freshdesk',                title:'Freshdesk',  src: 'Freshdesk.png'},
                { name: 'getresponse',              title:'Getresponse',    src: 'getResponse.png'},
                { name: 'hubspot',                  title:'Hubspot',    src: 'Hubspot.png'},
                { name: 'insightly',                title:'Insightly',  src: 'insightly.png'},
                { name: 'intercom',                 title:'Intercom',   src: 'intercom.png'},
                { name: 'jira',                     title:'Jira',   src: 'jira.png'},
                { name: 'keap',                     title:'Keap',   src: 'Keap.png'},
                { name: 'klaviyo',                  title:'Klaviyo',    src: 'klaviyo.png'},
                { name: 'marketo',                  title:'Marketo',    src: 'marketo.png'},
                { name: 'microsoftdynamics365crm',  title:'Microsoft Dynamics 365 CRM', src: 'MicrosoftDynamics365CRM.png'},
                { name: 'microsoftdynamicsax',      title:'Microsoft Dynamics AX',  src: 'MicrosoftDynamicsAX.png'},
                { name: 'microsoftdynamicsgp',      title:'Microsoft Dynamics GP',  src: 'MicrosoftDynamicsGP.png'},
                { name: 'mindbody',                 title:'Mindbody',   src: 'mindbody.png'},
                { name: 'netsuite',                 title:'NetSuite',   src: 'netsuite.png'},
                { name: 'oracleeloquia',            title:'Oracle Eloquia', src: 'OracleEloquia.png'},
                { name: 'oraclesalescloud',         title:'oracle Sales Cloud', src: 'oracleSalesCloud.png'},
                { name: 'pipedrive',                title:'Pipedrive',  src: 'pipedrive.png'},
                { name: 'quickbooks',               title:'Quickbooks', src: 'quickbooks.png'},
            ],
            objects: [
                {name: 'Contacts', value:'Contact',color:"primary lighten-4", icon:"mdi-account-check"},
                {name: 'Company' , value:'Company',color:"secondary lighten-4", icon:"mdi-domain"},
                {name: 'Deal' , value:'Deal',color:"iq_lightblue lighten-5", icon:"mdi-handshake"},
                {name: 'Products' , value:'Products',color:"iq_aquagreen lighten-4", icon:"mdi-cube"},
                {name: 'Deal/Fullfilment' , value:'Deal/Fullfilment',color:"success lighten-4", icon:"mdi-cube-send"},
                {name: 'Deal/Invoice', value:'Deal/Invoice',color:"iq_desaturatedblue lighten-4", icon:"mdi-receipt"},
            ],
        }
    },
    methods: {
        getSystemImage (name){
            var result =  this.systems.find(x => x.title === name);
            console.log(result)
            return result;
        },
        getParentObject(name){
            var result = this.objects.find(x => x.value === name);
            console.log(result);
            return result;
        },
    },
    
}
</script>
<style lang="postcss" scoped>
    .border-bottom{ 
        border-bottom: 1px solid #ccc;
    }
</style>
<template>
    
    <v-container class="mt-n1 pt-0">

        <v-row class="py-3">
           <v-col cols="6">
                <div class="d-flex flex-row align-start my-3"> 
                    <v-icon class="xx-large">mdi-group</v-icon>
                    <div class="ml-2">
                        <h1 class="display-1"> Objects </h1>
                        <h4 class="body-2">Select the objects for the integration and their child fields.</h4>
                    </div>
                </div>
                <p>Ea dolor ut duis esse occaecat nisi. Labore nulla officia minim est. Cillum fugiat aliqua enim dolor.
                    Labore nisi esse quis occaecat mollit magna Lorem quis eu eu proident Lorem irure. Nisi id nostrud dolor culpa incididunt magna id. Qui cupidatat cillum sit est cillum culpa velit. Nisi laborum dolore dolore do cillum excepteur occaecat in ipsum do aliquip proident enim culpa. Reprehenderit veniam consequat reprehenderit incididunt qui dolore amet irure tempor Lorem sint.
                </p>
           </v-col>
            <v-col cols="6">
                <Status :wizardState="this.status" :stepName="currentRouteName"></Status>
           </v-col>
        </v-row>
        <v-card rounded elevation="1" outlined>
            <v-expansion-panels focusable v-model="panel">
                <v-expansion-panel v-for="businessObject in businessObjects" :key="businessObject.id" class="mt-0">
                    <v-expansion-panel-header>
                        <span class="text-h6 flex-grow-0">{{businessObject.name}} <span class="ml-2 font-weight-medium primary--text" v-if="businessObject.isRequired">Required *</span> <span class="body-2">Select one or more</span> </span>
                         <v-chip label color="iq_lightblue" dark class="flex-grow-0 ml-1" v-for="value in values" :key="value">
                            <v-icon small class="mr-1">mdi-group</v-icon>
                            {{value}}
                        </v-chip>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="mt-4">
                            <v-data-iterator
                            :items=" businessObject.options"
                            :page.sync="objectPage"
                            :search="objectSearch"
                            :sort-by="objectSortBy"
                            :sort-desc="objectSortDesc"
                            :items-per-page="objectItemsPerPage"
                            :footer-props="{
                                itemsPerPageOptions: [8,16,22,25,50,100, -1]
                            }">
                                <template v-slot:default="props">
                                    <v-item-group multiple v-model="values" :value="selection">
                                        <v-row>
                                            <v-col cols="6"  sm="4" md="3"  xl="2"  v-for="(item, optionIndex) in  props.items" :key="optionIndex" class="pa-1">
                                                <v-hover v-slot="{ hover }">
                                                    <v-item v-slot="{ active, toggle }" :value="item.optionName" @change="getSelectetObjectName(item.optionName, item.optionID, optionIndex )">    
                                                        <v-card min-height="185px" max-height="185px" :elevation="hover ? 2 : 0" rounded outlined class="pa-0 d-flex flex-column flex-start align-start text-break justify-space-between lighten-5" :color="active ? 'info' : ''" @click="toggle">
                                                            <v-card-title class="d-flex justify-center" style="width: 100%">
                                                                <span class="text-uppercase font-weight-medium text-tight">{{item.optionName}} </span>
                                                            </v-card-title>
                                                            <v-card-text class="d-flex flex-column justify-center flex-grow-1 align-center overflow-y-auto pb-0">
                                                                    <p>{{item.optionDescription}}</p>
                                                            </v-card-text> 
                                                            <!-- <v-card-actions class="d-flex justify-center" style="width: 100%">
                                                                <v-btn color="primary" @click.stop="showFullDescription" class="font-weight-medium" text v-if="item.showMore">More...</v-btn>
                                                            </v-card-actions> -->
                                                        </v-card>
                                                    </v-item> 
                                                </v-hover>
                                            </v-col>
                                        </v-row>
                                    </v-item-group>
                                </template>
                                <template v-slot:footer.prepend>
                                    <div class="pt-5 ml-n3">
                                        <v-btn color="primary" text  @click="selectAll">
                                            Select All
                                        </v-btn>
                                        <v-btn color="secondary" text  @click="selectNone">
                                            Select None
                                        </v-btn>
                                    </div>
                                    <div class="pt-3 ml-auto text-center">
                                        <div class="py-2 body-2">
                                            <span>Object not listed? need a custom</span>
                                        </div>
                                        <v-btn color="primary">
                                            <v-icon> mdi-plus</v-icon>
                                            Add Custom
                                        </v-btn>
                                    </div>
                                </template>
                            </v-data-iterator>    
                            

                    </v-expansion-panel-content>

                </v-expansion-panel>
            </v-expansion-panels>

        
        </v-card>

        <v-row>
            
            <v-col> &nbsp;  
            </v-col>

        </v-row>
        <v-divider></v-divider>
        <v-footer color="iq_opaque" class="pt-4">
            <v-btn to="/wizard/fields" large color="primary" :disabled="this.values.length === 0"> Continue </v-btn>
            <v-btn text large > Cancel </v-btn>
            <v-btn to="/wizard/triggers" color="secondary" text large class="ml-auto" > Back </v-btn>
        </v-footer>

    </v-container>
    
</template>
<script>
import Status from "./_status.vue";

export default {
    props: ['status'],
    components: {
        Status
    },
    data () {
        return{
            panel: 0,
            businessObjects:[
                {id: '1', name: 'Objects', isRequired: true, value: undefined, selected:[],
                    options: [
                        {optionID: '1', selected:false, optionName: 'Contacts', optionDescription:'Irure eu consectetur nulla aliquip Lorem culpa ullamco consequat dolor et anim.', showMore: true},
                        {optionID: '2', selected:false, optionName: 'Company', optionDescription:'Nulla reprehenderit ullamco esse occaecat adipisicing magna ipsum sunt tempor .', showMore: true},
                        {optionID: '3', selected:false, optionName: 'Deal', optionDescription:'Veniam reprehenderit sunt eu exercitation sint nostrud nostrud elit exercitation consequat.', showMore: true},
                        {optionID: '4', selected:false, optionName: 'Products', optionDescription:'Consectetur quis nostrud consectetur laboris.', showMore: true},
                        {optionID: '5', selected:false, optionName: 'Deal/Fullfilment', optionDescription:'Aliquip laboris occaecat occaecat ea Lorem do anim id et quis in.', showMore: true},
                        {optionID: '6', selected:false, optionName: 'Deal/Invoice', optionDescription:'Voluptate et reprehenderit ea et consequat cillum dolore ex et qui tempor deserunt id occaecat.', showMore: true}
                    ]
                }
            ],
            values: [],
            selection: [],
            objectSearch: '',
            objectFiter: {},
            objectOrderByKeys: [],
            objectSortDesc: false, 
            objectPage: 1, 
            objectItemsPerPage: -1, 
            objectSortBy: 'optionName',
        }
    },
    watch: {
        values: {
            handler: function (v){
                console.log('something happened!' + v)
                this.status[4].value = this.values;
            },
            deep: true
        
        }
    },
    methods:{
         getSelectetObjectName(elem, arrayId, itemIndex){
            console.log(elem);
            console.log(arrayId);
            console.log(itemIndex);
        }, 
        selectAll(){
            this.values = ['Contacts','Company','Deal','Products','Deal/Fullfilment','Deal/Invoice']
        },
        selectNone(){
            this.values = [];
        }
    },
    computed:{
        currentRouteName() {
            return this.$route.name;
        },
    }
}
</script>
import Api from "@/services/api";
import Vue from 'vue';

export default {
    namespaced: true,
    state: {

    },
    actions: {
        async getObjects(){
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };

            let response = await Api().get(`/Web/SmartRamp/Status/GetObjects`, config)
            .catch(err => {
                console.log(err);
            
            });
            return response;
        },
        async getHSProperties({},{objectType:objectType}){
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };
            
            let response = await Api().get(`/Web/SmartRamp/Status/GetPipelines/${objectType}`,config)
            .catch(err => {
                console.log(err);
            });
            return response;
        },
        async validateUrl({}, Url){
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };

            let response = await Api().post(`/Web/SmartRamp/Status/ValidateURL`, Url, config)
            .catch(err => {
                console.log(err);
                return err.response;
            });
            return response;
        },
        async getTrackersByCompanyId(){
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };
            let response = await Api().get(`/Web/SmartRamp/Status/GetTrackersByCompany`, config)
            .catch(err => {
                console.log(err);
            });
            return response.data;
        },
        async createTracker({},{tracker: tracker}){
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };
            let response = await Api().post(`/Web/SmartRamp/Status/Tracker`, tracker, config)
            .catch(err => {
                console.log(err);
                return(err.response);
            });

            return response;
        },
        async deleteTracker({}, {idTracker: idTracker}){
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };
            let response = await Api().delete(`/Web/SmartRamp/Status/DeleteTracker/${idTracker}`, config)
            .catch(err => {
                console.log(err);
                return false;
            });
            return response.status;
        },
        async getTrackersById({}, {idTracker: idTracker}) {
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };
            let response = await Api().get(`/Web/SmartRamp/Status/GetTrackersById/${idTracker}`, config)
            .catch(err => {
                console.log(err);
                return false;
            });
            return response;
        },
        async updateTracker({}, {tracker: tracker}) {
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };
            let response = await Api().put(`/Web/SmartRamp/Status/UpdateTracker`, tracker, config)
            .catch(err => {
                console.log(err);
                return false;
            });
            return response;
        }
    },
    mutations: {
        
    },
    getters: {
        
    }
}
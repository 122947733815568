<template>
    <div>
        <v-form v-model="valid" id="callForwardingForm" ref="callForwardingForm" lazy-validation>
            <v-row>
                <v-col cols="12">
                    <h1  :class="this.$vuetify.breakpoint.smAndDown ? 'white--text text-h5 text-center' : 'secondary--text text-h2'">Where should we forward your calls? (Optional)</h1>
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        v-model="callForwardingForm.callForwardingNumber"
                        :color="!this.$vuetify.breakpoint.smAndDown ? 'iq_dark' : 'iq_light'"
                        :background-color="!this.$vuetify.breakpoint.smAndDown ? 'iq_light' : ''"
                        :dense="this.$vuetify.breakpoint.smAndDown"
                        hint="This is usually your primary business phone number."
                        v-mask="phoneMask"
                        label="Call Forwarding Number"
                        outlined
                        required
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-form>
        <v-footer 
        :app ="this.$vuetify.breakpoint.smAndDown"
        :class="this.$vuetify.breakpoint.smAndDown ? 'bg-mobile-overlay border-top':'bg-transparent'"
        padless 
        class="px-0 bg-transparent" 
        >
            <v-container fluid class="pa-0">
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-card-actions class="d-flex justify-space-between" :class="this.$vuetify.breakpoint.smAndDown ? '':'px-0'">
                                <v-btn x-large outlined color="primary" to="step3"> back </v-btn>
                                <v-btn x-large color=“primary” v-if="callForwardingForm.callForwardingNumber" v-on:click="createCompany"> Next </v-btn>
                                <v-btn x-large color=“primary” @click="showAlert" v-else> Skip </v-btn>
                        </v-card-actions>
                    </v-col>
                </v-row>
            </v-container>
        </v-footer>        
    </div>
</template>
<script>
export default {
    name: "AccountSetup5",
    data () {
        return {
            valid: false,
            callForwardingForm: {
                callForwardingNumber: ''
            },
            phoneMask: '(###) ###-####',
        }   
    }, 
    methods: {
        showAlert() {
            this.$swal.fire({
                title: 'Setup Later',
                text: 'Forwarding Number can be setup later in MIQ Settings',
                icon: 'info'}).then(()=> {
                    this.$router.push('/setup/step5');
            })
        },
        async createCompany(){
            if(this.validPhone(this.callForwardingForm.callForwardingNumber)){
                this.$store.dispatch("registration/saveCallForwardingNumber",this.callForwardingForm.callForwardingNumber);
                let result = await this.$store.dispatch("registration/registerCompany");
                if(result){
                    this.$router.push('/setup/step5');
                }else{
                    this.$swal({
                        icon: 'error',
                        title: 'Error Creating Account',
                        text: 'An error occurred while creating your account. Please try again later.',
                    })
                }
            }else{
                this.$swal({
                    icon: 'error',
                    title: 'Error Creating Account',
                    text: 'An error occurred while creating your account. Please try again later.',
                });
            }
        }
    }

}
</script>
import Vue from 'vue'
import moment from 'moment';

Vue.mixin({
    data: function() {
        return {
            appBarHeight: 32,
            appNavWidthMini: 90,
            appNavWidthExpanded: 250,
            appHeaderToolbar: 64,
            
            dataTableFooter: 60,

            conversationsNavWidth: 300,
            conversationsNavFilterElement: 48,

            conversationToolbarHeight: 49,
            dividerHeight: 1,
            
            settingsHeadingElement: 112,

        }
    },
    methods: {
        handleResize() { //LAYOUT TOOL: global handler
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
        chatWindowScroll(){
            const container = document.getElementById('conversation-pane');
            container.scrollTop = container.scrollHeight;
        },
        formatPhoneNumber(phoneNumberString){
            var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
            var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
            if (match) {
                return ['(', match[2], ') ', match[3], '-', match[4]].join('');
            }
            return null;
        },
        formatDateTimeToDate(dateTime){
            return moment(dateTime).format('M/D/YYYY');
        },
        capitalizeFirstLetter(string){
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        commaSeparateNumber(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        splitDecimals(x){
            let priceSplit = x.toString().split(".");
            let array = [];

            if( priceSplit[0] !== 'undefined'){
                array.push(priceSplit[0]);
            }

            if( priceSplit[1]){
                if (priceSplit[1].length < 2){// checkup for  JS removal of 0s in cents
                    array.push( "." +priceSplit[1]+ "0");
                }else{
                    array.push( "." +priceSplit[1]);
                }
            }
            else{ // in case it's empty JS will erase 00 
                array.push( ".00");
            }

            return array;

        },
        timeDifference(current, previous) {
            var msPerMinute = 60 * 1000;
            var msPerHour = msPerMinute * 60;
            var msPerDay = msPerHour * 24;
            var msPerMonth = msPerDay * 30;
            var msPerYear = msPerDay * 365;

            var elapsed = current - previous;
            if (elapsed < msPerMinute) {
                return Math.round(elapsed/1000) + ' seconds ago';   
            }

            else if (elapsed < msPerHour) {
                return Math.round(elapsed/msPerMinute) + ' minutes ago';   
            }

            else if (elapsed < msPerDay ) {
                return Math.round(elapsed/msPerHour ) + ' hours ago';   
            }

            else if (elapsed < msPerMonth) {
                return Math.round(elapsed/msPerDay) + ' days ago';   
            }

            else if (elapsed < msPerYear) {
                return Math.round(elapsed/msPerMonth) + ' months ago';   
            }

            else {
                return Math.round(elapsed/msPerYear ) + ' years ago';   
            }
        },

        formatDay(day){
            return moment(day).format("MM/DD/YYYY");
        },
        
        formatHour(day){
            return moment.utc(day).local().format("hh:mm a");
        },

        getInitials(value){
            let initials = "";
            if(value === 'Integrate IQ'){
                initials = "IIQ";
            }else{   
                initials = value.split(" ").map((n)=>n[0]).join("");
             }
            return initials;
         },
         validPhone (phone) {
            if (/^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/.test(phone)){
                return true;
            }else{
                return false;
            }
        },
        includeThousandsComma(x){
            const str = x.toLocaleString();
            return str;
        },
        convertLargeNumberToLetters(num){
            num = num.toString().replace(/[^0-9.]/g, '');
            if (num < 1000) {
                return num;
            }
            let si = [
              {v: 1E3, s: "K"},
              {v: 1E6, s: "M"},
              {v: 1E9, s: "B"},
              {v: 1E12, s: "T"},
              {v: 1E15, s: "P"},
              {v: 1E18, s: "E"}
              ];
            let index;
            for (index = si.length - 1; index > 0; index--) {
                if (num >= si[index].v) {
                    break;
                }
            }
            return (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[index].s;

        },
        stringSpaceRemoval(str){
            return str.replace(/\s/g, '');
        }
    }
  })


<template>

    <v-layout fill-height>  
    <v-navigation-drawer
       right
       app
       disable-resize-watcher
       v-model="previewDrawer"
       :width="this.$vuetify.breakpoint.mdAndDown ? '88%': '60%'"
       >
        <template v-slot:prepend>
            <v-list-item>
            <v-list-item-content>
                <v-list-item-title class="text-h6 d-flex align-center justify-space-between" >
                    <span>StatusIQ Preview</span>
                    <v-btn text fab small  @click="manuallyClosePreview">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-list-item-title>
            </v-list-item-content>
            </v-list-item>
        </template>
            <v-container fluid class="fill-height">
                <v-row>
                        <TrackView 
                            v-if="this.stages"
                            ref="trackView"
                            :tracker="this.trackerForm"
                            :stages="this.stages"
                        >
                        <!-- v-if="this.trackerForm.StatusStages.length" -->
                        
                        </TrackView>
                </v-row>
            </v-container>
                    
    </v-navigation-drawer>       
    <v-container fluid class="pa-0 d-flex fill-height" :style="this.previewDrawer  === false ? 'padding-right:0px !important;':'padding-right:60% !important;'">
        <v-overlay :value="loadingOverlay">
            <v-progress-circular
            indeterminate
            size="70"
            width="7"
            color="primary"
            ></v-progress-circular>
        </v-overlay>
        <v-row class="justify-center ma-0"> 
            <v-col cols="12" md="12" class="mt2 mb-4" >
                <v-form  v-model="trackerValid" id="trackerForm" ref="trackerForm" lazy-validation>
                    <v-row class="justify-center">
                        <v-col cols="12" md="8">
                            <v-card-title class="text-h5 px-0 pb-5 d-flex justify-space-between">
                                <div>
                                    Customize Your Status IQ Page
                                </div>
                                <!-- <div>
                                    <v-btn color="primary" :disabled="false" v-on:click="connectToHubSpot()"> Connect to HubSpot </v-btn>
                                </div> -->
                            </v-card-title>

                            <v-row>
                            <!-- ************** -->
                            <!-- HubSpot Object -->
                            <!-- ************** -->
                            <v-col cols="12" class="pt-0 pb-1">
                                <h6 :class="'secondary--text text-h6'">Select the Pipeline To Pull Your Status</h6>
                            </v-col>
                            <v-col cols="12"  class="py-0">
                                <v-select
                                    :dense="this.$vuetify.breakpoint.smAndDown"
                                    ref="selectedHsObject"
                                    outlined
                                    :items="hsObjects"
                                    v-model="trackerForm.ObjectType"
                                    item-text="name"
                                    item-value="value"
                                    label="Select Your HubSpot Object"
                                    v-on:change='handleSelectChange'
                                    @keydown="[validate]"
                                    :rules="formRules.select"
                                ></v-select>
                            </v-col>

                            <!-- **************** -->
                            <!-- HubSpot SearchValue -->
                            <!-- **************** -->
                            <v-col cols="12" class="py-0" v-if="showPipelines">
                                <v-select
                                    :dense="this.$vuetify.breakpoint.smAndDown"
                                    ref="selectedHsProperty"
                                    outlined
                                    :loading="loadingHsProperties"
                                    :items="hsProperties"
                                    v-model="trackerForm.SearchValue"
                                    v-on:change="saveSelectedPipeline"
                                    item-text="name"
                                    item-value="value"
                                    :label="loadingHsProperties ? 'Loading... please wait' : 'Select Your HubSpot Pipeline'"
                                    @keydown="[validate]"
                                    :rules="formRules.select"
                                ></v-select>
                                <v-alert
                                border="left"
                                color="primary"
                                dense
                                dismissible
                                elevation="5"
                                icon="$mdiAccount"
                                type="success"
                                :value="previewNotification"
                                v-html="previewNotificationText"
                                style="width: 100%;"
                                v-if="this.$vuetify.breakpoint.mdAndDown"
                                >
                                </v-alert>
                            </v-col>
                                
                            <!-- TODO: disable if there is no HS Object selected  -->
                            <!--     :disabled="hsProperties ? true : false"      -->

                            
                            <v-col cols="12" class="pt-0 pb-1">
                                <h6 :class="'secondary--text text-h6'">
                                    Enter one or more ways to access support - (At least one)
                                </h6>
                            </v-col>
                            <v-col cols="12" md="12" sm="12" class="py-0">

                                <!-- ***************** -->
                                <!--  Access Support   -->
                                <!-- ***************** -->
                                <v-text-field
                                    ref="supportURLInput"
                                    name="supportURLInput"
                                    :dense="this.$vuetify.breakpoint.smAndDown"
                                    outlined
                                    label="Link to Access Support (optional)"
                                    v-model="trackerForm.SupportUrl"
                                    @focusout="handleChange($event)"
                                    @keyup="handleChange($event)"
                                    @keyup.delete="handleChange($event)"
                                    :rules="formRules.urlRules"
                                ></v-text-field>
                                <!-- ref="supportURLInput" -->

                                <!-- **************** -->
                                <!--  Support Phone   -->
                                <!-- **************** -->
                                <vue-tel-input-vuetify 
                                    ref="phoneInput"
                                    name="phoneInput"
                                    :dense="this.$vuetify.breakpoint.smAndDown"
                                    outlined
                                    label="Support Phone Number (optional)"
                                    v-model="trackerForm.PhoneNumber" 
                                    @input="onInput"
                                    @blur="handlePhoneChange()"
                                    persistent-hint
                                    defaultCountry="US"
                                    
                                    :rules="[this.phone.valid || 'Invalid phone number']"
                                >
                                </vue-tel-input-vuetify>

                                 <!-- **************** -->
                                <!--  Support Email   -->
                                <!-- **************** -->
                                 <v-text-field
                                    ref="emailInput"
                                    name="emailInput"
                                    :dense="this.$vuetify.breakpoint.smAndDown"
                                    outlined
                                    label="Support Email (optional)"
                                    v-model="trackerForm.Email"
                                    @focusout="handleChange($event)"
                                    @keyup="handleChange($event)"
                                    @keyup.delete="handleChange($event)"
                                    :rules="formRules.emailRules"
                                ></v-text-field>
                                <!-- ref="emailInput" -->
                            </v-col>
                            
                            <v-col cols="12" class="pb-1">
                                <h6 :class="'secondary--text text-h6'">Enter the URL Someone can Access</h6>
                            </v-col>
                            <v-col cols="12" class="py-0">
                                <!-- ************* -->
                                <!--  Status URL   -->
                                <!-- ************* -->
                                <v-text-field
                                    ref="urlInput"
                                    :dense="this.$vuetify.breakpoint.smAndDown"
                                    outlined
                                    name="iqUrl"
                                    label="Your Status IQ URL"
                                    v-model="trackerForm.Url"
                                    @focusout="validateUrl"
                                    @keyup="validateUrl()"
                                    @keyup.delete="validateUrl()"
                                    :rules="formRules.iqUrl"
                                    :disabled="trackerForm.Id !== '00000000-0000-0000-0000-000000000000'"
                                    prefix="https://statusiq.io/"
                                ></v-text-field>

                            </v-col>
                            
                            <v-col cols="12" class="d-flex justify-space-between">
                                <v-btn class="px-10" outlined color="primary"  @click="clear">Clear</v-btn>
                                <v-btn color="primary" v-if="this.$vuetify.breakpoint.mdAndDown && this.stages !== null" @click="previewDrawer = !previewDrawer"> Show Preview </v-btn>
                                <v-btn color="primary" v-if="this.$vuetify.breakpoint.lgAndUp && this.stages !== null &&  this.manuallyClosedPreview && this.previewDrawer === false" @click="previewDrawer = !previewDrawer"> Show Preview </v-btn>

                                    <v-btn 
                                        class="px-10" 
                                        color="primary"
                                        @click="createTracker"
                                        :loading="loading"
                                        :disabled="!trackerValid || this.trackerForm.Url === null || this.validateFields === false || this.trackerForm.ObjectType === null|| this.trackerForm.SearchValue === null || this.validIqUrl === false"
                                    > 
                                        Save 
                                    </v-btn>
                                    
                            </v-col>

                            </v-row>

                        </v-col>
                    </v-row>
                </v-form>
            </v-col>
        </v-row>
       
                
    </v-container>
</v-layout>

</template>
<script>
import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue"
import TrackView from "./TrackView.vue";
// import { newEventSource } from 'vue-parent-emit';
export default {
    data (){
        return {
            loadingOverlay: true,

            trackerValid: true,
            validIqUrl: true,
            loadingHsProperties: false,
            loading: false,
            showPipelines: false,
            previewDrawer: false,
            previewNotification: false,
            previewNotificationText: '',
            manuallyClosedPreview: false,
            hsObjects:[
                {value:"DEAL", name:"Deals"},
                {value:"TICKET", name:"Tickets"},
            ],

            hsProperties:[],

            trackerForm: {
                Id: "00000000-0000-0000-0000-000000000000",
                Url: null,
                PhoneNumber: null,
                Email: null,
                SupportUrl: null,
                ObjectType: null,
                Property: 'Pipeline',
                SearchValue: null,
                StatusStages:[]
            },
            stages: null,
            phone: {
                number: '',
                valid: false,
                country: undefined,
            },
            validateMessage:'',
            validateFields: false,
            formRules: {
                required: v => !!v || 'Required.',
                iqUrl: [
                    v => !!v || 'Required.',
                    v => (v && v.length >= 3) || 'URL must have more than 3 characters',
                    // eslint-disable-next-line no-unused-vars
                    v => !!this.validIqUrl || this.validateMessage
                ],
                urlRules: [
                    v => (v && v.length >= 3) || 'URL must have more than 3 characters',
                    v => (v && v.length <= 256) || 'URL must be less than 256 characters',
                    /* eslint-disable-next-line no-useless-escape*/
                    v => /^[-a-zA-Z0-9:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9():%_\+.~#?&//=]*)$/.test(v) || 'URL must be valid', 
                ],
                emailRules: [
                    v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                ],
                select: [(v) => !!v || "Required."],
                
            },

            editing: false,
            firstLoad: true,
            loads: 0,

            window: { //LAYOUT TOOL: store location when page will need vertical space calculations         )
                width: 0,
                height: 0
            },

        }
    },
    watch: {
        stages: function( newVal, oldVal){
            console.log(oldVal);
            console.log(newVal);
            var isEqual = JSON.stringify(newVal) === JSON.stringify(oldVal);
            console.log(isEqual);

            
            if (this.stages !== null){
                if(this.$vuetify.breakpoint.mdAndDown){
                    if(oldVal === null){
                        this.previewNotificationText = "Tracker preview is ready, click <b>&apos;show preview&apos;</b> to review at any time";
                        this.previewNotification = true;
                    }else{
                        this.previewNotificationText =  "Tracker preview has been updated, click <b>&apos;show preview&apos;</b> to review at any time ";
                        this.previewNotification = true;
                    }
                    console.log("preview is ready");
                }else{
                    console.log('is a large screen and the stages are in' )
                    this.previewDrawer = true;
                }
            }else{
                this.previewDrawer = false;
                this.previewNotification =false;
                this.manuallyClosedPreview = false;
            }
        }, 
    },
    components: {
        VueTelInputVuetify,
        TrackView
    },   
    created() {
        if (!this.$route.params.id) this.loadingOverlay = false;
        
        this.$auth.auth0ClientPromise.then(async () => {
            await this.$store.dispatch('getUserCompany');

            //LAYOUT TOOL: resize handler
            window.addEventListener('resize', this.handleResize);
            this.handleResize();

            if (this.$route.params.id) await this.getTrackerData(this.$route.params.id);
            if (this.$route.params.id) this.trackerForm.Id = this.$route.params.id

            this.loadingOverlay = false;
        });
    },
    destroyed() { //LAYOUT TOOL: on leave dispose
        window.removeEventListener('resize', this.handleResize);
    },
    computed: {
        checkForSplits(){
            var numberOfSplits = this.conversationMessage.length / this.maxConversationMessageLength;
            var splits = "";
            if( numberOfSplits > 1){
                splits = Math.ceil(numberOfSplits);
            }
            return splits;
        },
    },
    methods: {
        validate(){
            this.$refs.trackerForm.validate()
        },
        resetValidation () {
            this.$refs.trackerForm.resetValidation()
        },
        manuallyClosePreview(){
            this.previewDrawer = false;
            this.manuallyClosedPreview =  true;
        },
        onInput(_formattedNumber, { number, valid, country }) {


            this.phone.number = number.international;
            this.phone.valid = valid;
            this.phone.country = country && country.name;

            this.handleChange({
                target: {
                    name: "phoneInput",
                    value: number.input,
                },
            });
        },
        handleSelectChange() {
            this.showPipelines = true;
            this.getHsProperties(this.trackerForm.ObjectType);
        },
        clear(){
            this.loading = false;
            this.trackerForm = {
                Id: this.$route.params.id ? this.trackerForm.Id : '00000000-0000-0000-0000-000000000000',
                Url: this.$route.params.id ? this.trackerForm.Url : null,
                PhoneNumber: null,
                Email: null,
                SupportUrl: null,
                ObjectType: null,
                Property: 'Pipeline',
                SearchValue:null,
                StatusStages:[]
            };
            this.stages = null;
            this.manuallyClosedPreview = false;
            this.hsProperties = [];

            this.resetValidation();
        },
        async connectToHubSpot() {

        },      
        async getHsObjects() {
            let hsObjects = [];

            let response = await this.$store.dispatch('status/getObjects');
            if(response.data) {
                response.data.forEach((property) => {
                    hsObjects.push({
                        value: property.id,
                        name: property.name,
                    });
                });
                this.hsObjects = hsObjects;
            }
        },
        async getHsProperties(hsObject) {
            this.hsProperties = [];
            this.loadingHsProperties = true

             let response = await this.$store.dispatch('status/getHSProperties',{
                 "objectType": hsObject
             });

            this.loadingHsProperties = false
            if(response.data.results) {
                response.data.results.map((property) => {
                    this.hsProperties.push({
                        value: property.id,
                        name: property.label,
                        stages: property.stages
                    });
                });

            }        
        },
        async validateUrl () {
            this.trackerForm.Url = this.trackerForm.Url ? this.trackerForm.Url.replace(/\s/g, '') : '';
            if(this.trackerForm.Url != undefined && this.trackerForm.Url != null && this.trackerForm.Url != ''){
                let response = await this.$store.dispatch('status/validateUrl',
                    this.trackerForm.Url
                );
                if(response.status === 200 ) {
                    this.validIqUrl = !response.data;

                    if (this.validIqUrl) {
                        this.validateMessage = '';
                    } else {
                        this.validateMessage = 'This URL is taken.';
                    }

                }else{
                    this.validIqUrl = false;
                    this.validateMessage = 'URL invalid.';
                }    

                if (this.$refs.urlInput) this.$refs.urlInput.validate(); // URL

            }else{
                this.validIqUrl = false;
            }

            if (!this.$route.params.id) this.validIqUrl && this.$refs.urlInput.valid ? this.trackerValid = true : this.trackerValid = false;
        },
        async createTracker(){
            let response = null;
            this.loading = true;

            this.trackerForm.StatusStages = this.stages;

            if (this.trackerForm.Id !== "00000000-0000-0000-0000-000000000000") {
                response = await this.$store.dispatch('status/updateTracker', {
                    "tracker": this.trackerForm
                });
            } else {
                response = await this.$store.dispatch('status/createTracker', {
                    "tracker": this.trackerForm
                });
            }
            
             if(response){
                if(response.status === 200){
                     this.$router.push('/StatusIQ');
                     this.$swal({
                         icon: 'success',
                         title: this.trackerForm.Id ? 'Tracker Edited' : 'Tracker Created',
                         text: this.trackerForm.Id ? 'Tracker Successfully Edited' : 'Tracker Successfully Created',
                         toast: true,
                         timer: 3000,
                         position: 'top-end'
                     });
                     this.clear();
                     this.resetValidation();
                 } else if(response.status === 400) {
                    this.showError(response.data); 
                 }               
             }else{
                 this.showError(this.trackerForm.Id ? 'We could not edit this tracker.' :'We could not create this tracker.');
             }
             this.loading = false;
        },
        showError(text){
            this.$swal({
                icon: 'error',
                title: text,
                text: '',
            });
        },
        async saveSelectedPipeline(){
            this.stages =[];
            var index = this.hsProperties.findIndex(x => x.value == this.trackerForm.SearchValue);
            if (index >= 0){
                this.pipelineSelected = this.hsProperties[index];

                this.hsProperties[index].stages.map((property) => {
                    let statusMappings= [];
                    statusMappings.push({
                        Value: property.id});

                    this.stages.push({
                        StatusOrder: property.displayOrder,
                        title: property.label,
                        Description: property.label,
                        StatusMappings: statusMappings
                    });
                });

                // await this.update();
                this.$nextTick(async function () {
                    // Code that will run only after the
                    // entire view has been re-rendered
                    if (this.stages && this.$refs.trackView) await this.$refs.trackView.setStages(this.stages);
                    if (this.stages && this.$refs.trackView) await this.$refs.trackView.setStages(this.stages);
                })
            }

            if (this.$route.params.id) this.preventChangeError();
        },
        handlePhoneChange() {
            this.handleChange({
                target: {
                    name: "phoneInput",
                    value: this.trackerForm.PhoneNumber ? this.trackerForm.PhoneNumber : '',
                },
            });
        },
        handleChange({ target: { name, value } }) {
            let preview = this.$refs.trackView;
            let form = this.trackerForm;
            let neighbors;
            
            this.$nextTick(async function () {
                if (name === "supportURLInput") {
                    if (preview) preview.setUrl(value); 
                    neighbors = { neighbor: "phoneInput", neighborV: form.PhoneNumber, otherNeighbor: "emailInput", otherNeighborV: form.Email }
                }
                    
                if (name === "phoneInput") {
                    if (preview) preview.setPhone(value);
                    neighbors = { neighbor: "supportURLInput", neighborV: form.SupportUrl, otherNeighbor: "emailInput", otherNeighborV: form.Email }
                }

                if (name === "emailInput") {
                    if (preview) preview.setEmail(value);
                    neighbors = { neighbor: "supportURLInput", neighborV: form.SupportUrl, otherNeighbor: "phoneInput", otherNeighborV: form.PhoneNumber }
                }
                
                this.validateField(name, value, neighbors);  
            })
        },
        validateField(name, value, neighbors) {
            let input = this.$refs[name];

            let inputValid = name === "phoneInput" ? this.phone.valid : input.valid;
            let neighborsNotEmpty = ((neighbors.neighborV !== null && neighbors.neighborV !== "") || (neighbors.otherNeighborV !== null && neighbors.otherNeighborV !== ""));
            let neighborsValid = (this.getNeighborValid(neighbors.neighbor) || this.getNeighborValid(neighbors.otherNeighbor));
            let inputReset = name === "phoneInput" ? input.$children[1] : input;


            if (value.length > 0) inputValid ? this.validateFields = true : this.validateFields = false;
            if (value.length > 0) inputValid ? this.trackerValid = true : this.trackerValid = false;
            if (value.length === 0 && neighborsNotEmpty) inputReset.resetValidation();
            if (value.length === 0 && !neighborsNotEmpty) this.validateFields = false;

            if (value.length === 0 && neighborsNotEmpty) inputReset.resetValidation();

            value.length > 0 && inputValid && neighborsNotEmpty && !neighborsValid ? this.trackerValid = false : this.trackerValid = true;

            if (value.length === 0 && neighborsNotEmpty && neighborsValid) {
                this.validateFields = true;
                this.trackerValid = true;
            } 

            // <!--     Neighbor - Reset      -->
            this.neighborsReset(this.$refs[neighbors.neighbor].name, value, input, this.$refs[neighbors.neighbor], neighbors.neighborV)

            // <!--  Other Neighbor - Reset   -->
            this.neighborsReset(this.$refs[neighbors.otherNeighbor].name, value, input, this.$refs[neighbors.otherNeighbor], neighbors.otherNeighborV);

            if (!this.$route.params.id) this.validIqUrl && this.$refs.urlInput.valid ? this.trackerValid = true : this.trackerValid = false;
        },
        getNeighborValid(neighbor) {
            return this.$refs[neighbor].name === "phoneInput" 
                ? this.phone.valid
                : this.$refs[neighbor].valid
        },
        neighborsReset(neighborName, value, input, neighbor, neighborValue) {
            let inputValid = input.name === "phoneInput" ? this.phone.valid : input.valid;

            if (neighborName === "phoneInput") {
                if ((value.length > 0 && input.valid) && (neighborValue === null || neighborValue === "")) 
                    neighbor.$children[1].resetValidation();

            } else {
                if ((value.length > 0 && inputValid) && (neighborValue === null || neighborValue === "")) 
                    neighbor.resetValidation();
            }
        },
        preventChangeError() {
            if (this.firstLoad) {
                if (this.trackerForm.SupportUrl) this.handleChange({ target: { name: "supportURLInput", value: this.trackerForm.SupportUrl } });
                if (this.trackerForm.PhoneNumber) this.handleChange({ target: { name: "phoneInput", value: this.trackerForm.PhoneNumber } });
                if (this.trackerForm.Email) this.handleChange({ target: { name: "emailInput", value: this.trackerForm.Email } });

                this.loads += 1;
                if (this.loads === 2) this.firstLoad = false;
            }
        },
        async getTrackerData(trackerId) {
            let response = await this.$store.dispatch('status/getTrackersById', {
                "idTracker": trackerId
            });

            if (response.data) {
                let tracker = response.data
                
                this.trackerForm.Id = tracker.id;
                this.trackerForm.CompanyId = tracker.companyId;
                this.trackerForm.Url = tracker.url;
                if (tracker.supportUrl) this.trackerForm.SupportUrl = tracker.supportUrl;
                if (tracker.phoneNumber) this.trackerForm.PhoneNumber = tracker.phoneNumber;
                if (tracker.email) this.trackerForm.Email = tracker.email;
                this.trackerForm.ObjectType = tracker.objectType;
                this.trackerForm.Property = tracker.property;
                this.trackerForm.SearchValue = tracker.searchValue;

                this.showPipelines = true;
                await this.getHsProperties(tracker.objectType);
                await this.saveSelectedPipeline();

                if (tracker.supportUrl) this.handleChange({ target: { name: "supportURLInput", value: tracker.supportUrl } });
                if (tracker.phoneNumber) this.handleChange({ target: { name: "phoneInput", value: tracker.phoneNumber } });
                if (tracker.email) this.handleChange({ target: { name: "emailInput", value: tracker.email} });
            }

        },
    },
    // updated: function () {
    //     this.$nextTick(async function () {
    //         // Code that will run only after the
    //         // entire view has been re-rendered
    //         // if (this.stages && this.$refs.trackView) await this.$refs.trackView.setStages(this.stages);
    //     })
    // }
}
</script>
<style lang="scss">
.statusIQ-gradient-bg{
    overflow: auto;
}

.v-main.StatusIQ{
    padding-right: 0px !important;
}
</style>
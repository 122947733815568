import Api from "@/services/api";
import Vue from "vue";
export default {
    namespaced: true,
    state: {
        
    },
    mutations: {
        
    },
    actions: {
        async getAdminReport({}){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let response = await Api().get(`/Web/Admin/AdminReport?filter=currentweek`,config)
            .catch(err => {
                console.log(err);
            });
            return response.data;
        },
        async getCompaniesByUser({}, {search: search}){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let response = await Api().get(`/Web/Admin/GetCompaniesByUser/${search}`,config)
            .catch(err => {
                console.log(err);
            });
            return response.data;
        }
    },
    getters: {
        
    }
};
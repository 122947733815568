
<template>
    <v-container fluid class="pa-0">
        <v-card flat>
            <v-overlay :value="loadingOverlay">
                <v-progress-circular
                indeterminate
                size="70"
                width="7"
                color="primary"
                ></v-progress-circular>
            </v-overlay>
            <v-toolbar
            dark
            color="iq_darkerblue"
            app
            >                      
                <v-toolbar-title  class="display-3">
                    <div class="d-flex flex-column">
                        Contacts
                        <span class="caption"> Last Update: {{ new Date() | moment( this.dateFormat) }}</span>  
                    </div>
                   
                </v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text class="pa-0">
                <v-container fluid>
                
                <v-row class="d-flex align-center">
                    <v-col md="8" cols="12" class="d-flex align-center">
                        <v-file-input 
                        dense
                        accept=".csv" label="Select a CSV file..."
                        v-model="ffile" @change="validateFile"
                        prepend-icon="mdi-paperclip"  
                        hide-details
                        outlined
                        ></v-file-input>
                        <v-btn small outlined color="primary" @click="readContacts" :disabled="!show" v-show="show" class="ml-2">
                            <span>Add Contacts</span>
                        </v-btn>
                    </v-col>   
                    <v-col md="4" cols="12" class="text-right">
                        <v-spacer></v-spacer>
                        <v-btn x-small text :href="'downloads/MessageIQ_Import_Template.csv'" target="_blank"> <v-icon class="mr-1">mdi-file-table-outline</v-icon> Download excel template</v-btn>
                    </v-col>
                </v-row>
                </v-container>        
                <v-data-table
                    :search="search"
                    :headers="contactHeaders"
                    :items="contactData"   
                    :sort-by="['firstName','lastName']"
                    :sort-desc="[false,false]"
                    :items-per-page="30"
                    dense
                    fixed-header
                    :height="window.height - this.appBarHeight - this.appHeaderToolbar - this.dataTableFooter - 65"
                    show-group-by
                    multi-sort
                    @update:group-by="handleGroup"
                    class="elevation-1 fill-height"
                    :footer-props="{
                        itemsPerPageOptions: [15,30,50,100,200, -1]
                    }"
                >   
                    <!-- This template looks for headers with formatters and executes them -->
                    <template
                        v-for="header in contactHeaders.filter((header) =>
                            header.hasOwnProperty('formatter')
                        )"
                        v-slot:[`item.${header.value}`]="{ header, value }"
                        >
                        {{ header.formatter(value) }}
                    </template>
                    <template v-slot:item.phone="{item}">
                        {{formatPhoneNumber(item.phone)}}
                    </template>
                    <!-- Custom colors and icons for missed and connected -->
                    <template v-slot:item.contactStatus="{ item }">
                        <b :class="getColor(item.contactStatus)+'--text'"> 
                            <v-icon :class="getColor(item.contactStatus)+'--text'" >{{getIcon(item.contactStatus)}}</v-icon>
                            {{ item.contactStatus }}
                        </b>
                    </template>

                    <!-- Custom colors for Tags -->
                    <template v-slot:item.tags="{ item }">
                        <div 
                            class="tag"
                            v-for="(tag, index) in item.tags"
                        >
                            {{ tag.name }}
                        </div>
                    </template>
                
                    <template v-slot:item.actions="{ item }">
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                x-small
                                outlined
                                color="primary"
                                v-bind="attrs"
                                v-on="on"
                                :data-user="item.firstName + item.lastName"
                                :data-phone="item.phone "
                                >
                                    Actions <v-icon small>mdi-chevron-down</v-icon>
                                </v-btn>
                            </template>
                                <v-list>
                                    <v-list-item
                                    v-for="(action, index) in actions"
                                    :key="index" 
                                    @click="callAction(action.title,item)"
                                    :data-user="item.firstName + item.lastName" 
                                    :data-phone="item.phone "
                                    >
                                        <v-list-item-title>{{ action.title }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                    </template>
                    <template v-slot:body.prepend>
                        <tr class="table-filter-row">
                            <td v-if="firstNameShown">
                                <v-text-field hide-details="true" dense class="input-xs" v-model="firstName" type="text" placeholder="First Name "></v-text-field>
                            </td>
                            <td  v-if="lastNameShown">
                                <v-text-field hide-details="true" dense class="input-xs" v-model="lastName" type="text" placeholder="Filter LastName"></v-text-field>
                            </td>
                            <td  v-if="phoneShown">
                                <v-text-field hide-details="true" dense class="input-xs" v-model="phone" type="text" placeholder="Filter Phone"></v-text-field>
                            </td>
                            <td v-if="contactStatusShown">
                                <v-select
                                    hide-details="true"
                                    dense
                                    class="input-xs"
                                    v-model="contactStatus"
                                    item-text="text"
                                    item-value="value"
                                    :items="statusFilter"
                                    placeholder="Show Only"
                                ></v-select>
                            </td>
                            <td> 
                                <v-text-field hide-details="true" dense class="input-xs" v-model="messagesSent" type="number" placeholder="More than"></v-text-field>
                            </td>
                            <td  v-if="tagsShow">
                                <v-text-field hide-details="true" dense class="input-xs" v-model="tags" type="text" placeholder="Filter Tag"></v-text-field>
                            </td>
                             <td> 
                                <v-text-field hide-details="true" dense class="input-xs" v-model="messagesReceived" type="number" placeholder="More than"></v-text-field>
                            </td>
                             <td> 
                                <v-text-field hide-details="true" dense class="input-xs" v-model="callsConnected" type="number" placeholder="More than"></v-text-field>
                            </td>
                             <td> 
                                <v-text-field hide-details="true" dense class="input-xs" v-model="callsMissed" type="number" placeholder="More than"></v-text-field>
                            </td>
                            <td>
                                <v-btn block text
                                @click="clearFilters"
                                :disabled=" firstName === '' && lastName === '' && phone === '' && contactStatus === '' && messagesSent === '' && messagesReceived === '' && callsConnected === '' && callsMissed === ''"
                                >
                                    <v-icon>mdi-filter-off-outline</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <v-dialog
            v-model="dialog"
            width="500"
        >
            <v-card>
                <v-card-title class="justify-left py-5 title">
                    Manage Tags
                </v-card-title>
                <div class="ma-auto position-relative  py-8 tabs" style="max-width: 450px">
                    <v-tabs
                        v-model="tab"
                        background-color="transparent"
                        color="rgba(0, 0, 0, 0.87)"
                        centered
                        dark
                    >           
                        <v-tabs-slider class="slider"></v-tabs-slider>       
                        <v-tab v-for="item in items" :key="item">
                            {{ item }}
                        </v-tab>

                    </v-tabs>

                    <v-tabs-items v-model="tab">
                        <v-tab-item v-for="item in items" :key="item">
                            <v-card-text v-if="item === 'Add Tags'" class="my-8 px-0">
                                <v-form  v-model="addTagsValid" id="addTagsForm" ref="addTagsForm" lazy-validation>
                                    <v-row class="d-flex flex-column justify-space-between" style="height: 100%">
                                        <v-col class="flex-grow-1 pb-0">
                                            <v-subheader v-if="addNewTagText" class="body-1 px-0">
                                                {{addNewTagText}} 
                                            </v-subheader>
                                            <v-autocomplete
                                                multiple
                                                ref="selectedTags"
                                                outlined
                                                :loading="loadingTags"
                                                :items="selecTags"
                                                v-model="addTags"
                                                item-text="name"
                                                item-value="id"
                                                label="Select Tags"
                                                v-on:change='handleChange("Add")'
                                                @keydown="[validate('Add')]"
                                                :rules="[(v) => !!v || 'Required.']"
                                            ></v-autocomplete>  
                                            <v-subheader v-if="!showCreateTag" class="body-1 px-0 justify-center">
                                                <a href="javascript:" @click.prevent="showCreateTag = true">Add New Tag</a> 
                                            </v-subheader>
                                        </v-col>
                                        <v-col v-if="showCreateTag" class="pt-0">
                                            <v-form v-model="newTagValid" id="newTagForm" ref="newTagForm" class="d-flex" lazy-validation>
                                                <v-text-field
                                                    class="pt-0"
                                                    v-model="newTagForm.tagName"
                                                    
                                                    label="Tag Name*"
                                                    outlined
                                                    required
                                                    :rules="[v => !!v || 'Required.']"
                                                ></v-text-field>
                                                <v-btn 
                                                    x-large 
                                                    :disabled="!newTagValid || newTagForm.tagName === null"  
                                                    :loading="loadingNewTag"
                                                    color="primary" 
                                                    @click="addNewTag" 
                                                    class="ml-2 height-btn"
                                                >
                                                    <span>Create</span>
                                                </v-btn>
                                            </v-form>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card-text>
                            
                            <v-card-text v-else class="my-8 px-0">
                                <v-form  v-model="removeTagsValid" id="removeTagsForm" ref="removeTagsForm" lazy-validation>
                                    <v-row class="d-flex flex-column justify-space-between" style="height: 100%">
                                        <v-col class="flex-grow-1" >
                                            <v-autocomplete
                                                multiple
                                                ref="selectedTags"
                                                outlined
                                                :loading="loadingTags"
                                                :items="selecRemoveTags"
                                                v-model="removeTags"
                                                item-text="name"
                                                item-value="id"
                                                label="Select Tags "
                                                v-on:change='handleChange("Remove")'
                                                @keydown="[validate('Remove')]"
                                                :rules="[(v) => !!v || 'Required.']"
                                                :disabled="showRemoveSelect"
                                            ></v-autocomplete>  
                                            <v-subheader v-if="showRemoveSelect" class="body-1 justify-center">
                                                This contact currently does not have any tags
                                            </v-subheader>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card-text>
                            
                            
                        </v-tab-item>
                    </v-tabs-items>
                </div>
                <v-card-actions class="">    
                    <v-footer absolute>
                        <v-row>
                            <v-col cols="12" class="d-flex justify-end py-5">
                                <div>
                                    <v-btn class="mx-2" color="primary"  @click="clear" outlined>Cancel</v-btn>
                                    <v-btn 
                                        v-if="tab === 0"
                                        class="mx-2" 
                                        color="primary"  
                                        @click="addContactTag" 
                                        :disabled="!addTagsValid || Object.keys(this.addTagsForm.tagsList).length === 0">
                                        <span>Add</span>
                                    </v-btn>
                                    <v-btn 
                                        v-else
                                        class="mx-2" 
                                        color="primary"  
                                        @click="removeContactTag" 
                                        :disabled="!removeTagsValid || Object.keys(this.removeTagsForm.tagsList).length === 0">
                                        <span>Remove</span>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-footer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import moment from "moment";
import Api from "@/services/api";
import { mapGetters } from 'vuex';

export default {
    data () {
      return {
        loadingOverlay: true,
        actions: [
            { title: 'Send Message'},
            { title: 'Add Tag'},
            { title: 'Mark Unsubscribed'},
            { title: 'Archive'},
        ],
        dateFormat: "DD-MM-YYYY h:mm:ss a",
        search: '',
        statusFilter: [{ text:'Unsubscribed Contacts', value:'Unsubscribed'},{ text:'Active Contacts', value:'Active'},{ text:'All', value:''},],
        
        ffile:[],
        show:false,

        firstName: '',
        firstNameShown: true,

        lastName: '',
        lastNameShown: true,

        phone: '',
        phoneShown: true,

        contactStatus:'',
        contactStatusShown: true,

        messagesSent: '',
        messagesSentShown: true,

        tags:'',
        tagsShow:true,

        messagesReceived: '',
        messagesReceivedShow: true,

        callsConnected: '',
        callsConnectedShown: true,

        callsMissed: '',
        callsMissedShown: true,


        duration: '',
        callTime: '',
        contactData: [],
        tagsData:[],
        window: { //LAYOUT TOOL: store location when page will need vertical space calculations         )
            width: 0,
            height: 0
        },

        // MODAL
        dialog: false,
        tab: null,
        items: ["Add Tags", "Remove Tags"],
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',

        loadingTags: false,
        currentContact: null,

        // v-model
        addTags: null,
        removeTags: null,

        selecTags: null,
        selecRemoveTags: null,

        showCreateTag: false,
        showRemoveSelect: false,

        addTagsValid: true,
        removeTagsValid: true,
        newTagValid: true,

        loadingNewTag: false,
        addNewTagText: null,

        addTagsForm: {
            tagsList: {}
        },
        removeTagsForm: {
            tagsList: {}
        },
        newTagForm: {
            tagName: null
        }


      }
    },
    methods: {
        async getContacts() {

            let contacts = [];
            let response = await this.$store.dispatch('company/getContacts',{
                "companyId": this.currentCompany.id
            }); 

            response.data.forEach( (contact) => {
                contacts.push({
                    firstName: contact.firstName,
                    lastName: contact.lastName,
                    phone: contact.phone,
                    contactStatus: contact.status,
                    messagesSent: contact.totalMessagesSent,
                    tags: contact.tags,
                    messagesReceived: contact.totalMessagesReceived,
                    callsMissed: contact.missedCallsCount,
                    callsConnected: contact.connectedCallsCount,
                    id: contact.id
                });
            });
            this.contactData = contacts;
            this.loadingOverlay = false;
        },

        async getTags() {
            let tags = [];
            this.loadingTags = true;
            let response = await this.$store.dispatch('tag/getTagsByCompanyId',{
                "companyId": this.currentCompany.id
            }); 
            this.loadingTags = false;

            if(response.length) {
                response.forEach( (tag) => {
                    tags.push({
                        name: tag.name,
                        contacts: tag.contacts,
                        id: tag.id,
                        companyId: tag.companyId
                    });
                });

            }
            this.tagsData = tags;
            // this.selecTags = tags;
        },
        getColor (type) {
            if (type === 'Unsubscribed') return 'error'
            else return 'success'
        },
        getIcon (type) {
            if (type === 'Unsubscribed') return 'mdi-timeline-remove-outline'
            else return 'mdi-timeline-check'
        },
        clearFilters(){
            this.firstName = "";
            this.lastName = "";
            this.phone = "";
            this.contactStatus = "";
            this.messagesSent = "";
            this.tags = ""
            this.messagesReceived = "";
            this.callsConnected = "";
            this.callsMissed = "";

        },
        callAction(actionName,contact){
           if(actionName ==="Send Message"){
              this.sendMessage(contact.phone);
           }else if(actionName === "Mark Unsubscribed"){
              this.unsubscribed(contact);
           }else if(actionName === "Add Tag"){
              this.dialog = true;
              this.loadSelect(contact);
              this.loadRemoveSelect(contact);
              this.currentContact = contact;
           }else{
              this.archive(contact);
           }
        },

        async unsubscribed(f){
            let status = "Active";
            if (f.contactStatus == "Active"){
                status = "Unsubscribed";
            }
            
            if(f != undefined){
               let phone = f.phone.replace( /[`~!@#$%^&*()_|+\-=?;: '",.<>\{\}\[\]\\\/]/gi, '')
               phone = "+" + phone;
               let userCall = await this.$store.dispatch('contact/getContactByPhoneNumber',{"companyId": this.currentCompany.id, "phoneNumber": phone});
               if(userCall != undefined){
                    let resultUnsubscribe = this.$store.dispatch('message/Unsubscribe',userCall.id);
                    if(resultUnsubscribe){
                        this.$swal({
                            icon: 'success',
                            title: 'Contact unsubscribed',
                            text: 'Contact successfully unsubscribed.',
                            toast: true,
                            timer: 3000,
                            position: 'top-end'
                        });
                        let index = this.contactData.findIndex(x => x.phone == f.phone);
                        if (index >= 0){
                            let contact = this.contactData[index];
                            contact.contactStatus = status
                            this.contactData[index] = contact;
                        }
                    }else{
                      this.$swal({
                        icon: 'error',
                        title: 'Error unsubscribing contact',
                        text: 'An error occurred while unsubscribing the contact. Please try again later.',
                        toast: true,
                        timer: 3000,
                        position: 'top-end'
                      });
                    }
                }
            } //TODO: Refresh list
        },

        async archive(f){
            if(f != undefined){
               let phone = f.phone.replace( /[`~!@#$%^&*()_|+\-=?;: '",.<>\{\}\[\]\\\/]/gi, '')
               phone = "+" + phone;
               let userCall = await this.$store.dispatch('contact/getContactByPhoneNumber',{"companyId": this.currentCompany.id, "phoneNumber": phone});
               if(userCall != undefined){
                    let resultArchive = this.$store.dispatch('contact/archiveConversation',{"id": userCall.id, "archive": true});
                    if(resultArchive){
                        this.$swal({
                            icon: 'success',
                            title: 'Archived conversation',
                            text: 'Conversation successfully archived.',
                            toast: true,
                            timer: 3000,
                            position: 'top-end'
                        });
                        let index = this.contactData.findIndex(x => x.phone == f.phone);
                        if (index >= 0){
                            this.contactData.splice(index,1);  
                        }                   
                    }else{
                        this.$swal({
                            icon: 'error',
                            title: 'Error archiving conversation',
                            text: 'An error occurred while archiving the conversation. Please try again later.',
                            toast: true,
                            timer: 3000,
                            position: 'top-end'
                    });
                }
                }
            }
        },

         async sendMessage(f){
            if(f != undefined){
               let phone = f.replace( /[`~!@#$%^&*()_|+\-=?;: '",.<>\{\}\[\]\\\/]/gi, '');
               phone = "+" + phone;
               let userCall = await this.$store.dispatch('contact/getContactByPhoneNumber',{"companyId": this.currentCompany.id, "phoneNumber": phone});
               if(userCall != undefined){
                   if(!userCall.isUnsubscribed){
                    //   let message =  {contactId: userCall.id, companyId: this.currentCompany.id,
                    //   message: "sending test message", companyPhone: null, mmsContent: null};
                    //   let img = await this.$store.dispatch('message/sendMessage', message);
                        this.$store.dispatch('contact/setContactToSendMessage', userCall.id);
                        this.$router.push('/conversations');
                   }

               }
            }
        },
        handleGroup(e){
            if(e.length > 0){
                if(e[0] === "contactStatus"){
                    //hides append filters to header when groupby applied
                    this.contactStatusShown = false;
                    //resets filter applied to callType if any
                    this.contactStatus = "";
                }
                
            }else{
                this.contactStatusShown = true;
            }

        },
        validateFile() {
            this.ffile.type.match('text/csv') ? this.show=true : this.show=false
        },
        async readContacts(){

            let response = await this.$store.dispatch('company/uploadFileContacts',{
                "file": this.ffile,
                "companyId": this.currentCompany.id,
                "userId": this.user.userId
            });

            if(response.data.status === 'Success'){
                this.$swal({
                    icon: 'success',
                    title: `Contacts added`,
                    text: `You have added ${response.data.count} contacts.`,
                    toast: true,
                    timer: 3000,
                    position: 'top-end'
                });

                this.getContacts();
            }else{
                this.$swal({
                    icon: 'error',
                    title: 'Error Uploading Contacts',
                    text: response.data.errorMessage === 'Invalid Template' ? 
                        'Invalid Contacts Template' : 'An error occurred while uploading the contacts. Please try again later.',
                });
            }
        },  

        showError(text){
            this.$swal({
                icon: 'error',
                title: text,
                text: '',
            });
        },
        
        async addNewTag () {
            this.loadingNewTag = true;

            let response = await this.$store.dispatch('tag/createTag', {
                "tag": this.getTagFormat()
            });

            this.loadingNewTag = false;

            if(response){
                this.clearNewTag();
                this.addNewTagText = "New tag added to list"

                await this.getTags();
                await this.$swal({
                    icon: 'success',
                    title: 'Tag Added',
                    text: 'Tag Successfully Added',
                    toast: true,
                    timer: 3000,
                    position: 'top-end'
                });

                this.loadSelect(this.currentContact);

                setTimeout(() => {
                    this.addNewTagText = null;
                }, "3000")
               
            }else{
                this.showError('We could not add this tag.');
            }
        },

        getTagFormat() {
            return { 
                CompanyId: this.currentCompany.id,
                Name: this.newTagForm.tagName,
            };
        },

        async incrementOrDecreaseTagContacts (tagId, increment) {
            this.loadingSubmit = true;

            let response = await this.$store.dispatch('tag/incrementOrDecreaseTagContacts', {
                 'idTag':tagId,'increment':increment
            });

        
        },

        async addTagToContact (tagId, contactId) {
            this.loadingSubmit = true;

            let response = await this.$store.dispatch('tag/createContactTag', {
                 'idTag':tagId,'idContact':contactId
            });

            if(response){
                this.getTags();
                this.$swal({
                    icon: 'success',
                    title: 'Tags Added',
                    text: 'Tags Successfully Added',
                    toast: true,
                    timer: 3000,
                    position: 'top-end'
                });
                this.dialog = false;
                this.tagForm = {};
               
            }else{
                this.showError('We could not add this tags');
            }

            this.loadingSubmit = false
        },

        async deleteTagToContact (tagId, contactId) {
            this.loadingSubmit = true;

            let response = await this.$store.dispatch('tag/deleteContactTag', {
                 'idTag':tagId,'idContact':contactId 
            });

            if(response){
                this.getTags();
                this.$swal({
                    icon: 'success',
                    title: 'Tag Remove',
                    text: 'Tag Successfully Remove',
                    toast: true,
                    timer: 3000,
                    position: 'top-end'
                });
                this.dialog = false;
                this.tagForm = {};
                this.agree = false;
               
            }else{
                this.showError('We could not remove this tags');
            }

            this.loadingSubmit = false
        },
        loadSelect(contact) {
            this.selecTags = this.tagsData;
            let tags = this.getTagsToAdd(contact, this.selecTags);
            
            this.selecTags = tags.length !== 0 ? tags : this.selecTags;
        },
        getTagsToAdd(contact, selectedTags) {
            // A comparer used to determine if two entries are equal.
            const isSameTag = (a, b) => a.id === b.id;

            // Get items that only occur in the left array,
            // using the compareFunction to determine equality.
            const onlyInLeft = (left, right, compareFunction) => 
            left.filter(leftValue =>
                !right.some(rightValue => 
                compareFunction(leftValue, rightValue)));

            const onlyInA = onlyInLeft(selectedTags, contact.tags, isSameTag);
            const onlyInB = onlyInLeft(contact.tags, selectedTags, isSameTag);

            return [...onlyInA, ...onlyInB];
        },
        loadRemoveSelect(contact) {
            this.selecRemoveTags = contact.tags;
            this.showRemoveSelect = contact.tags.length > 0 ? false : true;
        },
        async addContactTag(){
            this.addTagsForm.tagsList.map((tag) => {
                this.addTagToContact(tag.id, this.currentContact.id)
            });
            this.addTagsForm.tagsList.map((tag) => this.currentContact.tags.push(tag));

            this.dialog = false;
            this.clear()
        },
        async removeContactTag(){
            this.removeTagsForm.tagsList.map((tag) => {
                this.deleteTagToContact(tag.id, this.currentContact.id)
            });
            this.currentContact.tags = this.getTagsToAdd(this.currentContact, this.removeTagsForm.tagsList);

            this.dialog = false;
            this.clear()
        },
        handleChange(select) {
            if (select === 'Add' ) {
                let tagsToAdd = [];
            
                tagsToAdd = this.tagsData.filter((tag) => this.addTags.some(selectedTag => selectedTag === tag.id))
                this.addTagsForm.tagsList = tagsToAdd;
            } else {
                let tagsToRemove = [];
                tagsToRemove = this.tagsData.filter((tag) => this.removeTags.some(selectedTag => selectedTag === tag.id))
                this.removeTagsForm.tagsList = tagsToRemove;
            }
        },
        handleRemoveChange() {
            let tagsToAdd = [];
            
            tagsToAdd = this.tagsData.filter((tag) => this.addTags.some(selectedTag => selectedTag === tag.id))
            this.addTagsForm.tagsList = tagsToAdd;
        },
        validate(select){
            select === 'Add' 
                ? this.$refs.addTagsForm.validate()
                : this.$refs.removeTagsForm.validate()
        },
        resetValidation () {
            this.$refs.addTagsForm ? this.$refs.addTagsForm[0].resetValidation() : '';
            this.$refs.removeTagsForm ? this.$refs.removeTagsForm[0].resetValidation() : '';
        },
        clear() {
            this.dialog = false;

            this.tab = 0;

            this.addTagsForm.tagsList = {};
            this.removeTagsForm.tagsList = {};
            this.selecTags = null;
            this.selecRemoveTags = null;
            this.showRemoveSelect = false;
            this.addTags = null;
            this.removeTags = null;

            this.currentContact = null;

            this.resetValidation();
            this.clearNewTag();
        },
        clearNewTag() {
            this.showCreateTag = false;
            this.newTagForm.tagName = null;
            this.$refs.newTagForm ? this.$refs.newTagForm[0].resetValidation() : '';
        }
        
    },
    async created() { 

        this.$auth.auth0ClientPromise.then(async () => {
            if(Object.keys(this.currentCompany).length === 0){
                await this.$store.dispatch('getUserCompany');
                if(this.currentCompany.isHubSpotIntegrated){
                   //LAYOUT TOOL: resize handler
                    window.addEventListener('resize', this.handleResize);
                    this.handleResize();

                    this.getContacts();
                    this.getTags();

                }else{
                    this.$swal({
                        title: `Please connect to HubSpot to Send Message.`,
                        icon: 'error',
                        confirmButtonText: "Connect to HubSpot"
                    }).then(async result => {
                        if(result.isConfirmed){
                            this.$router.push("/settings/integrations");
                        }
                    });
                }
            }else{
                if(this.currentCompany.isHubSpotIntegrated){
                    //LAYOUT TOOL: resize handler
                    window.addEventListener('resize', this.handleResize);
                    this.handleResize();

                    this.getContacts();
                    this.getTags();
                }else{
                    this.$swal({
                        title: `Please connect to HubSpot to Send Message.`,
                        icon: 'error',
                        confirmButtonText: "Connect to HubSpot"
                    }).then(async result => {
                        if(result.isConfirmed){
                            this.$router.push("/settings/integrations");
                        }
                    });
                }
            }
        });
    },
    destroyed() { //LAYOUT TOOL: on leave dispose
        window.removeEventListener('resize', this.handleResize);
    },
    watch:{
        dialog: function(newValue, old){
            if(!newValue){
                // Closing
                this.clear();
            }
        }
    },
    computed:{
        ...mapGetters({
            currentCompany: 'company/getCurrentCompany',
            user: 'getUser'
        }),
        contactHeaders() {
            return [
                {
                    text: "First Name",
                    value: "firstName",
                    align: "left",
                    filter: f => { return ( f + '' ).toLowerCase().includes( this[ 'firstName' ].toLowerCase() ) }
                },
                {
                    text: "Last Name", 
                    value: "lastName",
                    align: "left",
                    filter: f => { return ( f + '' ).toLowerCase().includes( this[ 'lastName' ].toLowerCase() ) }
                },
                {
                    text: "Phone Number",
                    value: "phone",
                    align: "right",
                    groupable: false,
                    filter: f => { return ( f + '' ).toLowerCase().includes( this[ 'phone' ].toLowerCase() ) }
                },
                {
                    text: "Contact Status",
                    value: "contactStatus",
                    align: "left", 
                    filter: f => { return ( f + '' ).includes( this[ 'contactStatus' ] ) }
                },         
                {
                    text: "Messages Sent",
                    value: "messagesSent", 
                    align: "right",
                    groupable: false,
                    filter: value => {
                        if (!this.messagesSent) return true;
                        return value >= parseInt(this.messagesSent);
                    }
                },
                                {
                    text: "TAGS",
                    value: "tags", 
                    align: "right",
                    groupable: false,
                    filter: value => {
                        if (!this.tags) return true;
                        return value >= parseInt(this.tags);
                    }
                },
                {
                    text: "Messages Received ",
                    value: "messagesReceived",
                    align: "right",
                    groupable: false,
                    filter: value => {
                        if (!this.messagesReceived) return true;
                        return value >= parseInt(this.messagesReceived);
                    }
                },
                {
                    text: "Calls Connected",
                    value: "callsConnected",
                    align: "right",
                    groupable: false,
                    filter: value => {
                        if (!this.callsConnected) return true;
                        return value >= parseInt(this.callsConnected);
                    }
                },
                {
                    text: "Calls Missed",
                    value: "callsMissed",
                    align: "right",
                    groupable: false,
                    filter: value => {
                        if (!this.callsMissed) return true;
                        return value >= parseInt(this.callsMissed);
                    }
                },                
                {
                    text: "Actions",
                    value: "actions",
                    align: "center", 
                    groupable: false,
                    filterable: false,
                    sortable: false
                }
            ]
        },
       
    }
  }
</script>
<style lang="scss">
    .tag {
        display: inline;
        border-radius: 3px;
        color: rgba(0, 0, 0, 0.87);
        background-color: rgba(0, 0, 0, 0.05);
        width: auto;
        flex: 0 0 auto !important;
        padding: 1px 5px;
        margin: 0 2px;
    }
    .title {
        background-color: #f5f5f5;
    }
    .height-btn {
        height: 56px;
    }
    .tabs > .v-tabs--centered > .v-tabs-bar {
        height: 36px;
    }
    .tabs > .v-tabs--centered > .v-tabs-bar .v-tabs-bar__content > * {
        width: 100%;
        width: -moz-available;          /* WebKit-based browsers will ignore this. */
        width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
        width: fill-available;
        border-radius: 6px;
    }
    .slider {
        height: 0;
    }
    .tabs > .theme--dark.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active){
        color: rgba(0, 0, 0, 0.6);
    }
    .tabs > .theme--dark.v-tabs > .v-tabs-bar .v-tab.v-tab--active{
        color: #FFFFFF;
        background-color: #f50886;
    }
</style>
<template>
    <v-container>
              <v-row>
                  <v-col>
                           
                    <v-tabs vertical centered icons-and-text>
                        <v-tab>
                            Plan
                            <v-icon >
                            mdi-account
                            </v-icon>
                        </v-tab>
                        <v-tab>
                            Invoices
                            <v-icon >
                            mdi-receipt
                            </v-icon>
                        </v-tab>
                       

                        <v-tab-item>
                            <v-card flat class="border-left">
                                <v-card-text>
                                    <v-card elevation="2">
                                        <v-card-title> 
                                            <h3 class="font-weight-light">
                                                Current Plan: 
                                                <span  class="font-weight-bold" v-if="billingDetails.billingDetails.subType !== 'Trial'">
                                                ${{billingDetails.plan.price.toLocaleString("en-US")}} /
                                                {{billingDetails.plan.segmentCount.toLocaleString("en-US")}} Messages
                                                </span>
                                                <span  class="font-weight-bold" v-else>
                                                Trial
                                                </span>
                                            </h3> 
                                        </v-card-title>
                                        <v-card-subtitle v-if="billingDetails.billingDetails.subType !== 'Trial'">
                                            <div>
                                                Used: 
                                                    <v-chip
                                                        class="ma-2"
                                                        color="primary"
                                                        outlined
                                                        small
                                                        >
                                                        {{currentPlan.status.used.toLocaleString("en-US")}} credits
                                                    </v-chip>
                                                    out of 
                                                    <v-chip
                                                        class="ma-2"
                                                        color="primary"
                                                        outlined
                                                        small
                                                        >
                                                        {{billingDetails.plan.segmentCount.toLocaleString("en-US")}} credits
                                                    </v-chip>
                                            </div>
                                            <!-- <div v-if="billingDetails.billingDetails.subType !== 'Trial'">
                                                Next Billing Date: {{currentPlan.status.billDate}}
                                            </div>    -->
                                            <div v-if="billingDetails.billingDetails.subType !== 'Trial'">
                                                <v-alert
                                                border="left"
                                                colored-border
                                                color="primary"
                                                elevation="2"
                                                >

                                                        <v-icon large>
                                                            mdi-information-outline
                                                        </v-icon>
                                                        Your next billing on {{currentPlan.status.billDate}} you'll be charged 
                                                        <v-chip
                                                            class="ma-2"
                                                            color="primary"
                                                            outlined
                                                            small
                                                            >
                                                            ${{billingDetails.plan.price.toLocaleString("en-US")}} / Monthly
                                                        </v-chip>
                                                    with 
                                                    <v-chip
                                                        class="ma-2"
                                                        color="primary"
                                                        outlined
                                                        small
                                                        >
                                                        {{billingDetails.plan.segmentCount.toLocaleString("en-US")}} credits
                                                    </v-chip>
                                                </v-alert>
                                                
                                            </div>
                                        </v-card-subtitle>
                                       
                                        <v-card-actions>
                                            <v-btn v-if="billingDetails.billingDetails.subType !== 'Trial'" color="primary" large  @click.stop="changePlan = true" :disabled="changePlan"> Change Plan</v-btn>
                                            <v-btn v-else color="primary" large  @click.stop="changePlan = true" :disabled="changePlan"> Upgrade Plan</v-btn>
                                            <v-btn v-if="billingDetails.billingDetails.subType !== 'Trial'" color="primary" outlined large v-on:click="goToRecurlyHostedPage()"> Update Billing Information</v-btn>
                                        
                                        </v-card-actions>
                                    </v-card>
                                    <v-expand-transition>
                                        <v-card elevation="2" outlined v-if="changePlan">
                                            <v-item-group active-class="iq_desaturatedblue" v-model="selected" mandatory >
                                                <v-container>
                                                    <v-row>
                                                        <v-col cols="12" class="text-right"> <v-btn text rounded small @click.stop="changePlan = false">Don't Change <v-icon>mdi-close</v-icon></v-btn> </v-col>
                                                        <v-col cols="12" md="6" lg="4" class="planSelector d-flex mt-auto mb-auto" v-for="(plan, index) in plans"  :key="index" :id="plan.id">
                                                            <v-item v-slot="{ active, toggle }">
                                                                    <v-hover v-slot="{ hover }">
                                                                        <v-card
                                                                            outlined
                                                                            rounded
                                                                            min-height="133"
                                                                            style="width:100%"
                                                                            class="d-flex flex-column justify-space-between"    
                                                                            :class="{ 'on-hover': hover }"
                                                                            :elevation="plan.id === currentPlan.id ? 15 : 0"
                                                                            :color="plan.id === currentPlan.id ? 'iq_desaturatedblue active' : ''"
                                                                            :dark="plan.id === currentPlan.id"
                                                                        >   
                                                                            <v-card-title class="py-1 planSelector-title mb-1 " :class="plan.id === currentPlan.id ? 'current-plan':''">
                                                                                <div class="subtitle-2 font-weight-bold" v-if="plan.id === currentPlan.id">Current Plan</div>
                                                                            </v-card-title>
                                                                            <v-card-text class="d-flex justify-space-between align-center text-center flex-wrap pt-2">
                                                                                <div class="planSelector-pricing text-h3">
                                                                                    <span class="sup">$</span>
                                                                                    <span>{{numberWithCommas(plan.planPrice)}}</span>
                                                                                    <span class="sub">{{plan.planFrequency}}</span>
                                                                                </div>
                                                                                <div class="planSelector-credits text-h6">{{numberWithCommas(plan.planCredits)}} Credits </div>
                                                                            </v-card-text>                                                                    
                                                                            <v-card-actions class="planSelector-actions d-flex justify-end" :class="{ 'show-btns': hover }">
                                                                                    <v-btn color="primary" :disabled="plan.id === currentPlan.id" small block @click="toggle">
                                                                                        <span v-if="plan.id === selected || plan.id === currentPlan.id">Selected</span>
                                                                                        <span v-else v-on:click="changeCurrentPlan(plan.id)">Select</span>
                                                                                    </v-btn>
                                                                            </v-card-actions>
                                                                        </v-card>
                                                                    </v-hover>

                                                            </v-item>
                                                        
                                                        
                                                        </v-col>
                                                    
                                                    </v-row>
                                                    <v-card flat>
                                                        <v-card-title class="d-flex justify-center display-1">All Packages Include</v-card-title>
                                                        <v-card-text>
                                                            <v-row>
                                                                <v-col cols="12" md="4" class="d-flex flex-row align-center">
                                                                    <v-icon class="xxx-large">$messagingIcon</v-icon>
                                                                    <div>
                                                                        <h2>Premium Hubspot Integration</h2>
                                                                        <p class="body-1">Message IQ is built with the deepest HubSpot integration in the Marketplace</p>
                                                                    </div>
                                                                </v-col>
                                                                <v-col cols="12" md="4" class="d-flex flex-row align-center">
                                                                    <v-icon class="xxx-large">$deliveryIcon</v-icon>
                                                                    <div>
                                                                        <h2>2-Way Messaging System</h2>
                                                                        <p class="body-1">Have a one-to-one conversation with any contact.</p>
                                                                    </div>
                                                                </v-col>
                                                                <v-col cols="12" md="4" class="d-flex flex-row align-center">
                                                                    <v-icon class="xxx-large">$scalingIcon</v-icon>
                                                                    <div>
                                                                        <h2>Advanced Charts & Reporting</h2>
                                                                        <p class="body-1">Track clicks, replies, and deliverability down to the specific message.</p>
                                                                    </div>
                                                                </v-col>
                                                                <v-col cols="12" md="4" class="d-flex flex-row align-center">
                                                                    <v-icon class="xxx-large">$reportsIcon</v-icon>
                                                                    <div>
                                                                        <h2>IQ Autoscaling</h2>
                                                                        <p class="body-1">Our autoscaling platform allows you to use only one phone number regardless of SMS volume.</p>
                                                                    </div>
                                                                </v-col>
                                                                <v-col cols="12" md="4" class="d-flex flex-row align-center">
                                                                    <v-icon class="xxx-large primary--text">$hubspotIcon</v-icon>
                                                                    <div>
                                                                        <h2>IQ Advanced Delivery</h2>
                                                                        <p class="body-1">Know for sure if your message reached your contact’s phone.</p>
                                                                    </div>
                                                                </v-col>
                                                                <v-col cols="12" md="4" class="d-flex flex-row align-center">
                                                                    <v-icon class="xxx-large primary--text">$supportIcon</v-icon>
                                                                    <div>
                                                                        <h2>World Class Support</h2>
                                                                        <p class="body-1">Chat with us a real person will reply to help you in a matter of minutes.</p>
                                                                    </div>
                                                                </v-col>
                                                            </v-row>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-container>
                                            </v-item-group>
                                        </v-card>
                                        
                                    </v-expand-transition>



                                </v-card-text>
                            </v-card>
                            
                        </v-tab-item>
                        <v-tab-item>
                            <v-card flat class="border-left">
                                <v-card-text>
                                    <v-card elevation="2">
                                    <v-data-table
                                        :headers="billsHeaders"
                                        :items="billsData"
                                        :search="search"
                                        :items-per-page="12"
                                        sort-by="payDate"
                                        show-group-by
                                        dense
                                        :footer-props="{
                                            itemsPerPageOptions: [5,12,24,50,100,200, -1]
                                        }"
                                        :custom-sort="customSort"
                                    >
                                        <template v-slot:top>
                                            <v-text-field
                                                class="mx-4"
                                                prepend-icon="mdi-magnify"
                                                label="Search table"
                                                v-model="search"
                                            ></v-text-field>
                                        </template>
                                        <template
                                            v-for="header in billsHeaders.filter((header) =>
                                                header.hasOwnProperty('formatter')
                                            )"
                                            v-slot:[`item.${header.value}`]="{ header, value }"
                                            >
                                            {{ header.formatter(value) }}
                                        </template>
                                        <template v-slot:item.actions="{ item }">
                                            <v-menu offset-y>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                    x-small
                                                    outlined
                                                    color="primary"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    :data-invoiceID="item.invoiceID"
                                                    >
                                                        Actions <v-icon small>mdi-chevron-down</v-icon>
                                                    </v-btn>
                                                </template>
                                                    <v-list>
                                                        <v-list-item
                                                        v-for="(billAction, billIndex) in billActions"
                                                        :key="billIndex" 
                                                        @click="billActionClick"
                                                        :data-invoiceID="item.invoiceID" 
                                                        >
                                                            <v-list-item-title>{{ billAction.title }}</v-list-item-title>
                                                        </v-list-item>
                                                    </v-list>
                                            </v-menu>
                                        </template>
                                    </v-data-table>
                                    </v-card>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                    </v-tabs>
                  </v-col>
                </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import moment from "moment";
import Templates from './Templates.vue';
export default {
    components: { Templates },
    computed: {
        ...mapGetters({
            currentCompany: 'company/getCurrentCompany',
            billingDetails: 'company/getBillingDetails',
            currentPlans: 'billing/getPlans',
            billingInvoices: 'company/getBillingInvoices'
        })
    },
    data () {
        return { 
            loading: true,
            currentPlan: { id: 0, status:{ used: 500, billDate: "11/30/2021"}},
            changePlan: false,
            dateFormat: "MM-DD-YYYY",
            search: '',
            selected: null, 
            // bills Grid
            itemsPerPage: 12,
            billActions: [
                { title: 'Download' },
            ],
            billsHeaders: [                    
                {text: "Invoice ID", value: "invoiceID",align: "left"},
                {
                    text: "Amount", value: "amount",align: "left",
                    formatter: (x) => (x ? this.numberWithCommas(x) : null),
                },
                {   
                    text: "Payment Date",
                    value: "payDate",
                    align: "left",
                    formatter: (x) => (x ? moment(x).format(this.dateFormat) : null),
                },
                {text: "Action", value: "actions", sortable: false, align: "center"}
            ],
            billsData: [],
            plans:[],
            recurlyHostedPage: "",
            selectedPlanName: ""
        }
    },
    async created() {
        this.$auth.auth0ClientPromise.then(async () => {
            await this.$store.dispatch('billing/getPlans');
            this.parsePlans();

            if(Object.keys(this.currentCompany).length === 0){
                await this.$store.dispatch('getUserCompany');
                
                await this.$store.dispatch('company/getBillingDetailsByCompanyId',this.currentCompany.id);
                if(this.billingDetails.billingDetails.subType !== 'Trial'){
                    this.parseCurrentPlan();
                    this.parseInvoices();
                }
                this.loading = false;
            }else{
                await this.$store.dispatch('company/getBillingDetailsByCompanyId',this.currentCompany.id);
                if(this.billingDetails.billingDetails.subType !== 'Trial'){
                    this.parseCurrentPlan();
                    this.parseInvoices();
                }
                this.loading = false;
            }
        });
    },
    methods: {
        parsePlans(){
            let plans = this.currentPlans;
            let currentPlans = [];
            plans.forEach(plan => {
                let obj = {
                    "id": plan.id,
                    "planPrice": plan.price,
                    "planFrequency": "monthly",
                    "planCredits": plan.segmentCount
                };
                currentPlans.push(obj);
            });
            this.plans = currentPlans;
        },
        parseCurrentPlan(){
            let billingDetails = this.billingDetails;
            this.currentPlan.id = billingDetails.plan.id;
            this.selected = billingDetails.plan.id;
            this.currentPlan.status.used = billingDetails.currentUsage;
            this.currentPlan.status.billDate = moment(billingDetails.recurlySubscription.current_period_ends_at).format('MM/DD/YYYY');
        },
        parseInvoices(){
            let invoices = this.billingInvoices;
            invoices.sort((a,b) => (a.invoiceNumber > b.invoiceNumber) ? 1 : -1);
            let billData = [];
            invoices.forEach(invoice => {
                let bill = {
                    invoiceID: invoice.invoiceNumber, 
                    amount: invoice.amount, 
                    payDate: moment(invoice.processDate).format('MM/DD/YYYY')
                };
                billData.push(bill);
            });
            this.billsData = billData;
        },
        changeCurrentPlan(newPlanId){

            let newPlan = this.plans.find(p => p.id === newPlanId);
            let newPlanDetails = this.currentPlans.find(p => p.id === newPlanId);
            if(this.billingDetails.billingDetails.subType === 'Trial'){
                this.$store.dispatch("company/setSelectedPlanName",newPlanDetails.name);
                this.$store.dispatch("company/setNewPlan",newPlanDetails);
                this.$router.push('/billing-upgrade');
            }else{
                this.$swal({
                    title: `Are you sure you want to change you plan?`,
                    icon: 'waring',
                    showDenyButton: true,
                    confirmButtonText: "Yes, change it!",
                    denyButtonText: "No, keep it"
                }).then(async result => {
                    if(result.isConfirmed){
                        let response = await this.$store.dispatch("company/upgradeCompanyPlan",newPlanDetails);
                        if(response){
                            this.$swal({
                                icon: 'success',
                                title: 'Account Successfully Upgraded',
                                text: 'Your account has been successfully upgraded!',
                                confirmButtonText: "Continue",
                            }).then(async result => {
                                this.$router.push('/');
                            });
                        }else{
                            this.$swal({
                                icon: 'error',
                                title: 'Error Upgrading Account',
                                text: 'There was an error upgrading your account. Please try again later.',
                                confirmButtonText: "Continue",
                            }).then(async result => {
                                this.$router.push('/');
                            });
                        }
                    }
                });
            }
        },
        billActionClick(e){
            let invoiceid = e.target.parentNode.dataset['invoiceid'];
            this.$store.dispatch('billing/downloadBillingInvoice',invoiceid);
        },
        numberWithCommas(x) {
            let number = null;
            if(typeof x === "string"){
                number = parseInt(x);
            }
            else{
                number =  x;
            }
            return number.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            })
        },
        goToRecurlyHostedPage(){
            window.open(this.billingDetails.recurlyHostedAccountPage,"_blank");
        },
        customSort: function(items, index, isDesc) {
            items.sort((a, b) => {
                if (index[0]=='payDate') {
                    if (!isDesc[0]) {
                        return new Date(b[index]) - new Date(a[index]);
                    } else {
                        return new Date(a[index]) - new Date(b[index]);
                    }
                }
                else {
                    if(typeof a[index] !== 'undefined'){
                    if (!isDesc[0]) {
                        return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
                    }
                    else {
                        return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
                    }
                    }
                }
            });
            return items;
        }
    }
}
</script>
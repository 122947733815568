import Api from "@/services/api";
import Vue from "vue";
export default {
    namespaced: true,
    state: {
        currentSubscriptionId: "00000000-0000-0000-0000-000000000000",
        campaign: null,
        currentPhoneNumber: null,
        currentAccountModel: {
            companyName: null,
            industry: null,
            yourRole: null,
            primaryTimeZone: null,
            website: null,
        },
        currentNewPhonenumber:{},
        areaCode: "",
        callForwardingNumber: "",
        fpCookie: ""
    },
    mutations: {
        SET_CURRENT_SUBSCRIPTION_ID(state, currentSubscriptionId){
            state.currentSubscriptionId = currentSubscriptionId;
        },
        SET_CURRENT_PHONE_NUMBER(state, currentPhoneNumber){
            state.currentPhoneNumber = currentPhoneNumber;
        },
        SET_CURRENT_SUBSCRIPTION_ID(state, currentSubscriptionId){
            state.currentSubscriptionId = currentSubscriptionId;
        },
        SET_CURRENT_PHONE_NUMBER(state, currentPhoneNumber){
            state.currentPhoneNumber = currentPhoneNumber;
        },
        SET_CURRENT_ACCOUNT_MODEL(state, currentAccountModel){
            state.currentAccountModel = currentAccountModel;
        },
        SET_CURRENT_NEW_PHONENUMBER(state, currentNewPhonenumber){
            state.currentNewPhonenumber = currentNewPhonenumber;
        },
        SET_AREA_CODE(state, areaCode){
            state.areaCode = areaCode;
        },
        SET_CALL_FORWARDING_NUMBER(state,callForwardingNumber){
            state.callForwardingNumber = callForwardingNumber;
        },
        SET_FP_COOKIE(state,fpCookie){
            state.fpCookie = fpCookie;
        },
        SET_CAMPAIGN(state,campaign){
            state.campaign = campaign;
        }
    },
    actions: {
        saveSubscriptionId({commit},currentSubscriptionId){
            commit('SET_CURRENT_SUBSCRIPTION_ID',currentSubscriptionId);
        },
        saveCampaign({commit},campaign){
            commit('SET_CAMPAIGN',campaign);
        },
        saveFPCookie({commit},fpCookie){
            commit('SET_FP_COOKIE',fpCookie);
        },
        async verifyPhoneNumber({commit},phoneNumber){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let response = await Api().post(`/Web/User/VerifyPhoneNumber`,phoneNumber,config)
            .catch((err) => {
                console.log(err);
            });
            commit('SET_CURRENT_PHONE_NUMBER',phoneNumber);
            return response;
        },
        async VerifyPhoneAuthCode({commit},{"phoneNumber": phoneNumber,"phoneCode": phoneCode}){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let data = {
                PhoneNumber: phoneNumber,
                AuthCode: phoneCode
            }
            let response = await Api().post(`/Web/User/VerifyPhoneAuthCode`,data,config)
            .catch((err) => {
                console.log(err);
            });
            return response;
        },
        async SendAnotherCode({commit},phoneNumber){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let response = await Api().post(`/Web/User/SendAnotherCode`,phoneNumber,config)
            .catch((err) => {
                console.log(err);
            });
            return response;
        },
        setAccountModel({commit},currentAccountModel){
            commit('SET_CURRENT_ACCOUNT_MODEL',currentAccountModel);
        },
        async getPhoneNumber({commit},areaCode){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let response = await Api().get(`/Web/Registration/GetAvailablePhoneNumberByAreaCode/${areaCode}`,config)
            .catch((err) => {
                console.log(err);
            });
            commit('SET_AREA_CODE',areaCode);
            commit('SET_AREA_CODE',areaCode);
            commit('SET_CURRENT_NEW_PHONENUMBER',response.data);
            return response.data;
        },
        saveCallForwardingNumber({commit},callForwardingNumber){
            commit('SET_CALL_FORWARDING_NUMBER',callForwardingNumber);
        },
        
        async registerTrialMessageIQ({state, dispatch}){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let authUser = await Vue.prototype.$auth.user;
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
             
            let companyData = {
                firstName: authUser.given_name,
                lastName: authUser.family_name,
                email: authUser.email,
                authId: authUser.sub,
                phoneNumber: state.currentPhoneNumber,
                companyName: state.currentAccountModel.companyName,
                companyUrl: state.currentAccountModel.website,
                industry: state.currentAccountModel.industry,
                role: state.currentAccountModel.yourRole,
                timezone: state.currentAccountModel.primaryTimeZone,
                token: null,
                firstPromoterCookie: state.fpCookie,
                callForwardingNumber: state.callForwardingNumber,
                twilioPhoneNumber: state.currentNewPhonenumber,
                areaCode: state.areaCode,
                subscriptionId: state.currentSubscriptionId,
                campaign: state.campaign
            };
            await Api().post(`/Web/Registration/MessageIQTrial`,companyData,config)
            .catch(err => {
                console.log(err);
                return false;
            });
 
            await dispatch('getUserCompany');
 
            return true;
        },

        async registerCompany({state, dispatch}){
           let token = await Vue.prototype.$auth.getTokenSilently();
           let authUser = await Vue.prototype.$auth.user;
           let config = {
               headers: { Authorization: `Bearer ${token}` }
           };
            
           let companyData = {
               firstName: authUser.given_name,
               lastName: authUser.family_name,
               email: authUser.email,
               authId: authUser.sub,
               phoneNumber: state.currentPhoneNumber,
               companyName: state.currentAccountModel.companyName,
               companyUrl: state.currentAccountModel.website,
               industry: state.currentAccountModel.industry,
               role: state.currentAccountModel.yourRole,
               timezone: state.currentAccountModel.primaryTimeZone,
               token: null,
               firstPromoterCookie: state.fpCookie,
               callForwardingNumber: state.callForwardingNumber,
               twilioPhoneNumber: state.currentNewPhonenumber,
               areaCode: state.areaCode,
               subscriptionId: state.currentSubscriptionId,
               campaign: state.campaign
           };
           await Api().post(`/Web/Registration/CreateCompany`,companyData,config)
           .catch(err => {
               console.log(err);
               return false;
           });

           await dispatch('getUserCompany');

           return true;
        }               
        
    },
    getters: {
        getCurrentSubscriptionId: state => { return state.currentSubscriptionId},
        getCurrentPhoneNumber: state => { return state.currentPhoneNumber},
        getCurrentSubscriptionId: state => { return state.currentSubscriptionId},
        getCurrentPhoneNumber: state => { return state.currentPhoneNumber},
        getCurrentAccountModel: state => { return state.currentAccountModel}
    }
};
import { render, staticRenderFns } from "./_status.vue?vue&type=template&id=36bb3322&scoped=true&"
import script from "./_status.vue?vue&type=script&lang=js&"
export * from "./_status.vue?vue&type=script&lang=js&"
import style0 from "./_status.vue?vue&type=style&index=0&id=36bb3322&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36bb3322",
  null
  
)

export default component.exports
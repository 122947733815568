<template>
    <div fill-width justify="center" align="center">
        <v-overlay color="iq_dark" :value="loading">
            <v-icon color="iq_light" x-large>mdi-loading mdi-spin</v-icon>
            <span class="iq_light--text font-weight-bold ">Pulling Company Price Lists...</span>
        </v-overlay>
        <v-card class="mt-3" elevation="0" max-width="1200">
            <v-card-title>
                Price IQ Price Lists

                <v-spacer></v-spacer>

                <v-btn depressed class="me-5">
                    Connect to HubSpot
                </v-btn>
                <v-btn depressed color="primary" to="/PriceIQ/create">
                    Add New Price List
                </v-btn>
            </v-card-title>
            <v-data-table
            :headers="headers"
            :items="tableData"
            dense
        >
            <template v-slot:item="row">
                <tr>
                    <td>{{row.item.name}}</td>
                    <td>{{row.item.discount}}%</td>
                    <td>{{row.item.dealsUsed}}</td>
                    <td>
                        <v-row justify="center" class="ma-2">
                            <v-btn depressed>
                                Edit
                            </v-btn>
                        </v-row>
                    </td>
                </tr>
            </template>
        </v-data-table>
        </v-card>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters({
            currentCompany: 'company/getCurrentCompany',
            companyPriceLists: 'priceIQ/getCompanyPriceLists'
        }),
        headers() {
            return [
                {
                    text: "Name",
                    align: "start",
                    value: "name",
                    filter: this.nameFilter,
                },
                {
                    text: "Discount",
                    value: "discount"
                },
                {
                    text: "Deals Used",
                    value: "dealsUsed"
                },
                {
                    text: "Actions"
                }
            ]
        }
    },
    data(){
        return {
            loading: true,
            tableData: []
        }
    },
    created(){
        this.$auth.auth0ClientPromise.then(async () => {
            
            if(Object.keys(this.currentCompany).length === 0){
                await this.$store.dispatch('getUserCompany');
            }

            let priceLists = await this.getCompanyPriceLists();
            this.tableData = priceLists;
            this.loading = false;
        });
    },
    methods: {
        async getCompanyPriceLists(){
            let response = await this.$store.dispatch('priceIQ/getCompanyPriceLists');
            if(!response){
                this.$swal({
                    title: `Unable to fetch price list data.`,
                    icon: 'warning',
                    showDenyButton: false,
                    confirmButtonText: "Close"
                });
            } else {
                return response;
            }
        }
    }
}
</script>

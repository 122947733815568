import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import VueTelInputVuetify from "vue-tel-input-vuetify";
import vuetify from './plugins/vuetify'
import { VueMaskDirective } from 'v-mask'
import VueSweetalert2 from 'vue-sweetalert2';
import store from './store';
import 'sweetalert2/dist/sweetalert2.min.css';


// Import the Auth0 configuration
import { domain, clientId, audience } from "../auth_config.json";

// Import the plugin here
import { Auth0Plugin } from "./auth";

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

// Vue.use(VueTelInputVuetify, {
//   // vuetify,
// });

Vue.use(VueSweetalert2, {
  buttonsStyling: false,
  customClass: { // Using this will required customClass to never  be used further in the definition
    container: 'v-application', //this patches the issue of the swal opening out of the vue vuetify v-application container so we emulate for styling purposes 
    confirmButton: 'v-btn v-btn--is-elevated v-btn--has-bg v-size--default primary ma-1',
    denyButton: 'v-btn v-btn--is-elevated v-btn--has-bg v-size--default error ma-1',
    cancelButton: 'v-btn v-btn--is-elevated v-btn--has-bg v-size--default warning ma-1',
    popup: 'bauhaus_light primary--text text--darken-5 elevation-24'
  }
})


Vue.directive('mask', VueMaskDirective);
Vue.use(require('vue-moment'));
Vue.use(require('vue-script2'));

import './mixins/globalMethods.js';
import '@/assets/scss/styles.scss'

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')

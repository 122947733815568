import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VRow,{staticClass:"d-flex"},[_c(VCol,{attrs:{"cols":"6"}},[_c(VTextarea,{staticClass:"left mb-6 pt-4",attrs:{"flat":"","rows":"5"},on:{"input":_vm.onChangeMessage},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1),_c(VCol,{attrs:{"cols":"6"}},[_c(VCardText,[_c(VList,{staticClass:"transparent"},[_c(VListItem,{staticClass:"min-height"},[_c(VListItemTitle,[_vm._v("Encoding Used: ")]),_c(VListItemSubtitle,{staticClass:"text-right"},[_vm._v(" "+_vm._s(this.encoding)+" ")])],1),_c(VListItem,{staticClass:"min-height"},[_c(VListItemTitle,[_vm._v("Characters in SMS: ")]),_c(VListItemSubtitle,{staticClass:"text-right"},[_vm._v(" "+_vm._s(this.characters)+" ")])],1),_c(VListItem,{staticClass:"min-height"},[_c(VListItemTitle,[_vm._v("Number of segments: ")]),_c(VListItemSubtitle,{staticClass:"text-right"},[_vm._v(" "+_vm._s(this.segments)+" ")])],1),_c(VListItem,{staticClass:"min-height"},[_c(VListItemTitle,[_vm._v("Segment Length: ")]),_c(VListItemSubtitle,{staticClass:"text-right"},[_vm._v(" "+_vm._s(this.length)+" ")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-container class="mt-n1 pt-0">
        <v-row class="py-3">
            <v-col cols="6">
                <div class="d-flex flex-row align-start my-3"> 
                    <v-icon class="xx-large">mdi-database-plus-outline</v-icon>
                    <div class="ml-2">
                        <h1 class="display-1"> Systems </h1>
                        <h4 class="body-2">Select from the prexisting list of systems, choose other if not listed for custom integration</h4>
                    </div>
                </div>
               
                <p>Ea dolor ut duis esse occaecat nisi. Labore nulla officia minim est. Cillum fugiat aliqua enim dolor.
                Labore nisi esse quis occaecat mollit magna Lorem quis eu eu proident Lorem irure. Nisi id nostrud dolor culpa incididunt magna id. Qui cupidatat cillum sit est cillum culpa velit. Nisi laborum dolore dolore do cillum excepteur occaecat in ipsum do aliquip proident enim culpa. Reprehenderit veniam consequat reprehenderit incididunt qui dolore amet irure tempor Lorem sint.
                </p>
            </v-col>
            <v-col cols="6">
                <Status :wizardState="this.status" :stepName="currentRouteName"></Status>
           </v-col>
        </v-row>
        
        <v-card rounded elevation="1" outlined>
            <v-expansion-panels focusable v-model="panel">
                <v-expansion-panel v-for="(source, sourceIndex) in sources" :key="sourceIndex" class="mt-0">
                    <div v-if="sourceIndex === 0">
                        <v-expansion-panel-header> 
                            <span class="text-h6 flex-grow-0">{{source.name}} <span class="ml-2 font-weight-medium primary--text" v-if="source.isRequired">Required *</span></span>
                            <v-chip color="primary" class="flex-grow-0 ml-3" v-if="source.value !== undefined">
                                <v-icon>mdi-check</v-icon>
                                {{source.system}}
                            </v-chip>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="mt-4">
                            <v-toolbar class="flex-grow-0 mb-4" light elevation="0" flat>
                                    <v-text-field
                                        v-model="systemsSearch"
                                        clearable
                                        dense
                                        outlined
                                        flat
                                        solo
                                        hide-details
                                        prepend-inner-icon="mdi-magnify"
                                        label="Search Integration Systems"
                                        class="flex-grow-1 mr-1 compact"
                                    ></v-text-field>
                                    <v-btn-toggle
                                        v-model="systemsSortDesc"
                                        mandatory
                                    >
                                        <v-btn
                                        depressed
                                        :value="false"
                                        >
                                            <v-icon small>mdi-arrow-up</v-icon>
                                        </v-btn>
                                        <v-btn
                                        depressed
                                        :value="true"
                                        >
                                            <v-icon small>mdi-arrow-down</v-icon>
                                        </v-btn>
                                    </v-btn-toggle>
                            </v-toolbar>
                            <v-data-iterator
                            :items="systems"
                            :page.sync="systemsPage"
                            :search="systemsSearch"
                            :sort-by="systemsSortBy"
                            :sort-desc="systemsSortDesc"
                            :items-per-page="systemsItemsPerPage"
                            :footer-props="{
                            itemsPerPageOptions: [8,16,22,25,50,100, -1]
                            }">
                                <template v-slot:default="props">
                                <v-item-group v-model="source.value">
                                    <v-row>
                                        <v-col cols="6" sm="4" md="2"  xl="1"  v-for="(item, systemIndex) in  props.items" :key="systemIndex" class="pa-1">
                                            <v-hover v-slot="{ hover }">
                                                <v-item v-slot="{ active, toggle }" @change="getSelectedSystemName(item.title, source.id)">    
                                                    <v-card min-height="130px" max-height="130px" :elevation="hover ? 2 : 0" rounded outlined class="pa-0 d-flex flex-start align-center text-break lighten-5" :color="active ? 'info' : ''" @click="toggle">
                                                        <v-card-text class="d-flex flex-column flex-nowrap align-center ">
                                                            <v-img 
                                                            position="top left"
                                                            :src="require('@/assets/images/integrations/' + item.src )"
                                                            contain
                                                            max-height="60px"
                                                            max-width="60px"
                                                            ></v-img>
                                                            <span class="text-uppercase text-center font-weight-medium body-1 px-3 text-tight">{{item.title}} </span>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-item>
                                            </v-hover>
                                        </v-col>
                                    </v-row>
                                </v-item-group>
                                </template>
                                <template v-slot:footer.prepend>
                                    <div class="pt-3 text-left">
                                        <div class="py-2 body-2">
                                            <span>Is your system not listed? Request a new system here </span>
                                        </div>
                                        <v-btn color="primary">
                                            <v-icon> mdi-plus</v-icon>
                                            Request your System
                                        </v-btn>
                                    </div>
                                </template>
                            </v-data-iterator>
                            
                        </v-expansion-panel-content>
                    </div>
                    <div v-else>
                        <v-expansion-panel-header> 
                            <span class="text-h6 flex-grow-0">{{source.name}} <span class="ml-2 font-weight-medium primary--text" v-if="source.isRequired">Required *</span></span>
                            <v-chip color="primary" class="flex-grow-0 ml-3" v-if="source.value !== undefined">
                                <v-icon>mdi-check</v-icon>
                                {{source.system}}
                            </v-chip>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="mt-4">
                            <v-toolbar class="flex-grow-0 mb-4" light elevation="0" flat>
                                    <v-text-field
                                        v-model="systemsSearchDestination"
                                        clearable
                                        dense
                                        outlined
                                        flat
                                        solo
                                        hide-details
                                        prepend-inner-icon="mdi-magnify"
                                        label="Search Integration Systems"
                                        class="flex-grow-1 mr-1 compact"
                                    ></v-text-field>
                                    <v-btn-toggle
                                        v-model="systemsSortDescDestination"
                                        mandatory
                                    >
                                        <v-btn
                                        depressed
                                        :value="false"
                                        >
                                            <v-icon small>mdi-arrow-up</v-icon>
                                        </v-btn>
                                        <v-btn
                                        depressed
                                        :value="true"
                                        >
                                            <v-icon small>mdi-arrow-down</v-icon>
                                        </v-btn>
                                    </v-btn-toggle>
                            </v-toolbar>
                            <v-data-iterator
                            :items="systems"
                            :page.sync="systemsPageDestination"
                            :search="systemsSearchDestination"
                            :sort-by="systemsSortByDestination"
                            :sort-desc="systemsSortDescDestination"
                            :items-per-page="systemsItemsPerPageDestination"
                            :footer-props="{
                            itemsPerPageOptions: [8,16,22,25,50,100, -1]
                            }">
                                <template v-slot:default="props">
                                <v-item-group v-model="source.value">
                                    <v-row>
                                        <v-col cols="6" sm="4" md="2"  xl="1"  v-for="(item, systemIndex) in  props.items" :key="systemIndex" class="pa-1">
                                            <v-hover v-slot="{ hover }">
                                                <v-item v-slot="{ active, toggle }" @change="getSelectedSystemName(item.title, source.id)">    
                                                    <v-card min-height="130px" max-height="130px" :elevation="hover ? 2 : 0" rounded outlined class="pa-0 d-flex flex-start align-center text-break lighten-5" :color="active ? 'info' : ''" @click="toggle">
                                                        <v-card-text class="d-flex flex-column flex-nowrap align-center ">
                                                            <v-img 
                                                            position="top left"
                                                            :src="require('@/assets/images/integrations/' + item.src )"
                                                            contain
                                                            max-height="60px"
                                                            max-width="60px"
                                                            ></v-img>
                                                            <span class="text-uppercase text-center font-weight-medium body-1 px-3 text-tight">{{item.title}} </span>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-item>
                                            </v-hover>
                                        </v-col>
                                    </v-row>
                                </v-item-group>
                                </template>
                                <template v-slot:footer.prepend>
                                    <div class="pt-3 text-left">
                                        <div class="py-2 body-2">
                                            <span>Is your system not listed? Request a new system here </span>
                                        </div>
                                        <v-btn color="primary">
                                            <v-icon> mdi-plus</v-icon>
                                            Request your System
                                        </v-btn>
                                    </div>
                                </template>
                            </v-data-iterator>
                            
                        </v-expansion-panel-content>
                    </div>
                   
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card>
        <v-row>
            
            <v-col> &nbsp;  
            <v-chip class="ma-2" label color="error" v-if="this.sources[0].system === this.sources[1].system && this.sources[0].system !== null ">  Your selection can't be the same, please change to continue</v-chip>
            </v-col>

        </v-row>
        <v-divider></v-divider>
        <v-footer color="iq_opaque" class="pt-4">
            <v-btn :disabled="this.sources[0].value === undefined || this.sources[1].value === undefined || this.sources[0].system === this.sources[1].system" to="/wizard/triggers" large color="primary"> Continue </v-btn>
            <v-btn text large > Cancel </v-btn>
        </v-footer>
    </v-container>
</template>
<script>
import Status from "./_status.vue";

export default {
    props: ['status'],
    components: {
        Status
    },
    data () {
        return{
            panel: 0,
            sources:[
                {id: '1', name: 'Source System', isRequired: true, value: undefined, system: null},
                {id: '2', name: 'Destination System', isRequired: true, value: undefined, system: null},
            ],
//source            
            systemsSearch: '',
            systemsFiter: {},
            systemsOrderByKeys: [],
            systemsSortDesc: false, 
            systemsPage: 1, 
            systemsItemsPerPage: -1, 
            systemsSortBy: 'name',
//destination
            systemsSearchDestination: '',
            systemsFiterDestination: {},
            systemsOrderByKeysDestination: [],
            systemsSortDescDestination: false, 
            systemsPageDestination: 1, 
            systemsItemsPerPageDestination: -1, 
            systemsSortByDestination: 'name',


            systems: [
                { name: 'recurly',                  title:'Recurly',    src: 'Recurly.png'},
                { name: 'salesforce',               title:'Sales Force',    src: 'SalesForce.png'},
                { name: 'sendgrid',                 title:'SendGrid',   src: 'SendGrid.png'},
                { name: 'smartsheet',               title:'Smartsheet', src: 'smartsheet.png'},
                { name: 'square',                   title:'Square', src: 'square.png'},
                { name: 'stripe',                   title:'Stripe', src: 'Stripe.png'},
                { name: 'sugarcrm',                 title:'Sugar CRM',  src: 'SugarCRM.png'},
                { name: 'toast',                    title:'Toast',  src: 'toast.png'},
                { name: 'trello',                   title:'Trello', src: 'Trello.png'},
                { name: 'xero',                     title:'Xero',   src: 'xero.png'},
                { name: 'zendesk',                  title:'ZenDesk',    src: 'zendesk.png'},
                { name: 'zohocrm',                  title:'Zoho CRM',   src: 'ZOHOCRM.png'},
                { name: 'activecampaign',           title:'Active Campaign',    src: 'ActiveCampaign.png'},
                { name: 'brighttree',               title:'Brighttree', src: 'brighttree.png'},
                { name: 'bullhorn',                 title:'Bullhorn',   src: 'bullhorn.png'},
                { name: 'chargebee',                title:'Chargebee',  src: 'ChargeBee.png'},
                { name: 'chargify',                 title:'Chargify',   src: 'chargify.png'},
                { name: 'clover',                   title:'Clover', src: 'clover.png'},
                { name: 'drift',                    title:'Drift',  src: 'drift.png'},
                { name: 'freshbooks',               title:'Freshbooks', src: 'freshbooks.png'},
                { name: 'freshdesk',                title:'Freshdesk',  src: 'Freshdesk.png'},
                { name: 'getresponse',              title:'Getresponse',    src: 'getResponse.png'},
                { name: 'hubspot',                  title:'Hubspot',    src: 'Hubspot.png'},
                { name: 'insightly',                title:'Insightly',  src: 'insightly.png'},
                { name: 'intercom',                 title:'Intercom',   src: 'intercom.png'},
                { name: 'jira',                     title:'Jira',   src: 'jira.png'},
                { name: 'keap',                     title:'Keap',   src: 'Keap.png'},
                { name: 'klaviyo',                  title:'Klaviyo',    src: 'klaviyo.png'},
                { name: 'marketo',                  title:'Marketo',    src: 'marketo.png'},
                { name: 'microsoftdynamics365crm',  title:'Microsoft Dynamics 365 CRM', src: 'MicrosoftDynamics365CRM.png'},
                { name: 'microsoftdynamicsax',      title:'Microsoft Dynamics AX',  src: 'MicrosoftDynamicsAX.png'},
                { name: 'microsoftdynamicsgp',      title:'Microsoft Dynamics GP',  src: 'MicrosoftDynamicsGP.png'},
                { name: 'mindbody',                 title:'Mindbody',   src: 'mindbody.png'},
                { name: 'netsuite',                 title:'NetSuite',   src: 'netsuite.png'},
                { name: 'oracleeloquia',            title:'Oracle Eloquia', src: 'OracleEloquia.png'},
                { name: 'oraclesalescloud',         title:'oracle Sales Cloud', src: 'oracleSalesCloud.png'},
                { name: 'pipedrive',                title:'Pipedrive',  src: 'pipedrive.png'},
                { name: 'quickbooks',               title:'Quickbooks', src: 'quickbooks.png'},
            ],
        }
    },
    methods:{
        getSelectedSystemName(elem, position){
            console.log(elem);
            console.log(position);
            //sets the system title to the selection
            this.sources[position-1].system = elem;
            //waits half second then goes to next panel    
            
            setTimeout(() => this.panel = this.panel + 1, 500);
            //set status to show on next step using Route props
            this.status[position-1].value = elem;
        },
       
    },
    computed:{
        currentRouteName() {
            return this.$route.name;
        },

    }
}
</script>
<style lang="scss" scoped>
    .text-tight{
        padding-top: 3px;
        line-height: 1em;
        word-break: keep-all;
    }
</style>